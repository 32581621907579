/* eslint-disable import/prefer-default-export */

export const appConst = {
  APP_NAME: 'Mídias Marfrig',
  APP_VERSION: '1.0',
  STORAGE_NAME: 'midias_marfrig',
};

export const relatoriosColors = {
  colors: ['#021f59', '#00A000', '#FF0000', '#800080', '#FFA500', '#0000FF'],
};
