import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const ClienteSolicitacao = (props) => {
  const { data } = props;

  const titulo = 'SOLICITAÇÕES POR CLIENTE';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const clientes = Object.keys(data);
  const series = [
    { data: clientes.map((cliente) => data[cliente].quantidade) },
  ];

  const options = {
    colors: ['#021f59'],
    plotOptions: {
      bar: {
        barHeight: '80%',
        horizontal: true,
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: clientes.map((cliente) => cliente.toUpperCase()),
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max:
        Math.max(...clientes.map((cliente) => data[cliente].quantidade)) * 1.6,
      labels: {
        align: 'right',
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    grid: {
      padding: {
        left: 20,
      },
      row: {
        colors: ['#dcdcdc', 'transparent'],
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      parentHeightOffset: 0,
      width: '100%',
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'start',
      offsetX: 5,
      offsetY: 6,
      formatter: (value, { dataPointIndex }) => {
        return `${parseInt(
          data[clientes[dataPointIndex]].quantidade,
          10
        ).toLocaleString('pt-BR')}(${
          data[clientes[dataPointIndex]].percentual
        }%)`;
      },
      style: {
        fontSize: '0.75rem',
        fontWeight: 'light',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Rede: <span id="data">${clientes[
                dataPointIndex
              ].toUpperCase()}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${parseInt(
                series[seriesIndex][dataPointIndex],
                10
              ).toLocaleString('pt-BR')}%</span>
            </span>
            <span>
              % Aprovados: <span id="data">${
                data[clientes[dataPointIndex]].percentual
              }%</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="scrollable" style={{ maxHeight: '300px' }}>
        <Chart
          options={options}
          series={series}
          height={clientes.length * 25}
          type="bar"
        />
      </div>
    </div>
  );
};

export default ClienteSolicitacao;
