import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const RegionalSolicitacoes = (props) => {
  const { data } = props;

  const titulo = 'Solicitações por regional';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const regioes = Object.keys(data);

  const series = [{ data: regioes.map((regiao) => data[regiao].quantidade) }];

  const options = {
    colors: ['#021f59'],
    plotOptions: {
      bar: {
        barHeight: '70%',
        horizontal: true,
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: true,
          orientation: 'horizontal',
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: regioes.map((regiao) => regiao.toUpperCase()),
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max: Math.max(...regioes.map((regiao) => data[regiao].quantidade)) * 1.15,
      show: true,
      labels: {
        align: 'right',
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    grid: {
      show: true,
      row: {
        colors: ['#dcdcdc', 'transparent'],
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      // height: 'auto',
      id: 'reg-solicitacoes',
      parentHeightOffset: 0,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      textAnchor: 'start',
      offsetX: 5,
      offsetY: 6,
      formatter: (value, { dataPointIndex }) => {
        return `${value} (${data[regioes[dataPointIndex]].percentual}%)`;
      },
      style: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Regional: <span id="data">${regioes[
                dataPointIndex
              ].toUpperCase()}</span>
            </span>
            <span>
              Total de Solicitação id: <span id="data">${
                series[seriesIndex][dataPointIndex]
              }</span>
            </span>
            <span>
              % Solicitações Aprovadas: <span id="data">${
                data[regioes[dataPointIndex]].percentual
              }</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="bar" height="250" />
    </div>
  );
};

export default RegionalSolicitacoes;
