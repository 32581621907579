import React, { useState } from 'react';

import './style.scss';

const initialState = new Date().getUTCFullYear();

const BottomFixed = () => {
  const [year] = useState(initialState);

  return (
    <footer className="footer-fixed">
      <p>&copy; {year > 2020 ? `2020 - ${year}` : year}</p>
    </footer>
  );
};

export default BottomFixed;
