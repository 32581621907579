/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Carousel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import { Context } from 'context/AuthContext';
import { GET_LIST_AVISO } from 'services/Aviso';
import { COUNT_PENDENCIA_USUARIO_ATUAL } from 'services/Pendencia';
import { COUNT_RASCUNHO_USUARIO_ATUAL } from 'services/Rascunho';

import rascunho from 'assets/imgs/icons/rascunho.png';
import consultar from 'assets/imgs/icons/consultar.png';
import pendencias from 'assets/imgs/icons/pendencias.png';
import relatorios from 'assets/imgs/icons/relatorios.png';
import solicitarCampanha from 'assets/imgs/icons/solicitar_campanha.png';
import solicitarMateriais from 'assets/imgs/icons/solicitar_materiais.png';
import artesFacil from 'assets/imgs/icons/artes_facil.png';
import icon_revista from 'assets/imgs/icons/ICON_revista.png';
import icon_logomarca from 'assets/imgs/icons/ICON_logo.png';
import icon_mockup from 'assets/imgs/icons/ICON_mockups.png';

import './styles.scss';

const ContentHome = () => {
  const [warnings, setWarnings] = useState([]);
  const { showLoader } = useContext(Context);
  const [corPendencia, setCorPendencia] = useState([]);
  const [corRascunho, setCorRascunho] = useState([]);

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      const corPendenciaResult = await COUNT_PENDENCIA_USUARIO_ATUAL();
      setCorPendencia(corPendenciaResult.value);

      const countUsuarioAtual = await COUNT_RASCUNHO_USUARIO_ATUAL();
      setCorRascunho(countUsuarioAtual.value);

      const warningsResult = await GET_LIST_AVISO();
      setWarnings(warningsResult.value);

      showLoader(false);
    }
    fetchData();
  }, []);

  return (
    <div className="content-home">
      <div className="wrapper-home">
        <div className="warnings">
          {/* Carrocel ------------------------------------------------------------------------------------------------------------------------------ */}
          <Carousel>
            {warnings?.map((item) => {
              const link = item.urlRedirecionamento;
              return (
                <Carousel.Item key={item.avisoId}>
                  <a href={link}>
                    <img
                      className="d-block w-100"
                      src={item.urlImagem}
                      alt={item.texto}
                    />
                  </a>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        {/* <div className="actions"> */}
        <div className="actions">
          <div>

            {/* Tabloide ---------------------------------------------------------------------------------------------------------------------------- */}
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-materiais">
                  Solicite materiais personalizados com qualquer layout, produto ou
                  dimensões necessárias.
                </Tooltip>
              }
            >
              <Link to="/pedido-materiais?materialComunicacaoTipo=T">
                <div title="Solicitar Tabloides">
                  <div>
                    <img src={solicitarMateriais} alt="pedido-materiais" />
                  </div>
                  <div className="btn-divider" />
                  <div>Solicitar Tabloides</div>
                </div>
              </Link>
            </OverlayTrigger>

            {/* Midias Digitais --------------------------------------------------------------------------------------------------------------------- */}
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-artesfacil">
                  Solicite artes digitais personalizadas com qualquer layout, produto ou
                  dimensões necessárias.
                </Tooltip>
              }
            >
              <Link to="/pedido-materiais?materialComunicacaoTipo=MD">
                <div title="Solicitar Mídias Digitais">
                  <div>
                    <img src={artesFacil} alt="Midias Digitais" />
                  </div>
                  <div className="btn-divider" />
                  <div>Solicitar Mídias Digitais</div>
                </div>
              </Link>
            </OverlayTrigger>
          </div>

          <div>
            {/* Revistas --------------------------------------------------------------------------------------------------------------------- */}
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-revistas">
                  Solicite revistas personalizadas.
                </Tooltip>
              }
            >
              <Link to="/pedido-materiais?materialComunicacaoTipo=R">
                <div title="Solicitar Revistas">
                  <div>
                    <img src={icon_revista} alt="Revistas" />
                  </div>
                  <div className="btn-divider" />
                  <div>Solicitar Revistas</div>
                </div>
              </Link>
            </OverlayTrigger>

            {/* Rascunhos --------------------------------------------------------------------------------------------------------------------------- */}
            <a href="/rascunho">
              <div style={{ backgroundColor: corRascunho > 0 ? 'red' : '' }}>
                <div>
                  <img src={rascunho} alt="Rascunhos" />
                </div>
                <div className="btn-divider" />
                <div title="Rascunhos">Rascunhos</div>
              </div>
            </a>

          </div>

          <div>

            {/* Consulta ---------------------------------------------------------------------------------------------------------------------------- */}
            <a href="/consulta">
              <div title="Consultar">
                <div>
                  <img src={consultar} alt="Consultar" />
                </div>
                <div className="btn-divider" />
                <div>Consultar</div>
              </div>
            </a>

            {/* Pendencias -------------------------------------------------------------------------------------------------------------------------- */}
            <a href="/pendencias">
              <div style={{ backgroundColor: corPendencia > 0 ? 'red' : '' }}>
                <div>
                  <img src={pendencias} alt="pendencias" />
                </div>
                <div className="btn-divider" />
                <div title="Pendências">Pendências</div>
              </div>
            </a>
          </div>

          <div style={{ display: 'flex' }}>
            {/* <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-banco-artes">

                </Tooltip>
              }
            >
              <Link to="/artes-facil">
                <div title="Artes Fácil">
                  <div>
                    {/* <img src={solicitarCampanha} alt="pedido-campanha" />
                    <FontAwesomeIcon icon={faFileImage} className="icon-home" />
                  </div>
                  <div className="btn-divider" />
                  <div>Artes Fácil</div>
                </div>
              </Link>
            </OverlayTrigger> */}

            {/* Historico --------------------------------------------------------------------------------------------------------------------------- */}
            <div style={{ width: '49%', paddingLeft: '10px' }}>
              <Link to="/historico-pedidos">
                <div title="Histórico de Pedidos" >
                  <div>
                    <img src={rascunho} alt="Histórico de Pedidos" />
                  </div>
                  <div className="btn-divider" />
                  <div>Histórico de Pedidos</div>
                </div>
              </Link>
            </div>

            {/* Relatorios -------------------------------------------------------------------------------------------------------------------------- */}
            <a href="/relatorios" style={{ paddingLeft: '10px' }}>
              <div title="Relatórios" >
                <div>
                  <img src={relatorios} alt="atendimento" />
                </div>
                <div className="btn-divider" />
                <div>Relatórios</div>
              </div>
            </a>

          </div>

          <div style={{ display: 'flex' }}>
            {/* Logomarca --------------------------------------------------------------------------------------------------------------------------- */}
            <div style={{ width: '49%', paddingLeft: '10px' }}>
              <Link to="/banco-de-imagens?pagina=1&categoria=2&marca=">
                <div title="Logomarcas" >
                  <div>
                    <img src={icon_logomarca} alt="Logomarcas" />
                  </div>
                  <div className="btn-divider" />
                  <div>Logomarcas</div>
                </div>
              </Link>
            </div>
            {/* Mockups ----------------------------------------------------------------------------------------------------------------------------- */}
            <div style={{ width: '50%', paddingLeft: '20px' }}>
              <Link to="/banco-de-imagens?pagina=1&categoria=1&marca=">
                <div title="Mockups" >
                  <div>
                    <img src={icon_mockup} alt="Mockups" />
                  </div>
                  <div className="btn-divider" />
                  <div>Mockups</div>
                </div>
              </Link>
            </div>
          </div>

        </div>
      </div>

      {/* Texto Informativo ------------------------------------------------------------------------------------------------------------------------- */}
      <div className="texto-atendimento">
        <p>
          Em caso de dúvidas ou ocorrências sobre o webencartes Marfrig, ligar
          (11) 9 7066-7283 de segunda a sexta no horário das 9:00 às 18:00 ou,
          clique em atendimento ao usuário e abra um chamado que entraremos em
          contato.
        </p>
      </div>
    </div >
  );
};

const App = () => {
  return (
    <>
      <HeaderApp />
      <ContentHome />
      <Bottom />
    </>
  );
};

export default App;
