/* eslint-disable */
import React, { useEffect, useContext, useState } from 'react';
import { tabs } from '../_constants';
import DatePickerCustom from 'components/_base/DatePicker';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import CurrencyInput from 'react-currency-input';

import { PhoneMask, PhoneUnMask, PhoneValidate } from 'helpers/Phone';
import api from 'api';
import {
  GET_LIST_ACAO_PROMOCIONAL_CATEGORIA,
  GET_ARTE_FACIL_CADASTRO_ITEM,
} from 'services/Solicitacao';
import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';

import './styles.scss';

function DadosAcao(props) {
  const { setActiveTab, model, setModel } = props;
  const { showLoader } = useContext(Context);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    vigenciaInicio: '',
    vigenciaFim: '',
    logoArte: false,
    acaoPromocional: true,
    observacao: '',
    erros: [],
  });
  const [campos, setCampos] = useState('');
  const [camposAcaoPromocional, setCamposAcaoPromocional] = useState('');
  const [acaoPromocionalSelecionada, setAcaoPromocionalSelecionada] =
    useState('');

  const validacaoFormulario = async () => {
    let todosErros = [];
    // validação de campos obrigatorios
    !formData.nome && todosErros.push('Nome');
    let validarEmail = /\S+@\S+\.\S+/;
    if (!formData.email || !validarEmail.test(formData.email)) {
      todosErros.push('Email');
    }
    let validacaoTelefone = PhoneValidate(formData.telefone);
    if (!validacaoTelefone) {
      todosErros.push('Telefone');
    }
    !formData.vigenciaInicio && todosErros.push('Inicio da Vigência');
    !formData.vigenciaFim && todosErros.push('Fim da Vigência');

    setFormData({
      ...formData,
      erros: todosErros,
    });

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const submitChangeModel = () => {
    let dadosEntregaDigital = {
      nome: formData.nome,
      telefone: formData.telefone,
      email: formData.email,
    };
    let dadosSolicitacaoEntregaDigital = {
      nomeResponsavel: formData.nome,
      telefoneResponsavel: formData.telefone,
      emailResponsavel: formData.email,
    };

    let dadosSolicitacoes = {
      dataVeiculacaoInicio: formData.vigenciaInicio,
      dataVeiculacaoFim: formData.vigenciaFim,
      possuiLogo: formData.logoArte,
      possuiAcaoPromocional: formData.acaoPromocional,
      observacoesGerais: formData.observacao,
      layoutArteId: model.layoutArteFacilId,
    };

    let dadoSolicitacaoAcaoPromocional = acaoPromocionalSelecionada.map(
      (itemAtual) => {
        return {
          acaoPromocionalId: itemAtual.acoesPromocionais.acaoPromocionalId,
          acaoPromocional: itemAtual.acoesPromocionais,
          solicitacaoAcaoPromocionalValores:
            itemAtual.acoesPromocionais.solicitacaoAcaoPromocionalValores,
        };
      }
    );
    dadoSolicitacaoAcaoPromocional = dadoSolicitacaoAcaoPromocional.filter(
      (itemAtual) => !!itemAtual.acaoPromocionalId
    );

    let solicitacoesAtualizadas = model.solicitacoes.map((value) => {
      if (formData.acaoPromocional) {
        return {
          ...value,
          imprimir: false,
          solicitacaoDados: dadosSolicitacoes,
          solicitacaoAcaoPromocionais: dadoSolicitacaoAcaoPromocional,
          solicitacaoEntregaDigital: dadosSolicitacaoEntregaDigital,
          layoutArteId: model.layoutArteFacilId,
          layout: model.layoutArteFacil,
        };
      } else {
        return {
          ...value,
          imprimir: false,
          solicitacaoDados: dadosSolicitacoes,
          solicitacaoEntregaDigital: dadosSolicitacaoEntregaDigital,
          layout: model.layoutArteFacil,
        };
      }
    });

    setModel({
      solicitacoes: solicitacoesAtualizadas,
      entregaDigital: dadosEntregaDigital,
    });
    setActiveTab(tabs.tabResumo);
  };

  const inputValue = (acaoPromocionalId, acaoPromocionalCampoId) => {
    let campoDesativo =
      (acaoPromocionalSelecionada || []).findIndex(
        (x) => x.acoesPromocionais.acaoPromocionalId === acaoPromocionalId
      ) === -1;
    if (campoDesativo) return '';

    let valor = '';
    (acaoPromocionalSelecionada || []).find((item) => {
      if (item.acoesPromocionais.acaoPromocionalId === acaoPromocionalId) {
        let valorAcao =
          item.acoesPromocionais.solicitacaoAcaoPromocionalValores.find(
            (valorAcaoAtual) =>
              valorAcaoAtual.acaoPromocionalCampoId === acaoPromocionalCampoId
          );
        valor = valorAcao.valor;
      }
      return valor;
    });
    return valor;
  };

  const onChangeValueInput = (
    valor,
    acaoPromocionalId,
    acaoPromocionalCampoId
  ) => {
    let valorAcaoPromocionalSelecionada = (
      acaoPromocionalSelecionada || []
    ).map((item) => {
      let campos = item.acoesPromocionais.solicitacaoAcaoPromocionalValores;
      if (item.acoesPromocionais.acaoPromocionalId === acaoPromocionalId) {
        let itemAlterar =
          item.acoesPromocionais.solicitacaoAcaoPromocionalValores.map(
            (value) =>
              value.acaoPromocionalCampoId === acaoPromocionalCampoId
                ? { ...value, valor: valor }
                : value
          );
        campos = itemAlterar;
      }
      return {
        ...item,
        acoesPromocionais: {
          ...item.acoesPromocionais,
          solicitacaoAcaoPromocionalValores: campos,
        },
      };
    });
    setAcaoPromocionalSelecionada(valorAcaoPromocionalSelecionada);
  };

  useEffect(() => {
    (async () => {
      showLoader(true);
      let camposAcaoPromocionalApi =
        await GET_LIST_ACAO_PROMOCIONAL_CATEGORIA();
      camposAcaoPromocionalApi = camposAcaoPromocionalApi.value;
      setCamposAcaoPromocional(camposAcaoPromocionalApi);

      console.log(model)

      if (
        model.solicitacoes !== null &&
        model.solicitacoes &&
        model.solicitacoes.length
      ) {
        setFormData({
          ...formData,
          nome: model.entregaDigital?.nome ? model.entregaDigital.nome : '',
          email: model.entregaDigital?.email ? model.entregaDigital.email : '',
          telefone: model.entregaDigital?.telefone
            ? model.entregaDigital.telefone
            : '',
          vigenciaInicio: model.solicitacoes[0].solicitacaoDados
            .dataVeiculacaoInicio
            ? new Date(
                model.solicitacoes[0].solicitacaoDados.dataVeiculacaoInicio
              )
            : '',
          vigenciaFim: model.solicitacoes[0].solicitacaoDados.dataVeiculacaoFim
            ? new Date(model.solicitacoes[0].solicitacaoDados.dataVeiculacaoFim)
            : '',
          logoArte: model.solicitacoes[0].solicitacaoDados.possuiLogo
            ? true
            : false,
          acaoPromocional: model.solicitacoes[0].solicitacaoDados.possuiAcaoPromocional || !model.entregaDigital?.nome?.length
            ? true
            : false,
          observacao: model.solicitacoes[0].solicitacaoDados.observacoesGerais,
        });
        if (
          model.solicitacoes[0].solicitacaoAcaoPromocionais &&
          model.solicitacoes[0].solicitacaoAcaoPromocionais.length
        ) {
          const initCamposSelecionados = camposAcaoPromocionalApi.map(
            (radioOption) => {
              let valueTeste = [];
              radioOption.acoesPromocionais.filter((valorAcaoAtual) => {
                let valueObj =
                  model.solicitacoes[0].solicitacaoAcaoPromocionais.filter(
                    (obj) =>
                      obj.acaoPromocionalId === valorAcaoAtual.acaoPromocionalId
                  );
                if (valueObj.length) {
                  valueTeste.push({
                    ...valorAcaoAtual,
                    ...valueObj[0],
                  });
                }
                return (
                  valueObj.length && {
                    ...valorAcaoAtual,
                    solicitacaoAcaoPromocionalValores: valueObj,
                  }
                );
              });
              return {
                acaoPromocionalCategoriaId:
                  radioOption.acaoPromocionalCategoriaId,
                acoesPromocionais: valueTeste.length ? valueTeste[0] : '',
              };
            }
          );
          setAcaoPromocionalSelecionada(initCamposSelecionados);
        } else {
          const initCamposSelecionados = camposAcaoPromocionalApi.map(
            (radioOption) => {
              return {
                acaoPromocionalCategoriaId:
                  radioOption.acaoPromocionalCategoriaId,
                acoesPromocionais: '',
              };
            }
          );
          setAcaoPromocionalSelecionada(initCamposSelecionados);
        }
      }

      const data = await GET_ARTE_FACIL_CADASTRO_ITEM();
      setCampos(data.value);

      showLoader(false);
    })();
  }, []);

  return (
    <div className="container pb-5">
      <TitlePages title="Escolha a ação promocional" />

      {
        model.errors?.length > 0 && (
          <div className="alert alert-danger">
            <ul className="m-0">
              {model.errors.map((e, index) => (
                <li key={index}>{e.error}</li>
              ))}
            </ul>
          </div>
        )
      }
      <div className="row">
        <div className="col-md-12">
          {/* <div className="solicitacao-panel">
            <div className="solicitacao-panel-header">Preencha os Dados</div> */}
            <div className="solicitacao-panel-body form-dados-acao">
              <div className="col-12">
                <p className="font-weight-bold">
                  Dados para envio da Arte Final
                </p>
                {formData.erros && formData.erros.length !== 0 && (
                  <div className="alert alert-danger">
                    {formData.erros.map((value) => {
                      return (
                        <p className="m-0" key={value}>
                          O campo {value} é obrigatório
                        </p>
                      );
                    })}
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-8 mb-4">
                    <label>Nome</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.nome}
                      onChange={(e) =>
                        setFormData({ ...formData, nome: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <label>Telefone</label>
                    <input
                      type="text"
                      className="form-control"
                      value={PhoneMask(formData.telefone)}
                      onChange={(e) => {
                        let phoneNoMask = PhoneUnMask(e.target.value);
                        if (phoneNoMask.length <= 11) {
                          setFormData({
                            ...formData,
                            telefone: phoneNoMask,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {campos &&
                campos
                  .sort(function (a, b) {
                    return a.ordem - b.ordem;
                  })
                  .map((itemAtual) => {
                    let dados;
                    if (itemAtual.key === 'Dados_Veiculacao') {
                      dados = (
                        <div>
                          <p className="font-weight-bold">
                            Dados da Arte
                          </p>
                          <div className="row">
                            <div className="col-lg-4">
                              <label>Início da Vigência</label>
                              <DatePickerCustom
                                selected={formData.vigenciaInicio}
                                minDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate() + 2)
                                  )
                                }
                                onChange={(value) => {
                                  setFormData({
                                    ...formData,
                                    vigenciaInicio: value,
                                    vigenciaFim: '',
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label>Fim da Vigência</label>
                              <DatePickerCustom
                                selected={formData.vigenciaFim}
                                minDate={new Date(
                                  formData.vigenciaInicio
                                ).setDate(
                                  new Date(formData.vigenciaInicio).getDate() +
                                    1
                                )}
                                disabled={!formData.vigenciaInicio}
                                onChange={(value) => {
                                  setFormData({
                                    ...formData,
                                    vigenciaFim: value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                    if (itemAtual.key === 'Dados_Logo') {
                      dados = (
                        <div>
                          <label>Inserir logo do cliente na arte?</label>
                          <div className="d-block">
                            <label className="mr-3">
                              <input
                                className="mr-1"
                                type="radio"
                                value={'true'}
                                checked={formData.logoArte === true}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    logoArte: true,
                                  });
                                }}
                              />
                              Sim
                            </label>
                            <label>
                              <input
                                className="mr-1"
                                type="radio"
                                value={'false'}
                                checked={formData.logoArte === false}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    logoArte: false,
                                  });
                                }}
                              />
                              Não
                            </label>
                          </div>
                        </div>
                      );
                    }
                    if (itemAtual.key === 'Dados_Observacao') {
                      dados = (
                        <div>
                          {' '}
                          <label>Observações gerais</label>
                          <textarea
                            className="form-control"
                            rows="5"
                            value={formData.observacao || ''}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                observacao: e.target.value,
                              });
                            }}
                          />{' '}
                        </div>
                      );
                    }
                    if (itemAtual.key === 'Dados_AcaoPromocional') {
                      dados = (
                        <div key={itemAtual.key}>
                          <label>
                            Deseja incluir ação promocional ou preço?
                          </label>
                          <div className="d-block">
                            <label className="mr-3">
                              <input
                                className="mr-1"
                                type="radio"
                                value={'true'}
                                checked={formData.acaoPromocional === true}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    acaoPromocional: true,
                                  });
                                }}
                              />
                              Sim
                            </label>
                            <label>
                              <input
                                className="mr-1"
                                type="radio"
                                value={'false'}
                                checked={formData.acaoPromocional === false}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    acaoPromocional: false,
                                  });
                                }}
                              />
                              Não
                            </label>
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div className="col-lg-12 mb-4" key={itemAtual.key}>
                        {dados}
                      </div>
                    );
                  })}

              {/* Se deseja incluir ação promocional, exibir os campos */}
              {formData.acaoPromocional && (
                <div className="col-lg-12 mb-4">
                  <p className="font-weight-bold">
                    Selecione abaixo as opções de ação promocional para a sua
                    arte:
                  </p>
                  {camposAcaoPromocional
                    ? camposAcaoPromocional.map((acao) => (
                        <div key={acao.texto}>
                          <div className="titulo-acao-promocional mb-2">
                            {acao.texto}
                          </div>
                          <div className="row">
                            {acao.acoesPromocionais.map((item) => (
                              <label key={item.texto} className="col-md-4 mb-3">
                                <input
                                  className="mr-1"
                                  type="radio"
                                  value={item.acaoPromocionalId}
                                  id={item.acaoPromocionalId}
                                  onChange={() => {
                                    let novaListaSelecionados =
                                      acaoPromocionalSelecionada.map(
                                        (selecionado) => {
                                          if (
                                            selecionado.acaoPromocionalCategoriaId ===
                                            acao.acaoPromocionalCategoriaId
                                          ) {
                                            let campoValores = item.campos.map(
                                              (campoAtualValores) => {
                                                return {
                                                  acaoPromocionalCampoTipoId:
                                                    campoAtualValores.acaoPromocionalCampoTipoId,
                                                  acaoPromocionalCampoId:
                                                    campoAtualValores.acaoPromocionalCampoId,
                                                  acaoPromocionalId:
                                                    campoAtualValores.acaoPromocionalId,
                                                  acaoPromocionalCampo:
                                                    campoAtualValores,
                                                  valor: '',
                                                };
                                              }
                                            );
                                            return {
                                              ...selecionado,
                                              acoesPromocionais: {
                                                ...item,
                                                solicitacaoAcaoPromocionalValores:
                                                  campoValores,
                                              },
                                            };
                                          }
                                          return selecionado;
                                        }
                                      );
                                    setAcaoPromocionalSelecionada(
                                      novaListaSelecionados
                                    );
                                  }}
                                  checked={
                                    (
                                      acaoPromocionalSelecionada || []
                                    ).findIndex(
                                      (x) =>
                                        x.acoesPromocionais
                                          .acaoPromocionalId ===
                                        item.acaoPromocionalId
                                    ) !== -1
                                  }
                                />
                                {item.texto}
                                <div className="row">
                                  <div className="col-4">
                                    <img
                                      src={item.urlImagem}
                                      alt={item.texto}
                                      className="promocional"
                                      htmlFor={item.acaoPromocionalId}
                                    />
                                  </div>
                                  {item.campos &&
                                    item.campos.map((campo) => (
                                      <div
                                        className="col"
                                        key={campo.acaoPromocionalCampoId}
                                      >
                                        <div className="d-flex flex-row">
                                          {campo.acaoPromocionalCampoTipo
                                            .nome === 'string' && (
                                            <label className="mr-1">
                                              {campo.texto}
                                              <input
                                                type="text"
                                                className="form-control form-promocional"
                                                value={inputValue(
                                                  campo.acaoPromocionalId,
                                                  campo.acaoPromocionalCampoId
                                                )}
                                                onChange={(e) =>
                                                  onChangeValueInput(
                                                    e.target.value,
                                                    campo.acaoPromocionalId,
                                                    campo.acaoPromocionalCampoId
                                                  )
                                                }
                                                disabled={
                                                  (
                                                    acaoPromocionalSelecionada ||
                                                    []
                                                  ).findIndex(
                                                    (x) =>
                                                      x.acoesPromocionais
                                                        .acaoPromocionalId ===
                                                      item.acaoPromocionalId
                                                  ) === -1
                                                }
                                              />
                                            </label>
                                          )}
                                          {campo.acaoPromocionalCampoTipo
                                            .nome === 'integer' && (
                                            <label className="mr-1">
                                              {campo.texto}
                                              <input
                                                type="number"
                                                className="form-control form-promocional"
                                                value={inputValue(
                                                  campo.acaoPromocionalId,
                                                  campo.acaoPromocionalCampoId
                                                )}
                                                onChange={(e) => {
                                                  if (
                                                    parseInt(e.target.value) >=
                                                    0
                                                  ) {
                                                    onChangeValueInput(
                                                      parseInt(e.target.value),
                                                      campo.acaoPromocionalId,
                                                      campo.acaoPromocionalCampoId
                                                    );
                                                  }
                                                }}
                                                disabled={
                                                  (
                                                    acaoPromocionalSelecionada ||
                                                    []
                                                  ).findIndex(
                                                    (x) =>
                                                      x.acoesPromocionais
                                                        .acaoPromocionalId ===
                                                      item.acaoPromocionalId
                                                  ) === -1
                                                }
                                              />
                                            </label>
                                          )}
                                          {campo.acaoPromocionalCampoTipo
                                            .nome === 'decimal' && (
                                            <label className="mr-1">
                                              {campo.texto}
                                              <CurrencyInput
                                                decimalSeparator=","
                                                thousandSeparator=""
                                                className="form-control form-promocional"
                                                value={inputValue(
                                                  campo.acaoPromocionalId,
                                                  campo.acaoPromocionalCampoId
                                                )}
                                                onChangeEvent={(e) => {
                                                  if (
                                                    parseInt(e.target.value) >=
                                                    0
                                                  ) {
                                                    onChangeValueInput(
                                                      e.target.value.replace(
                                                        ',',
                                                        '.'
                                                      ),
                                                      campo.acaoPromocionalId,
                                                      campo.acaoPromocionalCampoId
                                                    );
                                                  }
                                                }}
                                                disabled={
                                                  (
                                                    acaoPromocionalSelecionada ||
                                                    []
                                                  ).findIndex(
                                                    (x) =>
                                                      x.acoesPromocionais
                                                        .acaoPromocionalId ===
                                                      item.acaoPromocionalId
                                                  ) === -1
                                                }
                                              />
                                            </label>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </label>
                            ))}
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              )}

              {/* Controle tab, prev/next */}
              <div className="row mt-5">
                <div className="col-sm-6 text-left">
                  <button
                    className="btn btn-primary btn-navigation"
                    onClick={() => {
                      // updateRascunho();
                      setActiveTab(tabs.tabTamanho);
                    }}
                  >
                    <span>
                      <FaAngleLeft size={26} />
                    </span>
                    <span>VOLTAR</span>
                  </button>
                </div>
                <div className="col-sm-6 d-flex flex-row-reverse">
                  <button
                    className={`btn btn-primary btn-navigation btn-navigation-right ${
                      true ? '' : 'btn-disabled'
                    }`}
                    onClick={async () => {
                      const validacao = await validacaoFormulario();
                      if (validacao) {
                        submitChangeModel();
                      }
                    }}
                  >
                    <span>AVANÇAR</span>
                    <span>
                      <FaAngleRight size={26} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          {/* </div>  */}
        </div>
      </div>
    </div>
  );
}

export default DadosAcao;
