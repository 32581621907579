/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { FaAngleRight, FaHome, FaSearch } from 'react-icons/fa';

import { GET_URL_LOGO_PADRAO, GET_LIST_LOJA } from 'services/Solicitacao';
import { UPLOAD_LOGO } from 'services/Solicitacao';
import RequestMaterialsActions from 'store/modules/request-materials/actions';
import TitlePages from 'components/_base/TitlePages';
import InputFileCustom from 'components/_base/InputFile';

import { tabs, passos } from '../_constants';
import './styles.scss';

const Cliente = (props) => {
  let { setLoader, updateRascunho, model, renderErrors, setActiveTab } = props;
  let errors = (model.errors || []).filter(
    (error) => error.passo === passos.passoCliente.nro
  );

  model.loja = model.loja || {};

  const dispatch = useDispatch();

  const [cliente, setCliente] = useState(model.loja || {});
  const [clientSelected, setClientSelected] = useState(null);
  const [clientsList, setClientsList] = useState([]);
  const [logoPadrao, setLogoPadrao] = useState('');
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [withBrand, setWithBrand] = useState(true);

  const updateCliente = (obj) => {
    model.loja = obj;
    model.lojaId = obj.lojaId || 0;
    setCliente(obj);
  };

  useEffect(() => {
    setCliente(model.loja || {});
  }, [model]);

  useEffect(() => {
    (async () => {
      setLoader(true);
      const resultLogoPadrao = await GET_URL_LOGO_PADRAO();

      if (resultLogoPadrao.value) setLogoPadrao(resultLogoPadrao.value);

      setLoader(false);
    })();
  }, [false]);

  const searchByCodeCNPJ = (event) => {
    setSearch(event.target.value);
  };

  const searchByName = (event) => {
    setSearch(event.target.value);
  };

  const onSearch = async () => {
    setLoader(true);
    const data = await GET_LIST_LOJA({ codigoCnpjRazaoSocial: search });
    if (data.value.length === 1) {
      updateCliente(data.value[0]);
    } else {
      setClientsList(data.value);
      setShowModal(true);
    }

    setLoader(false);
  };

  const selectClient = (index) => {
    setClientSelected(index);
    updateCliente(clientsList[index]);

    const action = RequestMaterialsActions.setCustomer(clientsList[index]);
    dispatch(action);

    setWithBrand(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const setSettingsBrand = (event) => {
    setWithBrand(event.target.value === 'true');
  };

  const uploadLogo = async (event) => {
    event.preventDefault();

    setLoader(true);
    const formData = new FormData();

    formData.append('lojaId', cliente.lojaId);
    formData.append('file', event.target.files[0]);

    const data = await UPLOAD_LOGO(formData);

    updateCliente({ ...cliente, urlLogo: data.value });

    setLoader(false);
  };

  return (
    <div className="wrapper-content-pedido-cliente">
      <TitlePages title="Selecionar Rede" />
      <div className="content-pedido-cliente">
        {renderErrors(errors)}

        <div className="content-cliente-search">
          <div id="busca" className=" busca">
            {/* <div className="row"> */}
              <div className="col-md-12 p-0">
                {/* <label htmlFor="">Nome</label> */}
                <div className="input-with-icon">
                  <input type="text" onChange={searchByName} onKeyDown={(e) => e.key === 'Enter' && onSearch()}/>
                  <div className="icon" onClick={onSearch}>
                    <FaSearch size={22} />
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
        <Modal show={showModal} onHide={hideModal} className="modal-busca-loja">
          <Modal.Header closeButton className="header">
            <Modal.Title>Redes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Rede</th>
                </tr>
              </thead>
              <tbody>
                {clientsList.map((item, index) => {
                  return (
                    <tr
                      className={
                        clientSelected === index
                          ? 'tr-selecao-loja tr-selecionado'
                          : 'tr-selecao-loja'
                      }
                      key={index}
                      onClick={() => selectClient(index)}
                      style={{
                        backgroundColor:
                          clientSelected === index
                            ? 'rgba(40, 109, 184,.5)'
                            : 'inherit',
                      }}
                    >
                      <td>{item.razaoSocial}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button
              variant="primary"
              onClick={hideModal}
              className="button-close"
            >
              CONCLUIR
            </Button>
          </Modal.Footer>
        </Modal>

        {
          cliente?.lojaId > 0 &&
          <div className="display-client pb-5">
            <div className="mb-4">
              <div>
                <label htmlFor="">Rede: </label>
                <span>{cliente.razaoSocial ? cliente.razaoSocial : ''}</span>
              </div>

              {/* <div>
              <label htmlFor="">CNPJ: </label>
              <span>{cliente.cnpj ? cliente.cnpj : ''}</span>
            </div>

            <div>
              <label htmlFor="">CEP: </label>
              <span>{cliente.cep ? cliente.cep : ''}</span>
            </div>

            <div>
              <label htmlFor="">Logradouro: </label>
              <span>{cliente.logradouro ? cliente.logradouro : ''}</span>
            </div>

            <div>
              <label htmlFor="">Número: </label>
              <span>{cliente.numero ? cliente.numero : ''}</span>
            </div>

            <div>
              <label htmlFor="">Bairro: </label>
              <span>{cliente.bairro ? cliente.bairro : ''}</span>
            </div>

            <div>
              <label htmlFor="">Cidade: </label>
              <span>
                {cliente.cidade && cliente.cidade.nome
                  ? cliente.cidade.nome + ' - ' + cliente.cidade.uf
                  : ''}
              </span>
            </div> */}
            </div>
            <div className="set-settings-brand">
              <div
                style={{ opacity: cliente?.lojaId > 0 ? 1 : 0 }}
              >
                <input
                  type="radio"
                  id="set-brand"
                  name="with-brand"
                  onChange={setSettingsBrand}
                  value={true}
                  checked={withBrand}
                />
                <label htmlFor="set-brand">Com Logo</label>

                <input
                  type="radio"
                  id="set-no-brand"
                  name="with-brand"
                  onChange={setSettingsBrand}
                  value={false}
                  checked={!withBrand}
                />
                <label htmlFor="set-no-brand">Sem Logo</label>

                {withBrand ? (
                  <div>
                    {cliente.urlLogo && (
                      <img
                        className="img-logo"
                        src={cliente.urlLogo ? cliente.urlLogo : ''}
                        alt=""
                      />
                    )}
                    <div className="mt-1"></div>
                    <InputFileCustom
                      name="Logo"
                      onChange={uploadLogo}
                    ></InputFileCustom>
                  </div>
                ) : (
                  <div style={{ marginTop: '20px' }}>
                    <div className="msg-logo-padrao text-center">
                      Seus materiais serão impressos com o logo padrão.
                    </div>
                    {/* <img className="img-logo" src={logoPadrao} alt="" /> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        }



      </div>
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-between">
          <div className="text-left">
            <Link to="/">
              <button className="btn btn-primary btn-navigation">
                <span className="pr-2">
                  <FaHome size={18} />
                </span>
                <span>PÁGINA INICIAL</span>
              </button>
            </Link>
          </div>

          <div className=" d-flex flex-row-reverse">
            <button
              className={`btn btn-primary btn-navigation btn-navigation-right ${cliente.lojaId > 0 ? '' : 'btn-disabled'
                }`}
              onClick={() => {
                if (cliente.lojaId > 0) {
                  updateRascunho();

                  if (model.pedidoDeCampanha)
                    setActiveTab(tabs.tabLayoutCampanha);
                  else setActiveTab(tabs.tabMateriais);
                }
              }}
            >
              <span>AVANÇAR</span>
              <span>
                <FaAngleRight size={24} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cliente;
