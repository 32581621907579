/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { FaAngleLeft, FaAngleRight, FaFilter, FaEraser } from 'react-icons/fa';
import DatePickerCustom from 'components/_base/DatePicker';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { Context } from 'context/AuthContext';
import { tabs } from '../_constants';
import { GET_SELECT_LIST } from 'services/Produto';
import {
  GET_SELECT_LIST_CATEGORIA,
  GET_SELECT_LIST_MARCA,
} from 'services/ProdutoCategoria';
import TitlePages from 'components/_base/TitlePages';

import api from 'api';
import { GET_LIST_ARTE_FACIL } from 'services/LayoutArte';

const Layout = (props) => {
  const { setActiveTab, model, setModel } = props;
  const { showLoader } = useContext(Context);

  const [filtro, setFiltro] = useState({
    nome: '',
    marca: '',
    categoriaProduto: '',
    produto: '',
    vigenciaInicio: '',
    vigenciaFim: '',
    PageSize: 16,
  });
  const [marca, setMarca] = useState([]);
  const [categoriaProduto, setCategoriaProduto] = useState('');
  const [produto, setProduto] = useState('');

  const [lista, setLista] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState('');
  const [layout, setLayout] = useState(
    model.layoutArteFacilId ? props.model.layoutArteFacilId : ''
  );

  useEffect(() => {
    (async () => {
      showLoader(true);
      const result = await GET_SELECT_LIST_MARCA();
      setMarca(result.value[0].options);

      const data = await GET_LIST_ARTE_FACIL({
        PageSize: filtro.PageSize,
      });
      setLista(data.value);
      setListaFiltrada(data.value);

      showLoader(false);
    })();
  }, []);

  const getCategoriaProduto = async (marcaId) => {
    showLoader(true);
    const result = await GET_SELECT_LIST_CATEGORIA({
      ids: marcaId,
    });
    setCategoriaProduto(result.value[0].options);
    showLoader(false);
  };

  const onClickFiltrarLayout = async () => {
    const result = await GET_LIST_ARTE_FACIL({
      Descricao: filtro.nome,
      MarcaIds: filtro.marca.value,
      CategoriaProdutoIds: filtro.categoriaProduto.value,
      ProdutoIds: filtro.produto.value,
      DataInicio: filtro.vigenciaInicio,
      DataFim: filtro.vigenciaFim,
      PageSize: filtro.PageSize,
    });
    setListaFiltrada(result.value);
  };

  const getProduto = async (categoriaProdutoId) => {
    const result = await GET_SELECT_LIST({
      ids: categoriaProdutoId,
    });
    setProduto(result.value[0].options);
  };

  const clearFilter = async () => {
    setFiltro({
      nome: '',
      marca: '',
      categoriaProduto: '',
      produto: '',
      vigenciaInicio: '',
      vigenciaFim: '',
    });
    setCategoriaProduto('');
    setProduto('');
    setListaFiltrada(lista);
  };

  const changePage = async (page) => {
    showLoader(true);
    const result = await GET_LIST_ARTE_FACIL({
      Descricao: filtro.nome,
      MarcaIds: filtro.marca.value,
      CategoriaProdutoIds: filtro.categoriaProduto.value,
      ProdutoIds: filtro.produto.value,
      DataInicio: filtro.vigenciaInicio,
      DataFim: filtro.vigenciaFim,
      PageSize: filtro.PageSize,
      PageNumber: page,
    });
    setListaFiltrada(result.value);
    showLoader(false);
  };

  const Paginacao = (totalPaginas, numeroPagina, mudarPagina) => {
    let totalItemPaginacao = [];
    // botão para pagina anterior
    totalItemPaginacao.push(
      <>
        <Pagination.First
          disabled={numeroPagina === 1}
          onClick={() => {
            mudarPagina(1);
          }}
        />
        <Pagination.Prev
          disabled={numeroPagina === 1}
          onClick={() => {
            mudarPagina(numeroPagina - 1);
          }}
        >
          Anterior
        </Pagination.Prev>
      </>
    );

    for (let number = 1; number <= totalPaginas; number++) {
      // To do
      // arrumar essa validação
      if (
        number === numeroPagina ||
        number === numeroPagina + 1 ||
        number === numeroPagina + 2 ||
        number === numeroPagina - 1 ||
        number === numeroPagina - 2
      ) {
        totalItemPaginacao.push(
          <Pagination.Item
            key={number}
            active={number === numeroPagina}
            onClick={() => {
              mudarPagina(number);
            }}
          >
            {number}
          </Pagination.Item>
        );
      }
    }

    // Botão prox pagina e ultima pagina
    totalItemPaginacao.push(
      <>
        <Pagination.Next
          disabled={numeroPagina === totalPaginas}
          onClick={() => {
            mudarPagina(numeroPagina + 1);
          }}
        >
          Próxima
        </Pagination.Next>
        <Pagination.Last
          disabled={numeroPagina === totalPaginas}
          onClick={() => {
            mudarPagina(totalPaginas);
          }}
        />
      </>
    );

    return <Pagination>{totalItemPaginacao}</Pagination>;
  };

  return (
    <div className="container pb-5">
      <TitlePages title="Escolha o Layout" />
      <div className="row mb-3 d-flex align-items-center ">
        <div className="col-lg-2 text-left">Nome</div>
        <div className="col-lg-10">
          <input
            type="text"
            className="form-control"
            value={filtro.nome}
            onChange={(event) => {
              setFiltro({
                ...filtro,
                nome: event.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="row mb-3 d-flex align-items-center">
        <div className="col-lg-2 text-left">Marca</div>
        <div className="col-lg-5">
          <Select
            name="colors"
            options={marca}
            classNamePrefix="select"
            placeholder="Selecione..."
            onChange={(item) => {
              getCategoriaProduto(item.value);
              setFiltro({
                ...filtro,
                marca: item,
                categoriaProduto: '',
                produto: '',
              });
              setProduto('');
            }}
            value={filtro.marca}
          />
        </div>
      </div>
      <div className="row mb-3 d-flex align-items-center">
        <div className="col-lg-2 text-left">Categoria do Produto</div>
        <div className="col-lg-5">
          <Select
            name="colors"
            options={categoriaProduto}
            classNamePrefix="select"
            placeholder="Selecione..."
            isDisabled={!categoriaProduto}
            onChange={(item) => {
              getProduto(item.value);
              setFiltro({
                ...filtro,
                categoriaProduto: item,
                produto: '',
              });
            }}
            value={filtro.categoriaProduto}
          />
        </div>
      </div>
      <div className="row mb-3 d-flex align-items-center">
        <div className="col-lg-2 text-left">Produto</div>
        <div className="col-lg-5">
          <Select
            name="colors"
            options={produto}
            isDisabled={!produto}
            classNamePrefix="select"
            placeholder="Selecione..."
            onChange={(item) =>
              setFiltro({
                ...filtro,
                produto: item,
              })
            }
            value={filtro.produto}
          />
        </div>
      </div>
      <div className="row mb-3 d-flex align-items-center ">
        <div className="col-lg-2 text-left">Vigencia</div>
        <div className="col-lg-5">
          <DatePickerCustom
            selected={filtro.vigenciaInicio}
            onChange={(item) => {
              if (filtro.vigenciaFim) {
                if (item < filtro.vigenciaFim) {
                  setFiltro({
                    ...filtro,
                    vigenciaInicio: item,
                  });
                } else {
                  setFiltro({
                    ...filtro,
                    vigenciaInicio: item,
                    vigenciaFim: '',
                  });
                }
              } else {
                setFiltro({
                  ...filtro,
                  vigenciaInicio: item,
                });
              }
            }}
          />
        </div>
        <div className="col-lg-5">
          <DatePickerCustom
            selected={filtro.vigenciaFim}
            onChange={(item) => {
              if (item > filtro.vigenciaInicio) {
                if (filtro.vigenciaInicio) {
                  setFiltro({
                    ...filtro,
                    vigenciaFim: item,
                  });
                } else {
                  setFiltro({
                    ...filtro,
                    vigenciaFim: item,
                    vigenciaInicio: item,
                  });
                }
              } else {
                setFiltro({
                  ...filtro,
                  vigenciaFim: filtro.vigenciaInicio,
                });
              }
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 offset-lg-2 d-flex justify-content-start">
          <button
            className="btn btn-primary btn-navigation mr-3"
            onClick={() => {
              onClickFiltrarLayout();
            }}
          >
            <span>
              <FaFilter size={18} />
            </span>
            <span>FILTRAR</span>
          </button>
          <Button
            id="limpar"
            className="query-button btn-navigation"
            variant="outline-primary"
            onClick={() => {
              clearFilter();
            }}
          >
            <span>
              <FaEraser size={22} />
            </span>
            <span>LIMPAR</span>
          </Button>
        </div>
      </div>
      <hr />
      <div className="row mt-5 justify-content-center">
        {/* <div className="col-lg-12 justify-content-center"> */}
          {listaFiltrada &&
            listaFiltrada.data.map((item) => {
              return (
                <div
                  key={item.layoutArteId}
                  className={`card-material ${item.layoutArteId === layout ? 'selecionado' : ''
                    }`}
                  onClick={() => {
                    setLayout(item.layoutArteId);
                  }}
                >
                  <div className="lista-campanha-materiais">
                    <div className="header-card">{item.descricao}</div>
                    <div className="body-card">
                      <label>
                        <img
                          src={item.urlImagemModelo}
                          alt={item.descricao}
                          className="img-fluid"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
        {/* </div> */}
        {/* <div className="col-md-3">
              <ItemList
                value={item.layoutArteId}
                imagem={item.urlImagemModelo}
                nome={item.descricao}
              />
            </div> */}
      </div>
      <div className="row mt-5">
        {listaFiltrada.totalPages > 1 ? (
          <div className="col-md-12 mt-3 d-flex align-items-center flex-column">
            {Paginacao(
              listaFiltrada.totalPages,
              listaFiltrada.pageNumber,
              changePage
            )}
            <div>
              <p>
                Página {listaFiltrada.pageNumber} de {listaFiltrada.totalPages}{' '}
              </p>
            </div>
          </div>
        ) : null}
      </div>
      <div className="row mt-5">
        <div className="col-sm-6 text-left">
          <button
            className="btn btn-primary btn-navigation"
            onClick={() => {
              // updateRascunho();
              setActiveTab(tabs.tabCliente);
            }}
          >
            <span>
              <FaAngleLeft size={26} />
            </span>
            <span>VOLTAR</span>
          </button>
        </div>

        <div className="col-sm-6 d-flex flex-row-reverse">
          <button
            className={`btn btn-primary btn-navigation btn-navigation-right ${layout ? '' : 'btn-disabled'
              }`}
            onClick={() => {
              // updateRascunho();
              if (layout) {
                let layoutArte = lista.data.filter((value) => {
                  return value.layoutArteId === layout;
                });
                setModel({
                  layoutArteFacil: layoutArte[0],
                  layoutArteFacilId: layout,
                });
                setActiveTab(tabs.tabTamanho);
              }
            }}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Layout;
