/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-else-return */
/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

import {
  passos,
  entregaTipoNomes,
  solicitacaoTabs,
} from 'pages/PedidoMateriais/ContentPedido/_constants';

import EntregaFisica from './EntregaFisica';
import EntregaDigital from './EntregaDigital';

import './styles.scss';

const Entrega = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  let solicitacao = props.solicitacao;
  let loja = props.loja;
  let entities = props.entities;
  let urlSolicitacao = props.urlSolicitacao;
  let renderErrors = props.renderErrors;
  let changeTab = props.changeTab;

  let tipoMateriais = entities.tipoMateriais || [];
  let tipoEntregas = entities.tipoEntregas || [];

  let materiais = [].concat(
    ...tipoMateriais.map((tipoMaterial) => tipoMaterial.materiaisComunicacao)
  );
  let material = materiais.find(
    (material) =>
      material.materialComunicacaoId === solicitacao.materialComunicacaoId
  );

  let errors = (solicitacao.errors || []).filter(
    (error) => error.passo === passos.passoEntrega.nro
  );

  const [entregaTipoId, setEntregaTipoId] = useState(0);
  useEffect(() => {
    setEntregaTipoId(solicitacao.entregaTipoId || 0);
  }, [solicitacao]);

  // const initialTab = existeMaterialComProducao() ? entregaTabs.tabFisica : entregaTabs.tabDigital;
  // const currentTab = location.pathname.split("/")[4] || initialTab;
  // const [entregaActiveTab, setEntregaActiveTab] = useState(currentTab);

  // const changeTab = (entregaTab) => {
  //   history.push(`${urlSolicitacao}/${tabs.tabEntrega}/${entregaTab}`);
  // };

  // useEffect(() => {
  //   return history.listen((location) => {
  //     let tab = location.pathname.split("/")[4] || initialTab;
  //     setEntregaActiveTab(tab);
  //   })
  // }, [history]);

  // useEffect(() => {
  //   var array = location.pathname.split("/");
  //   if (solicitacao.solicitacaoId && array.length === 4) {
  //     history.push(`${urlSolicitacao}/${tabs.tabEntrega}/${initialTab}`);
  //   }
  // }, [model]);

  const renderTabs = () => {
    if (!solicitacao.solicitacaoId) return null;

    if (!tipoMateriais.length) return null;

    if (!tipoEntregas.length) return null;

    if (!material?.possuiProducao) {
      let digital = tipoEntregas.find((x) => x.entregaDigital);
      solicitacao.entregaTipoId = digital.entregaTipoId;
    }

    let entregaTipo = tipoEntregas.find(
      (x) => x.entregaTipoId === solicitacao.entregaTipoId
    );
    // if (existeMaterialComProducao()) {
    if (entregaTipo.nome === entregaTipoNomes.digital)
      return <EntregaDigital solicitacao={solicitacao} entities={entities} />;
    else
      return (
        <EntregaFisica
          solicitacao={solicitacao}
          loja={loja}
          entities={entities}
        />
      );
  };

  const renderPergunta = () => {
    if (!solicitacao.solicitacaoId) return null;

    if (!tipoEntregas.length) return null;

    if (!tipoMateriais.length) return null;

    if (!material?.possuiProducao) return null;

    let entregaTipo = tipoEntregas.find(
      (x) => x.entregaTipoId === solicitacao.entregaTipoId
    );
    return (
      <div className="text-center mt-5">
        <h4>Deseja que o material seja impresso e enviado?</h4>
        <div className="d-block">
          <label className="mr-3">
            <input
              type="radio"
              value={'true'}
              checked={entregaTipo.entregaFisica}
              onChange={(e) => {
                solicitacao.entregaTipoId = tipoEntregas.find(
                  (x) => x.entregaFisica
                ).entregaTipoId;
                setEntregaTipoId(solicitacao.entregaTipoId);
              }}
            />{' '}
            Sim
          </label>

          <label>
            <input
              type="radio"
              value={'false'}
              checked={!entregaTipo.entregaFisica}
              onChange={(e) => {
                solicitacao.entregaTipoId = tipoEntregas.find(
                  (x) => !x.entregaFisica
                ).entregaTipoId;
                setEntregaTipoId(solicitacao.entregaTipoId);
              }}
            />{' '}
            Não
          </label>
        </div>
        <hr />
      </div>
    );
  };

  return (
    <div className="wrapper-content-entrega">
      {renderErrors(errors)}

      {renderPergunta()}

      {renderTabs()}

      <div className="row">
        {/* <div className="col-sm-6 text-left">
            <button className="btn btn-primary btn-navigation" onClick={() => {history.push("/")}} >
              <span><FaAngleLeft size={26} /></span>
              <span>VOLTAR</span>
            </button>
          </div> */}

        <div className="col-lg-12 d-flex flex-row-reverse">
          <button
            className="btn btn-primary btn-navigation btn-navigation-right"
            onClick={() => {
              changeTab(solicitacaoTabs.tabLayout);
            }}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>

        {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}
      </div>
    </div>
  );
};

export default Entrega;
