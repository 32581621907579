import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import lineBreaker from 'helpers/LineBreaker';
import SemDados from '../SemDados';

const marcasFixasArray = ['BASSI', 'MONTANA STEAKHOUSE', 'MONTANA'];
const coresFixas = ['#ed1d24', '#fa6e0a', '#011689'];

const MarcaSolicitacoes = (props) => {
  const { data } = props;

  const titulo = 'Solicitações por marca';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const marcas = Object.keys(data);

  const dataMarcasFixas = {};
  const dataMarcasOutros = {};

  // filtrando marcas fixas e outras
  marcas.forEach((marca) => {
    if (marcasFixasArray.includes(marca.toUpperCase())) {
      dataMarcasFixas[marca] = data[marca];
    } else {
      dataMarcasOutros[marca] = data[marca];
    }
  });

  // adicionando OUTROS
  dataMarcasFixas.OUTROS = {
    quantidade: 0,
    percentual: 0,
  };

  // somando quantidade e percentual de OUTROS
  Object.keys(dataMarcasOutros).forEach((marca) => {
    dataMarcasFixas.OUTROS.quantidade += dataMarcasOutros[marca].quantidade;
    dataMarcasFixas.OUTROS.percentual += dataMarcasOutros[marca].percentual;
  });

  const marcasFixas = Object.keys(dataMarcasFixas);
  // const marcasOutros = Object.keys(dataMarcasOutros);

  const series = [
    { data: marcasFixas.map((marca) => dataMarcasFixas[marca].quantidade) },
  ];

  const cores = marcasFixas.map((marca) => {
    return (
      coresFixas[marcasFixasArray.indexOf(marca.toUpperCase())] || '#d3d3d3'
    );
  });

  const options = {
    legend: {
      show: false,
      showForSingleSeries: true,
    },
    colors: cores,
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
        colors: {},
      },
    },
    xaxis: {
      show: true,
      labels: {
        formatter: (value) => {
          const lines = lineBreaker(value.toUpperCase(), 16);
          return lines;
        },
        show: true,
        rotate: 0,
        style: {
          fontWeight: 600,
        },
      },
      categories: marcasFixas,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    chart: {
      id: 'mar-solicitacoes',
      parentHeightOffset: 0,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: -19,
      formatter: (value, { dataPointIndex }) => {
        return `${value}(${dataMarcasFixas[
          marcasFixas[dataPointIndex]
        ].percentual.toFixed(2)}%)`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return marcasFixasArray.includes(
          marcasFixas[dataPointIndex].toUpperCase()
        )
          ? `<div class="arrow_box">
            <span>
              Marca: <span id="data">${marcasFixas[
                dataPointIndex
              ].toUpperCase()}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${
                series[seriesIndex][dataPointIndex]
              }</span>
            </span>
            <span>
              %: <span id="data">${dataMarcasFixas?.[
                marcasFixas[dataPointIndex]
              ].percentual.toFixed(2)}</span>
            </span>
          </div>
          `
          : `<div class="arrow_box">
              <span>Outras Marcas:</span>
              ${Object.keys(dataMarcasOutros)
                .map((marca) => {
                  return `<span>${marca.toUpperCase()}: <span id="data">${
                    dataMarcasOutros?.[marca]?.quantidade
                  } (${dataMarcasOutros?.[marca]?.percentual.toFixed(
                    2
                  )}%)</span></span>`;
                })
                .join('')}
            </div>`;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="bar" height="250" />
    </div>
  );
};

export default MarcaSolicitacoes;
