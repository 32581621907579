import React from 'react';
import VMasker from 'vanilla-masker';

export default ({ className, value, onChange }) => {
  return (
    <input
      type="text"
      className={`form-control ${className}`}
      value={value}
      onChange={(event) => {
        let input = event.target.value;
        input = VMasker.toPattern(input, '99999999999999999999');

        if (input.length > 11) {
          input = VMasker.toPattern(input, '99.999.999/9999-99');
        } else {
          input = VMasker.toPattern(input, '999.999.999-99');
        }
        onChange(input);
      }}
    />
  );
};
