import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import Extrato from './Extrato';
import ExtratoParcial from './ExtratoParcial';
import Produtividade from './Produtividade';
import './styles.scss';

const ContentRelatorios = () => {
  const [activeTab, setActiveTab] = useState('produtividade');

  return (
    <div className="content_relatorios">
      <h1 className="relatorio-title">RELATÓRIOS</h1>
      <Tabs
        onSelect={(k) => setActiveTab(k)}
        defaultActiveKey="produtividade"
        id="tab-relatorios"
      >
        <Tab eventKey="produtividade" title="Produtividade">
          {activeTab === 'produtividade' && <Produtividade />}
        </Tab>
        <Tab eventKey="extrato" title="Extrato Mensal">
          {activeTab === 'extrato' && <Extrato />}
        </Tab>
        <Tab eventKey="extratoparcial" title="Extrato Parcial">
          {activeTab === 'extratoparcial' && <ExtratoParcial />}
        </Tab>
      </Tabs>
    </div>
  );
};

export default ContentRelatorios;
