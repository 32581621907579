import api from 'api';

const PATH = '/ExtratoSolicitacaoCusto';

const GET_EXTRATO_CUSTO_BYTES = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-extrato-custo-em-bytes-por-filtro`,
    {
      params: filtros,
    }
  );
  return data;
};

const GET_EXTRATO_CUSTO_ARQUIVO = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-extrato-custo-em-arquivo-por-filtro`,
    {
      params: filtros,
    }
  );
  return data;
};

// Retorna as regionais aos quais o usuario tem acesso ao relatorio
const GET_SELECT_LIST_REGIONAL = async () => {
  const { data } = await api.get(`${PATH}/get-regionais-select-list`);
  return data;
};

export {
  GET_EXTRATO_CUSTO_BYTES,
  GET_EXTRATO_CUSTO_ARQUIVO,
  GET_SELECT_LIST_REGIONAL,
};
