import React from 'react';

import Drawer from 'components/Drawer';
import Brand from 'components/_base/Brand';
import LoggedUser from 'components/LoggedUser';
import Notifications from 'components/Notifications';

import './styles.scss';

const HeaderApp = () => {
  return (
    <header className="header-app">
      <Drawer />
      <div className="brand-app">
        <div>
          <a href="/">
            <Brand white />
          </a>
        </div>
      </div>
      <LoggedUser />
      <Notifications />
    </header>
  );
};

export default HeaderApp;
