import React from 'react';

import {
  AcumuladoCanal,
  CanalSolicitacoes,
  EvolucaoSolicitacoes,
  EvolucaoSolicitacoesAtacado,
  EvolucaoSolicitacoesVarejo,
  MapChart,
  MarcaSolicitacoes,
  RankGenerico,
  RedeSolicitacoes,
  RegionalSolicitacoes,
} from '../Graficos/produtividade';

const TabProdutividade = React.memo(
  (props) => {
    const { data } = props;
    return (
      <div className="container-tab">
        <div className="row-graficos">
          <EvolucaoSolicitacoes data={data?.evolucao} />
        </div>
        <div className="row-graficos">
          <MapChart data={data?.uf} />
          <div className="column-graficos">
            <RegionalSolicitacoes data={data?.regional} />
            <RedeSolicitacoes data={data?.rede} />
          </div>
        </div>
        <div className="row-graficos">
          <MarcaSolicitacoes data={data?.marca} />
          <AcumuladoCanal data={data?.canal} />
        </div>
        <div className="row-graficos">
          <CanalSolicitacoes data={data?.canal} />
        </div>
        <div className="row-graficos">
          <EvolucaoSolicitacoesAtacado data={data?.canal} />
        </div>
        <div className="row-graficos">
          <EvolucaoSolicitacoesVarejo data={data?.canal} />
        </div>
        <div className="row-graficos">
          <RankGenerico
            titulo="TOP 10 Solicitantes GERAL"
            data={data?.ranking?.solicitante}
          />
          <RankGenerico
            titulo="TOP 10 Solicitantes FOOD&VAREJO"
            data={data?.ranking?.solicitanteVarejo}
          />
          <RankGenerico
            titulo="TOP 10 Solicitantes ATACADO"
            data={data?.ranking?.solicitanteAtacado}
          />
        </div>
        <div className="row-graficos">
          {/* <RankGenerico
            titulo="TOP 10 solicitações por rede"
            data={data?.ranking?.rede}
          /> */}
          {/* <RankGenerico
          titulo="Rank solicitações por campanha"
          data={data?.ranking?.campanha}
        /> */}
          <RankGenerico
            titulo="Rank solicitações por produto"
            data={data?.ranking?.produto}
          />
        </div>
        <div className="row-graficos">
          <RankGenerico
            titulo="Rank solicitações por categoria"
            data={data?.ranking?.categoria}
          />
          <RankGenerico
            titulo="Rank solicitações por material"
            data={data?.ranking?.material}
          />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default TabProdutividade;
