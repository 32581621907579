import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';
import { months } from '../../data/sampleData';

const EvolucaoCanal = (props) => {
  const { data } = props;

  const titulo = 'Evolução quantidade de impressos por canal';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const canais = Object.keys(data);
  const anos = Object.keys(data[canais[0]]);
  const meses = [];
  const values = {};

  const calculateSeries = () => {
    const tmpSeries = [];
    if (anos.length === 0) {
      return [];
    }
    anos.forEach((ano) => {
      months.forEach((mes, i) => {
        canais.forEach((canal) => {
          values[canal] = [...(values[canal] || []), data[canal][ano][i] || 0];
        });
        meses.push(`${mes.slice(0, 3)}.-${ano.slice(2, 4)}`);
      });
    });
    canais.forEach((canal) => {
      tmpSeries.push({
        name: canal,
        data: values[canal],
      });
    });
    return tmpSeries;
  };

  const series = calculateSeries();

  const options = {
    legend: {
      formatter: (seriesName) => {
        return [seriesName.toUpperCase()];
      },
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'left',
    },
    xaxis: {
      categories: [...meses],
      tickPlacement: 'between',
    },
    yaxis: {
      show: false,
      showForNullSeries: true,
    },
    grid: {
      show: false,
    },
    chart: {
      id: 'evo-impressos',
      parentHeightOffset: 0,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
    },
    stroke: {
      width: [3, 3],
      show: true,
      curve: 'straight',
      dashArray: 0,
    },
    markers: {
      size: 8,
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      formatter: (value) => {
        if (value === null) return '';
        return `${parseInt(value, 10).toLocaleString('pt-BR')}`;
      },
      style: {
        fontSize: '0.8rem',
        colors: ['#021f59', '#00A000'],
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        opacity: 0.8,
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: true,
      fillSeriesColor: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `<div class="arrow_box">
            <span>
              Canal: <span id="data">${canais[seriesIndex]}</span>
            </span>
            <span>
              Mês de Data Criação: <span id="data">${
                months[dataPointIndex % 12]
              } de ${anos[seriesIndex]}</span>
            </span>
            <span>
              Quantidade Impressos: <span id="data">${parseInt(
                series[seriesIndex][dataPointIndex],
                10
              ).toLocaleString('pt-BR')}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="line" height="250" />
    </div>
  );
};

export default EvolucaoCanal;
