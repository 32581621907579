import React from 'react';
import './styles.scss';

const Cards = React.memo(
  (props) => {
    const { data } = props;
    if (!data) return null;
    return (
      <div className="card-wrapper">
        <div className="card">
          <p className="card-title">Total de solicitações</p>
          <p className="card-data">
            {parseInt(data?.solicitacoes, 10).toLocaleString('pt-BR')}
          </p>
        </div>
        <div className="card">
          <p className="card-title">Solicitações aprovadas</p>
          <p className="card-data">
            {parseInt(data?.solicitacoesAprovadas, 10).toLocaleString('pt-BR')}
          </p>
        </div>
        <div className="card">
          <p className="card-title">Total arte aprovada</p>
          <p className="card-data">
            {parseInt(data?.artesAprovadas, 10).toLocaleString('pt-BR')}
          </p>
        </div>
        <div className="card">
          <p className="card-title">Qtd Impressos</p>
          <p className="card-data">
            {parseInt(data?.qtdeImpressoes, 10).toLocaleString('pt-BR')}
          </p>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default Cards;
