import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentChamadoChat from './ContentChamadoChat';

const ChatCalled = () => {
  return (
    <>
      <Header />
      <ContentChamadoChat />
      <Bottom />
    </>
  );
};

export default ChatCalled;
