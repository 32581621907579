/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

import imageErro from 'assets/imgs/erro.png';

const ContentErroServidor = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: 'calc(100vh - 140px)', margin: '15px' }}
    >
      <div style={{ textAlign: 'center' }}>
        <img
          src={imageErro}
          alt="erro"
          style={{ marginBottom: '20px', maxWith: '340px' }}
        />
        <h1>Ocorreu um erro</h1>
        <h5>
          Ocorreu um erro ao tentar processar a requisição. <br />
          Por favor, tente novamente
        </h5>
        <Link to="/">
          <button className="mt-3 btn btn-primary btn-navigation">
            <span>
              <FaHome size={26} />
              <span className="ml-2">PÁGINA INICIAL</span>
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ContentErroServidor;
