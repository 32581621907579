/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { tabs, allTabs, httpStatusCode } from './_constants';
import { ADD_PEDIDO } from 'services/Pedido';
import {
  GET_PEDIDO_RASCUNHO,
  UPDATE_RASCUNHO,
  ADD_RASCUNHO,
} from 'services/Rascunho';
import { GET_USUARIO } from 'services/Usuario';

import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import Cliente from './Cliente';
import Layout from './Layout';
import Tamanho from './Tamanho';
import DadosAcao from './DadosAcao';
import Resumo from './Resumo';
import Sucesso from './Sucesso';

import './styles.scss';

const ContentArtesFacil = (props) => {
  const params = useParams();
  const history = useHistory();
  const { showLoader } = useContext(Context);

  let baseUrl = props.baseUrl;

  const [model, setModel] = useState({
    pedidoTipoId: 3,
    pedidoDeCampanha: false,
    criadoPeloApp: false,
  });
  const [activeTab, setActiveTab] = useState({
    nome: '',
    url: '',
    icone: '',
    texto: '',
  });
  const [initialModel, setInitialModel] = useState(true);

  const updateModel = () => {
    setModel({ ...model });
  };

  const updateModelReativo = (value) => {
    let valorAtualizacaoReativa = value;
    setModel({
      ...model,
      ...valorAtualizacaoReativa,
    });
  };

  const changeTab = (tab) => {
    updateModel();
    history.push(`${baseUrl}/${model.rascunhoId}/${tab}`);
  };

  const onlyChangeTab = (tab) => {
    history.push(`${baseUrl}/${model.rascunhoId}/${tab}`);
  };

  const updateRascunho = () => {
    (async () => {
      await UPDATE_RASCUNHO(model);
    })();
  };

  useEffect(() => {
    // Atualizar rascunho quando a model for atualizada
    (async () => {
      if (!model.rascunhoId) return;
      if (initialModel) return;
      await UPDATE_RASCUNHO(model);
    })();
  }, [model]);
  // Redireciona para a tab de acordo com a mudança na url
  useEffect(() => {
    return history.listen((location) => {
      let tab = location.pathname.split('/')[3];
      // setActiveTab(tab);
      let tabAtiva = allTabs.filter((value) => {
        return value.url === tab;
      });
      setActiveTab(
        tabAtiva[0] || allTabs.find((element) => element.url === 'cliente')
      );
    });
  }, [history]);

  // useEffect "DidMount", primeiro carregamento da pagina
  useEffect(() => {
    (async () => {
      showLoader(true);
      /*
        verifica o paramentro pedidoId da url
        - se nao existir é um novo pedido e tem que ser criado o rascunho, assim que criado
          redireciona para a url contendo o parametro pedidoId
        - se existir, busca o rascunho e seta o model de acordo com ele
      */
      if (!params.pedidoId) {
        // Criar o rascunho caso não tenha o id na url
        await criarRascunho();
      } else {
        // Adicionar os dados do pedidoId no state Modal
        await dadosEdicaoRascunho();
      }
      setInitialModel(false);

      let tabAtiva = allTabs.filter((value) => {
        return value.url === params.tabPedido;
      });
      setActiveTab(
        tabAtiva[0] || allTabs.find((element) => element.url === 'cliente')
      );
      showLoader(false);
    })();
  }, []);

  const criarRascunho = async () => {
    const rascunho = await ADD_RASCUNHO();
    if (rascunho.value) {
      let dadosUsuario = await GET_USUARIO(rascunho.value.usuarioId);
      setModel({
        ...model,
        rascunhoId: rascunho.value.rascunhoId,
        dataCriacao: rascunho.value.dataCriacao,
        ativo: rascunho.value.ativo,
        usuarioId: rascunho.value.usuarioId,
        solicitante: dadosUsuario.value,
      });

      history.push(
        `${baseUrl}/${rascunho.value.rascunhoId}/${tabs.tabCliente}`
      );
      return rascunho;
    }
  };

  const dadosEdicaoRascunho = async () => {
    const dadosEdicao = await GET_PEDIDO_RASCUNHO(params.pedidoId);
    if (dadosEdicao.value) {
      setModel({
        ...dadosEdicao.value,
        rascunhoId: params.pedidoId,
      });
      return dadosEdicao.value;
    } else {
      await criarRascunho();
    }
  };

  const enviarSolicitacao = async () => {
    model.errors = [];

    showLoader(true);
    let pedidoModel = {
      pedido: model,
      rascunho: { rascunhoId: parseInt(model.rascunhoId) },
    };
    const data = await ADD_PEDIDO(model.rascunhoId, pedidoModel);
    if (data?.code) {
      let code = data.code;
      // pedido criado
      if (code == httpStatusCode.ok) {
        setModel(data.value.pedido);
        onlyChangeTab(tabs.tabSucesso);
      }
      else if (data.value.errors?.length > 0){
        model.errors = data.value.errors;
        updateModel();
        onlyChangeTab(tabs.tabDadosAcao);
      }
    }
    showLoader(false);
  };

  let renderTabs = () => {
    if (activeTab.url === 'cliente') {
      return (
        <Cliente
          baseUrl={baseUrl}
          updateRascunho={updateRascunho}
          model={model}
          setModel={updateModel}
          setActiveTab={changeTab}
        />
      );
    }
    if (activeTab.url === 'layout') {
      return (
        <Layout
          baseUrl={baseUrl}
          updateRascunho={updateRascunho}
          model={model}
          setModel={updateModelReativo}
          setActiveTab={onlyChangeTab}
        />
      );
    }
    if (activeTab.url === 'tamanho') {
      return (
        <Tamanho
          baseUrl={baseUrl}
          model={model}
          setModel={updateModelReativo}
          setActiveTab={onlyChangeTab}
        />
      );
    }
    if (activeTab.url === tabs.tabDadosAcao) {
      return (
        <DadosAcao
          baseUrl={baseUrl}
          model={model}
          setModel={updateModelReativo}
          setActiveTab={onlyChangeTab}
        />
      );
    }
    if (activeTab.url === tabs.tabResumo) {
      return (
        <Resumo
          baseUrl={baseUrl}
          updateRascunho={updateRascunho}
          model={model}
          setModel={updateModel}
          setActiveTab={onlyChangeTab}
          enviarSolicitacao={enviarSolicitacao}
        />
      );
    }
    if (activeTab.url === tabs.tabSucesso) {
      return (
        <Sucesso
          baseUrl={baseUrl}
          updateRascunho={updateRascunho}
          model={model}
          setModel={updateModel}
          setActiveTab={onlyChangeTab}
        />
      );
    }

    return null;
  };

  return (
    <div className="wrapper-content-pedido">
      {/* <TitlePages title={activeTab.texto} /> */}
      {renderTabs()}
    </div>
  );
};

export default ContentArtesFacil;
