import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';

import ContentArtesFacil from './ContentArtesFacil';

const ArtesFacil = () => {
  return (
    <>
      <HeaderApp />
      <ContentArtesFacil baseUrl="/artes-facil" />
      <Bottom />
    </>
  );
};

export default ArtesFacil;
