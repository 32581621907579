/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import Select, { createFilter } from 'react-select';
import { optimizeSelect } from 'components/_base/OptimizeSelect';
import { FaTimes } from 'react-icons/fa';
import { GET_ENDERECO_POR_CEP } from 'services/Solicitacao';

import InputCEP from 'components/_base/InputCEP';

import { getCampoObrigatorioClassName } from '../../../../../_constants';

import { Context } from 'context/AuthContext';

const RodapeEndereco = (props) => {
  const { showLoader } = useContext(Context);

  let entities = props.entities;
  let solicitacaoRodape = props.solicitacaoRodape;
  let updateSolicitacaoRodapes = props.updateSolicitacaoRodapes;
  let removerSolicitacaoRodape = props.removerSolicitacaoRodape;

  let estados = entities.estados || [];
  let cidades = [].concat(...estados.map((estado) => estado.cidades));

  const [selectListEstados, setSelectListEstados] = useState([]);
  useEffect(() => {
    if (selectListEstados.length)
      return;

    let lista = entities.estados.map((estado) => {
      return {
        value: estado.uf,
        label: estado.nome,
      };
    })
    setSelectListEstados(lista);
  }, [entities])

  const [selectListCidades, setSelectListCidades] = useState({});
  useEffect(() => {
    if (selectListCidades.uf && selectListCidades.uf === solicitacaoRodape.uf)
      return;

    let lista = (entities.estados.find((x) => x.uf === solicitacaoRodape.uf)?.cidades || []).map((cidade) => {
      return {
        value: cidade.cidadeId,
        label: cidade.nome,
      };
    }) || [];

    if (lista.length === 0)
      return;

    setSelectListCidades({
      uf: solicitacaoRodape.uf,
      options: lista
    });
  }, [entities, solicitacaoRodape?.uf, selectListEstados])

  const [selectedCidade, setSelectedCidade] = useState({});
  useEffect(() => {
    if (selectedCidade?.value && selectedCidade?.value === solicitacaoRodape.cidadeId)
      return;

    setSelectedCidade(
      (selectListCidades.options || []).find(
        (item) => item.value === solicitacaoRodape.cidadeId
      ) || null
    );
  }, [solicitacaoRodape?.cidadeId, selectListCidades]);

  const buscaCep = () => {
    (async () => {
      showLoader(true);

      const result = await GET_ENDERECO_POR_CEP(solicitacaoRodape.cep);

      showLoader(false);

      if (!result?.value)
        return;

      let endereco = result.value;

      solicitacaoRodape.cidadeId = endereco.cidadeId,
      solicitacaoRodape.cidade = endereco.cidade,
      solicitacaoRodape.uf = endereco.cidade.uf,
      solicitacaoRodape.logradouro = endereco.logradouro,
      solicitacaoRodape.bairro = endereco.bairro

      updateSolicitacaoRodapes();
    })();
  };

  return (
    <div className="col-lg-12 mb-4">
      <div className="row solicitacao-rodape">
        <div className="col-lg-12 text-right">
          <FaTimes onClick={() => removerSolicitacaoRodape()} />
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>Descrição Loja</label>
              <input
                type="text"
                className={`form-control ${getCampoObrigatorioClassName(
                  solicitacaoRodape.descricaoLoja,
                  true
                )}`}
                value={solicitacaoRodape.descricaoLoja || ''}
                onChange={(event) => {
                  solicitacaoRodape.descricaoLoja = event.target.value;
                  updateSolicitacaoRodapes();
                }}
              />
            </div>

            <div className="col-lg-12 mb-4">
              <label>CEP</label>
              <InputCEP
                className={getCampoObrigatorioClassName(
                  solicitacaoRodape.cep,
                  true
                )}
                value={solicitacaoRodape.cep || ''}
                onChange={(value) => {
                  solicitacaoRodape.cep = value;
                  updateSolicitacaoRodapes();
                }}
                onChange={(value) => {
                  solicitacaoRodape.cep = value;
                  if (solicitacaoRodape.cep.length === 9)
                    buscaCep();

                  updateSolicitacaoRodapes();
                }}
                onKeyDown={(e) => e.key === 'Enter' && buscaCep()}
              />
            </div>

            <div className="col-lg-12 mb-4">
              <label>Endereço</label>
              <input
                type="text"
                className={`form-control ${getCampoObrigatorioClassName(
                  solicitacaoRodape.endereco,
                  true
                )}`}
                value={solicitacaoRodape.endereco || ''}
                onChange={(event) => {
                  solicitacaoRodape.endereco = event.target.value;
                  updateSolicitacaoRodapes();
                }}
              />
            </div>

            <div className="col-lg-12 mb-4">
              <label>Número</label>
              <input
                type="text"
                className={`form-control ${getCampoObrigatorioClassName(
                  solicitacaoRodape.numero,
                  true
                )}`}
                value={solicitacaoRodape.numero || ''}
                onChange={(event) => {
                  solicitacaoRodape.numero = event.target.value;
                  updateSolicitacaoRodapes();
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>Bairro</label>
              <input
                type="text"
                className={`form-control ${getCampoObrigatorioClassName(
                  solicitacaoRodape.bairro,
                  true
                )}`}
                value={solicitacaoRodape.bairro || ''}
                onChange={(event) => {
                  solicitacaoRodape.bairro = event.target.value;
                  updateSolicitacaoRodapes();
                }}
              />
            </div>

            <div className="col-lg-12 mb-4">
              <label>Estado</label>
              <Select
                  className={`${getCampoObrigatorioClassName(
                    solicitacaoRodape.uf,
                    true
                  )}`}
                  options={selectListEstados}
                  isClearable={true}
                  onChange={(item) => {
                    solicitacaoRodape.uf = item?.value;
                    updateSolicitacaoRodapes();
                  }}
                  value={selectListEstados.find(
                    (item) => item.value === solicitacaoRodape?.uf
                  )}
                />
            </div>

            <div className="col-lg-12 mb-4">
              <label>Cidade</label>
              <Select
                  className={`${getCampoObrigatorioClassName(
                    solicitacaoRodape.cidadeId,
                    true
                  )}`}
                  components={optimizeSelect.components}
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={selectListCidades.options || []}
                  isClearable={true}
                  onChange={(item) => {
                    let cidadeId = item?.value ?? 0;
                    if (solicitacaoRodape.cidadeId !== cidadeId) {
                      solicitacaoRodape.cidadeId = cidadeId;
                      updateSolicitacaoRodapes();
                    }
                  }}
                  value={selectedCidade}
                  isDisabled={!solicitacaoRodape?.uf}
                />
            </div>

            <div className="col-lg-12 mb-4">
              <label>Complemento</label>
              <input
                type="text"
                className="form-control"
                value={solicitacaoRodape.complemento || ''}
                onChange={(event) => {
                  solicitacaoRodape.complemento = event.target.value;
                  updateSolicitacaoRodapes();
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label>Horário de Funcionamento</label>
              <textarea className="form-control" rows="5"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RodapeEndereco;
