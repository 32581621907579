/* eslint-disable */
import React, { useRef, useEffect } from "react";

const {tableau} = window;

const Tableau = ({url}) => {
  const refContainer = useRef(null);
  const refViz = useRef(null);
  const refWorkbook = useRef(null);
  const refActiveSheet = useRef(null);

  useEffect(() => {
    if (refContainer.current !== null)
      initializeViz();
  }, [url]);

  function initializeViz() {
    let placeholderDiv = refContainer.current;
    console.log(url);
    //let url = "https://public.tableau.com/views/LearnEmbeddedAnalytics/SalesOverviewDashboard";

    var options = {
      width: placeholderDiv.offsetWidth,
      height: placeholderDiv.offsetHeight,
      //hideTabs: true,
      hideToolbar: true,
      onFirstInteractive: function () {
        refWorkbook.current = refViz.current.getWorkbook();
        refActiveSheet.current = refWorkbook.current.getActiveSheet();
      },
    };

    refViz.current = new tableau.Viz(placeholderDiv, url, options);
  }

  async function exportToPDF() {
    //await refWorkbook.current.activateSheetAsync(0);

    refViz.current.showExportPDFDialog();
  }

  return (
    <>
      <div className="text-right">
        <button onClick={exportToPDF} className="btn btn-primary">Exportar PDF</button>
      </div>

      <div ref={refContainer} className="iframe-container" style={{height: 4000}}></div>
    </>
  );
}

export default Tableau;
