import '../styles.scss';
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const RedeSolicitacoes = (props) => {
  const { data } = props;

  const titulo = 'Solicitações por rede';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const [modelGrafico, setModelGrafico] = useState({
    series: [{ data: Object.keys(data).map((rede) => data[rede].quantidade) }],
    redes: Object.keys(data),
  });
  const [filterText, setFilterText] = useState('');

  // Update state when data prop changes
  useEffect(() => {
    setFilterText('');
    setModelGrafico({
      series: [
        { data: Object.keys(data).map((rede) => data[rede].quantidade) },
      ],
      redes: Object.keys(data),
    });
  }, [data]);

  const updateModelData = (nome) => {
    if (nome.length === 0) {
      setModelGrafico({
        series: [
          { data: Object.keys(data).map((rede) => data[rede].quantidade) },
        ],
        redes: Object.keys(data),
      });
      return;
    }

    if (nome.length <= 3) return;

    const redes = Object.keys(data).filter((rede) =>
      rede.toUpperCase().includes(nome.toUpperCase())
    );
    const series = [{ data: redes.map((rede) => data[rede].quantidade) }];

    setModelGrafico({
      series,
      redes,
    });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setFilterText(value);
    updateModelData(value);
  };

  const options = {
    colors: ['#021f59'],
    plotOptions: {
      bar: {
        barHeight: '80%',
        horizontal: true,
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: modelGrafico.redes.map((rede) => rede.toUpperCase()),
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max:
        Math.max(...modelGrafico.redes.map((rede) => data[rede].quantidade)) *
        1.25,
      labels: {
        align: 'right',
        maxWidth: 120, // alterar para mudar o tamanho do espaco para as categorias
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    grid: {
      padding: {
        left: 20,
      },
      row: {
        colors: ['#dcdcdc', 'transparent'],
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      parentHeightOffset: 0,
      width: '100%',
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'start',
      offsetX: 5,
      offsetY: 6,
      formatter: (value, { dataPointIndex }) => {
        return `${value}(${
          data[modelGrafico.redes[dataPointIndex]].percentual
        }%)`;
      },
      style: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Rede: <span id="data">${modelGrafico.redes[
                dataPointIndex
              ].toUpperCase()}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${
                series[seriesIndex][dataPointIndex]
              }</span>
            </span>
            <span>
              % Solicitações Aprovadas: <span id="data">${
                data[modelGrafico.redes[dataPointIndex]].percentual
              }</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder="Filtrar redes"
          value={filterText}
          onChange={handleInputChange}
        />
      </div>
      <div className="scrollable" id="grafico-rede-solicitacao">
        <Chart
          options={options}
          series={modelGrafico.series}
          type="bar"
          height={modelGrafico.redes.length * 17 + 100}
        />
      </div>
    </div>
  );
};

export default RedeSolicitacoes;
