import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { appConst } from 'constants/appConstants';
import { COUNT_PENDENCIA_USUARIO_ATUAL } from 'services/Pendencia';

import './styles.scss';

const Notifications = () => {
  const [pendencias, setPendencias] = useState([]);
  const userAuth = JSON.parse(localStorage.getItem(appConst.STORAGE_NAME));
  useEffect(() => {
    (async () => {
      const data = await COUNT_PENDENCIA_USUARIO_ATUAL();
      setPendencias(data.value);
    })();
  }, []);

  return (
    <>
      <div className="notifications usuario">
        <span>{userAuth && userAuth.nome}</span>
      </div>
      <a href="/pendencias" className="notifications cursor-pointer">
        <FontAwesomeIcon icon={faBell} size="2x" />
        {pendencias > 0 ? <span id="pendencias">{pendencias}</span> : ''}
      </a>
    </>
  );
};

export default Notifications;
