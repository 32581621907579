/* eslint-disable */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import history from 'routes/history';
import actionLoader from 'store/modules/loader/actions';
import { GET_ADMIN_LOGIN } from 'services/Admin';
import { LOGIN, LOGOUT, LOGIN_WITH_TOKEN } from 'services/Login';
import { appConst } from 'constants/appConstants';

const useAuth = () => {
  const dispatch = useDispatch();

  const [loaderShowing, setShowLoader] = useState(false);
  const [userData, setUserData] = useState({
    atualizarDados: false,
    jwtToken: null,
    userId: null,
    authenticated: false,
    nome: null,
    regionalId: null,
  });

  useEffect(() => {
    async function autoLogin() {
      const loginUserData = JSON.parse(localStorage.getItem(appConst.STORAGE_NAME));
      setUserData(loginUserData);
    }
    autoLogin();
  }, []);

  async function handleLogin({ username, password, redirect }) {
    showLoader(true);

    const {
      value: { authenticated, nome, userId, regionalId, atualizarDados },
      jwtToken,
    } = await LOGIN({ username, password });

    localStorage.setItem(
      appConst.STORAGE_NAME,
      JSON.stringify({
        jwtToken,
        userId,
        authenticated,
        nome,
        regionalId,
        atualizarDados,
      })
    );
    localStorage.setItem('token', jwtToken);
    setUserData({
      jwtToken,
      userId,
      authenticated,
      nome,
      regionalId,
      atualizarDados,
    });
    showLoader(false);
    if (authenticated === true) {
      history.push(redirect);
    }
    return authenticated;
  }

  async function handleLogout() {
    showLoader(true);
    await LOGOUT();
    localStorage.removeItem(appConst.STORAGE_NAME);
    setUserData({
      atualizarDados: false,
      jwtToken: null,
      userId: null,
      authenticated: false,
      nome: null,
      regionalId: null,
    });
    history.replace('/login');
    showLoader(false);
  }

  async function adminLoginAs(usuarioId, redirect) {
    showLoader(true);

    let response = await GET_ADMIN_LOGIN(usuarioId);

    const {
        value: { authenticated, nome, userId, regionalId, atualizarDados },
        jwtToken,
      } = response;

    setLocalStorage({
      jwtToken,
      userId,
      authenticated,
      nome,
      regionalId,
      atualizarDados,
    });

    showLoader(false);
    history.push(redirect || '/');
  }

  async function loginWithToken(token) {
    showLoader(true);
    const {
      value: {
        authenticated,
        nome,
        userId,
        regionalId,
        atualizarDados,
        urlRedirect,
        error,
      },
      jwtToken,
    } = await LOGIN_WITH_TOKEN({ token: token });

    if (error?.length) {
      history.push('/');
      return;
    }

    setLocalStorage({
      jwtToken,
      userId,
      authenticated,
      nome,
      regionalId,
      atualizarDados,
    });

    history.push(urlRedirect || '/');
    showLoader(false);
  }

  function setLocalStorage(user) {
    localStorage.setItem(appConst.STORAGE_NAME, JSON.stringify(user));
    localStorage.setItem('token', user.jwtToken);
    setUserData(user);
  }

  async function showLoader(value) {
    dispatch(actionLoader.showLoader(value));
    setShowLoader(value);
  }

  return {
    loaderShowing,
    showLoader,
    handleLogin,
    handleLogout,
    userData,
    setUserData,
    adminLoginAs,
    loginWithToken,
  };
};

export default useAuth;
