import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';
import { months } from '../../data/sampleData';

const EvolucaoTotal = (props) => {
  const { data } = props;

  const titulo = 'Evolução do Total do Valor Gasto';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const years = Object.keys(data);

  const series = years.map(
    (year) => {
      return {
        name: year,
        data: Object.values(data[year]).map((value) => value || 0), // para os valores null, o grafico nao plota, para os valores 0, o grafico plota
      };
    },
    [data]
  );

  const options = {
    title: {},
    legend: {
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'left',
      floating: false,
      offsetX: -25,
      offsetY: 7,
    },
    xaxis: {
      categories: months,
      tickPlacement: 'between',
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
      showForNullSeries: true,
    },
    grid: {
      show: false,
    },
    chart: {
      id: 'evo-solicitacoes',
      parentHeightOffset: 0,
    },
    stroke: {
      width: [3, 3],
      show: true,
      curve: 'straight',
      dashArray: 0,
    },
    markers: {
      size: 8,
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      formatter: (value) => {
        if (value === null) return '';
        return `R$ ${parseInt(value, 10).toLocaleString('pt-BR')}`;
      },
      style: {
        fontSize: '0.8rem',
        colors: ['#021f59', '#00A000'],
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        opacity: 0.8,
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: true,
      // fillSeriesColor: true,
      fillSeriesColor: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `<div class="arrow_box">
            <span>
              Mês de Data Criação: <span id="data">${
                months[dataPointIndex]
              }</span>
            </span>
            <span>
              Ano de Data Criação: <span id="data">${years[seriesIndex]}</span>
            </span>
            <span>
              Valor total: <span id="data">R$ ${parseInt(
                series[seriesIndex][dataPointIndex],
                10
              ).toLocaleString('pt-BR')}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      {/* <div className="grafico"> */}
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="line" height="250" />
    </div>
  );
};

export default EvolucaoTotal;
