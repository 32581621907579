import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import lineBreaker from 'helpers/LineBreaker';
import SemDados from '../SemDados';

const RegiaoTotal = (props) => {
  const { data } = props;
  const titulo = 'Total gasto por regional';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const regioes = Object.keys(data);

  const series = [{ data: regioes.map((regiao) => data[regiao]) }];

  const options = {
    colors: ['#021f59'],
    plotOptions: {
      bar: {
        barHeight: '20px',
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: true,
          orientation: 'vertical',
        },
      },
    },
    xaxis: {
      labels: {
        formatter: (value) => {
          const lines = lineBreaker(value.toUpperCase(), 16);
          return lines;
        },
        show: true,
        rotate: -90,
        rotateAlways: false, // mudar para true se quiser que todas as labels sejam rotacionadas
      },
      categories: regioes,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      max: Math.max(...regioes.map((regiao) => data[regiao])) * 1.6,
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        // left: 0,
        // right: 0,
        top: -30,
        // bottom: 0,
      },
    },
    chart: {
      height: 'auto',
      id: 'reg-solicitacoes',
      parentHeightOffset: 0,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: 10,
      formatter: (value) => {
        return `R$ ${parseInt(value, 10).toLocaleString('pt-BR')}`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 550,
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              UF: <span id="data">${regioes[
                dataPointIndex
              ].toUpperCase()}</span>
            </span>
            <span>
              Valor Total: <span id="data">R$ ${parseInt(
                series[seriesIndex][dataPointIndex],
                10
              ).toLocaleString('pt-BR')}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    // <div className="grafico">
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="bar" height="350" />
    </div>
  );
};

export default RegiaoTotal;
