import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import { relatoriosColors } from 'constants/appConstants';
import SemDados from '../SemDados';
import { months } from '../../data/sampleData';

const EvolucaoSolicitacoes = (props) => {
  const { data } = props;

  const titulo = 'Evolução de Solicitações';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const years = Object.keys(data);

  const series = years.map(
    (year) => {
      return {
        name: year,
        data: Object.values(data[year]).map((value) => value || 0),
      };
    },
    [data]
  );
  const options = {
    colors: relatoriosColors.colors,
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'left',
    },
    xaxis: {
      categories: months,
      tickPlacement: 'between',
    },
    yaxis: {
      show: false,
      showForNullSeries: true,
    },
    grid: {
      show: false,
    },
    chart: {
      id: 'evo-solicitacoes',
      parentHeightOffset: 0,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: [3, 3],
      show: true,
      curve: 'straight',
      dashArray: 0,
    },
    markers: {
      size: 8,
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        fontSize: '0.8rem',
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        opacity: 0.8,
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: true,
      fillSeriesColor: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `<div class="arrow_box">
            <span>
              Mês de Data Criação: <span id="data">${months[dataPointIndex]}</span>
            </span>
            <span>
              Ano de Data Criação: <span id="data">${years[seriesIndex]}</span>
            </span>
            <span>
              Contagem distinta de Solicitação id: <span id="data">${series[seriesIndex][dataPointIndex]}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="line" height="250" />
    </div>
  );
};

export default EvolucaoSolicitacoes;
