/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faThumbsDown,
  faDollarSign,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

import api from 'api';
import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import ModalSummary from 'components/ModalSummary';
import ModalProdutos from './ModalProdutos';
import SolicitacaoArquivos from 'pages/SolicitacaoFluxo/Status/ContentSolicitacaoFluxoStatus/SolicitacaoArquivos';
import Historico from 'pages/SolicitacaoFluxo/Status/ContentSolicitacaoFluxoStatus/Historico';

import {
  GET_APROVAR_ARTE_MODEL,
  APROVAR_ARTE,
  REPROVAR_ARTE,
} from 'services/SolicitacaoFluxo';
import './styles.scss';

const ContentSolicitacaoFluxoAprovarArte = () => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0
  );
  const [model, setModel] = useState({});
  const [showComment, setShowComment] = useState(false);
  const [showEnviarAprovacao, setShowEnviarAprovacao] = useState(false);
  const [showModalProdutos, setShowModalProdutos] = useState(false);
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const responseModel = await GET_APROVAR_ARTE_MODEL(solicitacaoId);

      setModel(responseModel.value);

      showLoader(false);
    })();
  }, []);

  function renderResumoButton() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div className="text-left">
        <button
          className="btn btn-primary"
          onClick={() => {
            setResumoSolicitacaoId(solicitacaoId);
          }}
        >
          Ver Solicitação
        </button>
      </div>
    );
  }

  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function renderButtons() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <button className="m-2 btn btn-success" onClick={aprovar}>
          <FontAwesomeIcon icon={faThumbsUp} /> Aprovar
        </button>
        <button className="m-2 btn btn-danger" onClick={reprovar}>
          <FontAwesomeIcon icon={faThumbsDown} /> Reprovar
        </button>
      </>
    );
  }

  function aprovar() {
    setShowEnviarAprovacao(true);
    setShowComment(false);
  }

  function enviarAprovacao() {
    showLoader(true);

    (async () => {
      const response = await APROVAR_ARTE(model);

      if (response.value) {
        setModel({});
      }
      setResultModel(response);
      showLoader(false);
    })();
  }

  function reprovar() {
    setShowComment(true);
    setShowEnviarAprovacao(false);
  }

  function enviarReprovacao() {
    showLoader(true);

    (async () => {
      const response = await REPROVAR_ARTE(model);

      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }

      setResultModel(response);

      showLoader(false);
    })();
  }

  function renderArquivos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return <SolicitacaoArquivos solicitacaoId={model.solicitacaoId} />;
  }

  function renderEnviarAprovacao() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!showEnviarAprovacao) return null;

    return (
      <>
        <hr />
        <div className="row text-center">
          <div className="col-lg-12">
            <h4>
              ATENÇÃO: Confira as descrições, os preços e unidades de vendas de
              TODOS OS PRODUTOS, para ter certeza que as informações estão de
              acordo. Aprovando o layout, você está concordando com TODAS as
              informações constantes na arte.
              <br />
              <br />
              Caso tenha alguma correção a ser feita, clique em REPROVAR
            </h4>
          </div>
          <div className="col-lg-12 mt-2">
            <button className="btn btn-primary" onClick={enviarAprovacao}>
              <FontAwesomeIcon icon={faPaperPlane} /> Enviar
            </button>
          </div>
        </div>
      </>
    );
  }

  function renderComment() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!showComment) return null;

    return (
      <>
        <hr />
        <div className="row">
          <div className="col-lg-9">
            <textarea
              rows="7"
              className="form-control"
              value={model.observacao}
              onChange={(e) =>
                setModel({ ...model, observacao: e.target.value })
              }
            ></textarea>
          </div>
          <div className="col-lg-3">
            <button
              className="btn btn-primary w-100 mb-3"
              onClick={() => {
                setShowModalProdutos(true);
              }}
            >
              <FontAwesomeIcon icon={faDollarSign} /> Alterar Preços
            </button>
            <button
              className="btn btn-primary w-100"
              onClick={enviarReprovacao}
            >
              <FontAwesomeIcon icon={faPaperPlane} /> Enviar
            </button>
          </div>
        </div>
      </>
    );
  }

  function renderModalProdutos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!showModalProdutos) return null;

    return (
      <ModalProdutos
        produtos={model.solicitacaoProdutos}
        show={showModalProdutos}
        updateProdutos={(produtos) => {
          model.solicitacaoProdutos = produtos;
        }}
        closeModal={() => setShowModalProdutos(false)}
      />
    );
  }

  function renderProdutosAlterados() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!model.solicitacaoProdutos || model.solicitacaoProdutos.length === 0)
      return null;

    let produtosAlterados = model.solicitacaoProdutos.filter(
      (produto) =>
        produto.novoPreco &&
        produto.novoPreco.length > 0 &&
        parseFloat(produto.novoPreco.replace(',', '.'))
    );
    if (produtosAlterados.length === 0) return null;

    return (
      <>
        <hr />
        <div className="text-left">
          <table className="table-precos-alterados">
            <thead>
              <tr>
                <th>Produto</th>
                <th>Novo Preço</th>
              </tr>
            </thead>
            <tbody>
              {produtosAlterados.map((produto, index) => {
                return (
                  <tr key={index}>
                    <td>{produto.nome}</td>
                    <td>R$ {produto.novoPreco}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-aprovar-arte">
      <TitlePages
        title={
          'Solicitação ' +
          solicitacaoId +
          (model && model.solicitacaoId && model.solicitacaoId > 0
            ? ' - Arte aguardando aprovação.'
            : '')
        }
      />

      <div className="content-solicitacaofluxo text-center">
        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderArquivos()}

        {renderButtons()}

        {renderEnviarAprovacao()}

        {renderProdutosAlterados()}

        {renderComment()}

        {renderHistory()}

        {renderModalProdutos()}

        {renderModalSummary()}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoAprovarArte;
