import React from 'react';
import '../../styles.scss';

import {
  AcumuladoCanal,
  AnoSolicitacoes,
  CanalSolicitacoes,
  ClienteSolicitacao,
  EvolucaoCanal,
  EvolucaoCusto,
  EvolucaoMarca,
  EvolucaoSolicitacoes,
  EvolucaoSolicitacoesAtacado,
  EvolucaoSolicitacoesVarejo,
  MarcaSolicitacoes,
  MaterialRank,
  RegionalSolicitacao,
} from '../Graficos/fechamento';

const TabFechamento = React.memo(
  (props) => {
    const { data } = props;
    return (
      <div className="container-tab">
        <div className="row-graficos">
          <EvolucaoSolicitacoes data={data?.evolucaoSolicitacoes} />
        </div>
        <div className="row-graficos">
          <MarcaSolicitacoes data={data?.solicitacoesMarca} />
          <AcumuladoCanal data={data?.solicitacoesCanal} />
        </div>
        <div className="row-graficos">
          <CanalSolicitacoes data={data?.solicitacoesCanal} />
        </div>
        <div className="row-graficos">
          <AnoSolicitacoes data={data?.solicitacoesAno} />
          <EvolucaoSolicitacoesAtacado
            data={data?.solicitacoesCanal}
            hasAnnotation
          />
          <EvolucaoSolicitacoesVarejo
            data={data?.solicitacoesCanal}
            hasAnnotation
          />
        </div>
        <div className="row-graficos">
          <EvolucaoCanal data={data?.solicitacoesCanal} />
          <EvolucaoMarca data={data?.evolucaoMarca} />
        </div>
        <div className="row-graficos">
          <RegionalSolicitacao data={data?.solicitacoesRegional} />
          <ClienteSolicitacao data={data?.solicitacoesCliente} />
          <MaterialRank data={data?.solicitacoesMaterial} />
        </div>
        <div className="row-graficos">
          <EvolucaoCusto data={data?.evolucaoCusto} />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default TabFechamento;
