/* eslint-disable  */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import history from 'routes/history';
import { Context } from 'context/AuthContext';
import api from 'api';
import { dateFormat, getDate, JsDateToCsDateStr } from 'helpers/Date.Helper';
import stringArrayToIntArray from 'helpers/Parse.Helper';
import { GET_LIST_SOLICITACAO } from 'services/Consulta';

import ModalHistoric from 'components/ModalHistoric';
import ModalSummary from 'components/ModalSummary';
import ModalSolicitarNovamente from 'components/ModalSolicitarNovamente';
import ContentWrapper from 'components/_base/ContentWrapper';
import Filter from './Filter';
import TableList from './TableList';

import './styles.scss';

const qs = require('qs');

const ContentQuery = (props) => {
  const paramsLocation = new URLSearchParams(useLocation().search);

  const initialState = {
    solicitacaoId: paramsLocation.get('solicitacaoId') || '',
    materialComunicacaoIds: paramsLocation.get('materialComunicacaoIds')
      ? stringArrayToIntArray(
          paramsLocation.get('materialComunicacaoIds').toString().split(',')
        )
      : [],
    etapaIds: paramsLocation.get('etapaIds')
      ? stringArrayToIntArray(
          paramsLocation.get('etapaIds').toString().split(',')
        )
      : [],
    dataInicio: getDate(decodeURI(paramsLocation.get('dataInicio'))),
    dataFim: getDate(decodeURI(paramsLocation.get('dataFim'))),
    pageNumber: paramsLocation.get('pageNumber') || 1,
    pageSize: paramsLocation.get('pageSize') || 25,
    list: [],
    pageData: {},
  };

  const { showLoader } = useContext(Context);
  const [model, setModel] = useState(initialState);
  const [refresh, setRefresh] = useState([]);
  const [historicoId, setHistoricoId] = useState(0);
  const [resumoId, setResumoId] = useState(0);
  const [solicitarNovamenteId, setSolicitarNovamenteId] = useState(0);

  useEffect(() => {
    history.listen((location) => {
      if (history.action === 'POP') {
        window.location = location.pathname + location.search;
      }
    });

    (async () => {
      showLoader(true);
      let params = getFilter();
      params = {
        dataInicio: getDate(params.dataInicio),
        dataFim: getDate(params.dataFim),
        ...params,
      };

      const data = await GET_LIST_SOLICITACAO(params);

      setModel({ ...model, pageData: data.value, list: data.value.data });
      showLoader(false);
    })();
  }, [refresh]);

  const getFilter = () => {
    return {
      solicitacaoId: model.solicitacaoId,
      materialComunicacaoIds: model.materialComunicacaoIds,
      etapaIds: model.etapaIds,
      dataInicio: model.dataInicio
        ? JsDateToCsDateStr(model.dataInicio, true)
        : '',
      dataFim: model.dataFim ? JsDateToCsDateStr(model.dataFim, true) : '',
      pageNumber: model.pageNumber,
      pageSize: model.pageSize,
    };
  };

  const filterList = () => {
    var query = new URLSearchParams(getFilter()).toString();
    history.push({ pathname: '/consulta', search: '?' + query });
    setRefresh(!refresh);
  };

  const clearfilter = () => {
    history.push({ pathname: '/consulta' });
    setModel({
      solicitacaoId: '',
      materialComunicacaoIds: [],
      etapaIds: [],
      dataInicio: '',
      dataFim: '',
      pageNumber: 1,
      pageSize: 25,
      list: [],
      pageData: {},
    });
    setRefresh(!refresh);
  };

  const onClickFilter = () => {
    model.pageNumber = 1;
    filterList();
  };

  const onShowHistorico = (solicitacaoId) => {
    (async () => {
      setHistoricoId(solicitacaoId);
    })();
  };

  const onCloseHistorico = (solicitacaoId) => {
    (async () => {
      setHistoricoId(0);
    })();
  };

  const onShowResumo = (solicitacaoId) => {
    (async () => {
      setResumoId(solicitacaoId);
    })();
  };

  const onCloseResumo = (solicitacaoId) => {
    (async () => {
      setResumoId(0);
    })();
  };

  const handlePageChange = (pageNumber) => {
    model.pageNumber = pageNumber;
    filterList();
  };

  const handleSolicitarNovamente = (solicitacaoId) => {
    onCloseResumo();
    setSolicitarNovamenteId(solicitacaoId);
  };

  const closeSolicitarNovamente = () => {
    setSolicitarNovamenteId(0);
  };

  return (
    <>
      <ContentWrapper>
        <Filter
          model={model}
          filter={onClickFilter}
          clearfilter={clearfilter}
          setModel={setModel}
        />
      </ContentWrapper>
      <TableList
        model={model}
        handlePageChange={handlePageChange}
        onClickHistorico={onShowHistorico}
        onClickResumo={onShowResumo}
      />
      <ModalHistoric solicitacaoId={historicoId} onClose={onCloseHistorico} />
      <ModalSummary
        solicitacaoId={resumoId}
        onClose={onCloseResumo}
        handleSolicitarNovamente={handleSolicitarNovamente}
        displayButtonSolicitarNovamente={true}
      />
      <ModalSolicitarNovamente
        solicitacaoId={solicitarNovamenteId}
        close={closeSolicitarNovamente}
      />
    </>
  );
};

export default ContentQuery;
