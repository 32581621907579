/* eslint-disable */
import axios from 'axios';
import actionLoader from './store/modules/loader/actions';
import { useDispatch } from 'react-redux';
import { appConst } from 'constants/appConstants';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.request.use(function (config) {
  const bearerToken = localStorage.getItem('token')
    ? `Bearer ${localStorage.getItem('token')}`
    : '';

  config.headers.Authorization = bearerToken;

  return config;
});

api.interceptors.response.use(
  function (response) {
    if (response.data?.code === 500) {
      // excessão tratada no servidor
      window.location = '/erro';
      return undefined;
    }

    return response;
  },
  function (error) {
    if (401 === error.response?.status) {
      localStorage.removeItem(appConst.STORAGE_NAME);
      window.location = '/login';
    } else if (500 === error.response?.status) {
      // mostrar tela de um erro inesperado na tela
      window.location = '/erro';
    } else if (403 === error.response?.status) {
      // mostrar uma tela de voce nao tem permissao pra finalizar essa opecação
      window.location = '/acesso-restrito';
    } else {
      return Promise.reject(error);
    }
    const dispatch = useDispatch();
    dispatch(actionLoader.showLoader(false));
  }
);

export default api;
