/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import Select, { createFilter } from 'react-select';
import { optimizeSelect } from 'components/_base/OptimizeSelect';
import { GET_ENDERECO_POR_CEP } from 'services/Solicitacao';

import { getCampoObrigatorioClassName } from '../../../_constants';

import InputCEP from 'components/_base/InputCEP';
import InputTelefone from 'components/_base/InputTelefone';
import InputCpfCnpj from 'components/_base/InputCpfCnpj';

import { Context } from 'context/AuthContext';

const _EntregaOutro = (props) => {
  const { showLoader } = useContext(Context);

  let ref_entregaOutro = props.entregaOutro;
  let entities = props.entities;

  const [entregaOutro, setEntregaOutro] = useState(ref_entregaOutro);
  const updateEntregaOutro = (source) => {
    Object.assign(ref_entregaOutro, source);
    setEntregaOutro({ ...ref_entregaOutro });
  };

  const [selectListEstados, setSelectListEstados] = useState([]);
  useEffect(() => {
    if (selectListEstados.length)
      return;

    let lista = entities.estados.map((estado) => {
      return {
        value: estado.uf,
        label: estado.nome,
      };
    })
    setSelectListEstados(lista);
  }, [entities])

  const [selectListCidades, setSelectListCidades] = useState({});
  useEffect(() => {
    if (selectListCidades.uf && selectListCidades.uf === entregaOutro.uf)
      return;

    let lista = (entities.estados.find((x) => x.uf === entregaOutro.uf)?.cidades || []).map((cidade) => {
      return {
        value: cidade.cidadeId,
        label: cidade.nome,
      };
    }) || [];

    if (lista.length === 0)
      return;

    setSelectListCidades({
      uf: entregaOutro.uf,
      options: lista
    });
  }, [entities, entregaOutro, selectListEstados])

  const [selectedCidade, setSelectedCidade] = useState({});
  useEffect(() => {
    if (selectedCidade?.value && selectedCidade?.value === entregaOutro.cidadeId)
      return;

    setSelectedCidade(
      (selectListCidades.options || []).find(
        (item) => item.value === entregaOutro.cidadeId
      ) || null
    );
  }, [entregaOutro, selectListCidades]);

  const buscaCep = () => {
    (async () => {
      showLoader(true);

      const result = await GET_ENDERECO_POR_CEP(entregaOutro.cep);

      showLoader(false);

      if (!result?.value)
        return;

      let endereco = result.value;

      updateEntregaOutro({
        cidadeId: endereco.cidadeId,
        cidade: endereco.cidade,
        uf: endereco.cidade.uf,
        logradouro: endereco.logradouro,
        bairro: endereco.bairro
      });
    })();
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <div className="entrega-form-group">
            <label>CEP</label>
            <InputCEP
              className={`form-control ${getCampoObrigatorioClassName(
                entregaOutro.cep,
                true
              )}`}
              value={entregaOutro.cep || ''}
              onChange={(value) => {
                updateEntregaOutro({ cep: value });

                entregaOutro.cep = value;
                if (entregaOutro.cep.length === 9)
                  buscaCep();
              }}
              onKeyDown={(e) => e.key === 'Enter' && buscaCep()}
            />
          </div>

          <div className="entrega-form-group">
            <label>Endereço</label>
            <input
              type="text"
              className={`form-control ${getCampoObrigatorioClassName(
                entregaOutro.logradouro,
                true
              )}`}
              value={entregaOutro.logradouro || ''}
              onChange={(event) => {
                updateEntregaOutro({ logradouro: event.target.value });
              }}
            />
          </div>

          <div className="entrega-form-group">
            <label>Número</label>
            <input
              type="text"
              className={`form-control ${getCampoObrigatorioClassName(
                entregaOutro.numero,
                true
              )}`}
              value={entregaOutro.numero || ''}
              onChange={(event) => {
                updateEntregaOutro({ numero: event.target.value });
              }}
            />
          </div>

          <div className="entrega-form-group">
            <label>Complemento</label>
            <input
              type="text"
              className="form-control"
              value={entregaOutro.complemento || ''}
              onChange={(event) => {
                updateEntregaOutro({ complemento: event.target.value });
              }}
            />
          </div>

          <div className="entrega-form-group">
            <label>Bairro</label>
            <input
              type="text"
              className={`form-control ${getCampoObrigatorioClassName(
                entregaOutro.bairro,
                true
              )}`}
              value={entregaOutro.bairro || ''}
              onChange={(event) => {
                updateEntregaOutro({ bairro: event.target.value });
              }}
            />
          </div>
        </div>

        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-6">
              <div className="entrega-form-group">
                <label>Estado</label>
                <Select
                  className={`${getCampoObrigatorioClassName(
                    entregaOutro.uf,
                    true
                  )}`}
                  options={selectListEstados}
                  isClearable={true}
                  onChange={(item) => {
                    updateEntregaOutro({ uf: item?.value });
                  }}
                  value={selectListEstados.find(
                    (item) => item.value === entregaOutro?.uf
                  )}
                />
              </div>

              <div className="entrega-form-group">
                <label>Cidade</label>
                <Select
                  className={`${getCampoObrigatorioClassName(
                    entregaOutro.cidadeId,
                    true
                  )}`}
                  components={optimizeSelect.components}
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={selectListCidades.options || []}
                  isClearable={true}
                  onChange={(item) => {
                    let cidadeId = item?.value ?? 0;
                    if (entregaOutro.cidadeId !== cidadeId) {
                      updateEntregaOutro({ cidadeId });
                    }
                  }}
                  value={selectedCidade}
                  isDisabled={!entregaOutro?.uf}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="entrega-form-group">
                <label>Razão Social</label>
                <input
                  type="text"
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaOutro.razaoSocial,
                    true
                  )}`}
                  value={entregaOutro.razaoSocial || ''}
                  onChange={(event) => {
                    updateEntregaOutro({ razaoSocial: event.target.value });
                  }}
                />
              </div>

              <div className="entrega-form-group">
                <label>CNPJ/CPF</label>
                <InputCpfCnpj
                  className={`${getCampoObrigatorioClassName(
                    entregaOutro.cnpj,
                    true
                  )}`}
                  value={entregaOutro.cnpj || ''}
                  onChange={(value) => {
                    updateEntregaOutro({ cnpj: value });
                  }}
                />
              </div>
            </div>
            <div />
          </div>

          <div className="row">
            <div className="col-lg-12">
              <hr />

              <h5>Dados do Responsável</h5>

              <div className="entrega-form-group">
                <label>Nome</label>
                <input
                  type="text"
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaOutro.responsavelNome,
                    true
                  )}`}
                  value={entregaOutro.responsavelNome || ''}
                  onChange={(event) => {
                    updateEntregaOutro({ responsavelNome: event.target.value });
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="entrega-form-group">
                <label>Telefone</label>
                <InputTelefone
                  className={`${getCampoObrigatorioClassName(
                    entregaOutro.responsavelTelefone,
                    true
                  )}`}
                  value={entregaOutro.responsavelTelefone || ''}
                  onChange={(value) => {
                    updateEntregaOutro({ responsavelTelefone: value });
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="entrega-form-group">
                <label>E-mail</label>
                <input
                  type="text"
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaOutro.responsavelEmail,
                    true
                  )}`}
                  value={entregaOutro.responsavelEmail || ''}
                  onChange={(event) => {
                    updateEntregaOutro({ responsavelEmail: event.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default _EntregaOutro;
