/* eslint-disable */
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import _EntregaOutro from './_entregaOutro';

const Outro = (props) => {
  const { model } = props;
  const { entities } = props;

  let setEntregaOutros;
  [model.entregaOutros, setEntregaOutros] = React.useState(model.entregaOutros || [{}])

  const adicionarEndereco = () => {
    model.entregaOutros.push({});
    setEntregaOutros([...model.entregaOutros]);
  };

  const removerEndereco = (index) => {
    model.entregaOutros.splice(index, 1);
    setEntregaOutros([...model.entregaOutros]);
  };

  return (
    <>
      {model.entregaOutros.map((entregaOutro, index) => (
        <div key={index} className="entrega-fisica-panel d-flex flex-column">
          <span className={`align-self-end icon-action ${model.entregaOutros?.length > 1 ? "d-inline-block" : "d-none"}`}>
            <FaTimes size={18} onClick={() => removerEndereco(index)} />
          </span>
          <_EntregaOutro entities={entities} entregaOutro={entregaOutro} />
        </div>
      ))}

      <button type="button" className="btn btn-link" onClick={adicionarEndereco}>Adicionar Endereço</button>
    </>
  );
};

export default Outro;
