/* eslint-disable */
import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import lineBreaker from 'helpers/LineBreaker';
import SemDados from '../SemDados';

const somaCanal = (canal) => {
  const years = Object.keys(canal);
  const returnArray = [];
  years.forEach((year) => {
    returnArray.push(canal[year].reduce((a, b) => a + b, 0));
  });
  return returnArray;
};

const AcumuladoCanal = (props) => {
  const { data } = props;

  const titulo = `ACUMULADO POR CANAL`;

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const canais = Object.keys(data);
  const anos = Object.keys(data[canais[0]].data);

  const series = canais.map((canal) => {
    return {
      name: canal,
      data: somaCanal(data[canal].data),
    };
  });

  const totalAnos = [];
  anos.forEach((ano, i) => {
    series.forEach((canal) => {
      totalAnos[i] = canal.data[i] + (totalAnos[i] || 0);
    });
  });

  const options = {
    legend: {
      formatter: (seriesName) => {
        return [seriesName.toUpperCase()];
      },
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      offsetX: 0,
      offsetY: 6,
    },
    colors: ['#faa306', '#59aaf1', '#d3d3d3'],
    plotOptions: {
      bar: {
        distributed: false,
        horizontal: false,
        dataLabels: {
          position: 'center',
          total: {
            enabled: true,
            formatter: (value, { dataPointIndex }) => {
              return `${totalAnos[dataPointIndex]}(100%)`;
            },
          },
        },
      },
    },
    xaxis: {
      show: true,
      labels: {
        show: true,
        rotate: 0,
        style: {
          fontWeight: 600,
        },
        formatter: (value) => {
          const lines = lineBreaker(value.toUpperCase(), 7);
          return lines;
        },
      },
      categories: anos,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
      padding: {
        bottom: -10,
        top: 10,
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      id: 'acu-canal',
      parentHeightOffset: 8,
      toolbar: {
        show: true,
      },
      stacked: true,
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: 0,
      formatter: (value, { dataPointIndex }) => {
        const porcentagem = (value / totalAnos[dataPointIndex]) * 100;
        return `${porcentagem.toFixed(2)}%`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: true,
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `<div class="arrow_box">
            <span>
              Ano: <span id="data">${anos[dataPointIndex].toUpperCase()}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${
                totalAnos[dataPointIndex]
              }</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="bar" height="300" />
    </div>
  );
};

export default AcumuladoCanal;
