/* eslint-disable */
import React, { useState, useContext } from 'react';
import { getStringFromDate } from '../_constants';
import { Link } from 'react-router-dom';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';
import TitlePages from 'components/_base/TitlePages';

import { Context } from 'context/AuthContext';
import './styles.scss';

function Sucesso(props) {
  let { model, enviarSolicitacao } = props;
  const { showLoader } = useContext(Context);
  const [ico, setIcon] = useState(false);

  const mudarIcon = () => {
    if (ico === false) {
      setIcon(true);
    } else {
      setIcon(false);
    }
  };

  const resumoItem = (item, model) => {
    return (
      <div className="col-lg-12 resumo mb-4" key={item.materialComunicacaoId}>
        <div className="resumo-header">
          <Accordion.Toggle
            onClick={mudarIcon}
            as={Card.Header}
            eventKey={item.materialComunicacaoId}
          >
            {item.pedidoId} - {item.materialComunicacao.nome}
            <div className="float-right">
              <FontAwesomeIcon
                id="icone"
                icon={ico !== true ? faAngleDown : faAngleUp}
              ></FontAwesomeIcon>
            </div>
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey={item.materialComunicacaoId}>
          <div className="resumo-body">
            <div className="resumo-informacoes">
              <h4>Informações</h4>
              <div className="resumo-informacoes-dados">
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Solicitante</div>
                  <div className="col-lg-9 info-dados">{model?.solicitante?.nome}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Supervisor</div>
                  <div className="col-lg-9 info-dados">{model?.usuarioSuperior?.nome}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Regional</div>
                  <div className="col-lg-9 info-dados">{model.loja?.regional?.nome}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Rede</div>
                  <div className="col-lg-9 info-dados">{model.loja?.razaoSocial}</div>
                </div>
              </div>
            </div>
            <div className="resumo-informacoes">
              <h4>Layout</h4>
              <img
                src={model.solicitacoes[0].materialComunicacao.urlImagem}
                className="img-fluid"
              />
              <hr />
            </div>
            <div className="resumo-informacoes">
              <h4>Ação Promocional</h4>
              <div className="resumo-informacoes-dados">
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Início da Vigência</div>
                  <div className="col-lg-9 info-dados">
                    {getStringFromDate(
                      item.solicitacaoDados?.dataVeiculacaoInicio
                    )}
                  </div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Fim da Vigência</div>
                  <div className="col-lg-9 info-dados">
                    {getStringFromDate(
                      item?.solicitacaoDados?.dataVeiculacaoFim
                    )}
                  </div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Incluir logo?</div>
                  <div className="col-lg-9 info-dados">{item.solicitacaoDados?.possuiLogo ? 'Sim' : 'Não'}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Tamanhos</div>
                  <div className="col-lg-9 info-dados">
                    {item.solicitacaoTamanhos.map(
                      (tamanho) => `${tamanho.largura}x${tamanho.altura} `
                    )}
                  </div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Observação</div>
                  <div className="col-lg-9 info-dados">{item.solicitacaoDados?.observacoesGerais}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Incluir Ação Promocional?</div>
                  <div className="col-lg-9 info-dados">
                    {item.solicitacaoDados.possuiAcaoPromocional
                      ? 'Sim'
                      : 'Não'}
                  </div>
                </div>

                {item.solicitacaoAcaoPromocionais !== null &&
                  item.solicitacaoAcaoPromocionais.length > 0 &&
                  item.solicitacaoDados.possuiAcaoPromocional &&
                  item.solicitacaoAcaoPromocionais.map(
                    (acao, key) =>
                      acao.acaoPromocional?.texto?.length > 0 && (
                        <div key={key} className="row row-striped">
                          <div className="col-lg-3 header-dados"></div>
                          <div className="col-lg-9 info-dados">
                            {acao.acaoPromocional?.texto}
                            {'  '}
                            {acao.solicitacaoAcaoPromocionalValores &&
                              acao.solicitacaoAcaoPromocionalValores.length > 0 &&
                              acao.solicitacaoAcaoPromocionalValores.map(
                                (valor) =>
                                  `${valor.acaoPromocionalCampo.texto}: ${valor.valor && valor.valor
                                  } `
                              )}
                          </div>
                        </div>
                      )
                  )}
              </div>
            </div>
            <div className="resumo-informacoes">
              <h4>Dados de Envio</h4>
              <div className="resumo-informacoes-dados">
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Nome</div>
                  <div className="col-lg-9 info-dados">{item.solicitacaoEntregaDigital?.nomeResponsavel}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Telefone</div>
                  <div className="col-lg-9 info-dados">
                    {item.solicitacaoEntregaDigital?.telefoneResponsavel}
                  </div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Email</div>
                  <div className="col-lg-9 info-dados">{item.solicitacaoEntregaDigital?.emailResponsavel}</div>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    );
  };

  return (
    <div className="container content-pedido-resumo pb-5">
      <TitlePages title="Solicitações criadas com sucesso!" />
      <div className="row">
        <div className="col-lg-12">
          <h4 className="text-center mb-5">
            Suas solicitações já foram enviadas para aprovação e em seguida
            serão encaminhadas para o time de criação. <br />
            Você poderá acompanhá-las pelos números de solicitação abaixo
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Accordion>
            {model.solicitacoes &&
              model.solicitacoes.map((item) => resumoItem(item, model))}
          </Accordion>
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex flex-row-reverse">
          <Link to="/">
            <button
              type="button"
              className="btn btn-primary btn-navigation btn-navigation-right"
            >
              <span>Pagina Inicial</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sucesso;
