import React from 'react';

import HeaderLogin from 'components/Header/HeaderLogin';
import BottomFixed from 'components/BottomFixed';
import ContentLogin from './ContentLogin';

const Login = () => {
  return (
    <>
      <HeaderLogin />
      <ContentLogin />
      <BottomFixed />
    </>
  );
};

export default Login;
