/* eslint-disable */
import { useEffect, useState } from 'react';

import api from 'api';
import {
  GET_LIST_MATERIAL_CADASTRO_ITENS_MODEL,
  GET_LIST_MATERIAL_CAMPANHA,
  GET_LIST_EXTENSAO_ARQUIVO,
  GET_LIST_ESTADO,
  GET_LIST_FORMA_PAGAMENTO,
  GET_LIST_MOTIVO_ACAO,
  GET_LOJA_LAYOUT_ARTES,
  GET_LIST_TEMPO_VIDEO,
  GET_LIST_TIPO_ENTREGA,
  GET_LIST_TIPO_MATERIAL,
  GET_LIST_TIPO_TABLOIDE,
  GET_LIST_QUANTIDADE_IMPRESSOS,
  GET_LIST_CAMPANHA,
  GET_TAB_PRODUTOS,
  GET_ENDERECO_CENTRO_DISTRIBUICAO,
} from 'services/Solicitacao';


const Entities = (props) => {
  const { setEntities, pedido, solicitacao, setLoader } = props;

  const [primaryLoader, setPrimaryLoader] = useState(false);
  const [secondaryLoader, setSecondaryLoader] = useState(false);

  const [lojaId, setLojaId] = useState(0);
  const [tipoMateriais, setTipoMateriais] = useState([]);
  const [tipoEntregas, setTipoEntregas] = useState([]);
  const [campanhas, setCampanhas] = useState([]);
  const [estados, setEstados] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [produtoCategorias, setProdutoCategorias] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [extensoes, setExtensoes] = useState([]);
  const [formaPagamentos, setFormaPagamentos] = useState([]);
  const [quantidadesImpressos, setQuantidadesImpressos] = useState([]);
  const [tempos, setTempos] = useState([]);

  const [enderecoFilial, setEnderecoFilial] = useState({});
  const [campanhaMateriais, setCampanhaMateriais] = useState([]);
  const [materiaisCadastroItens, setMateriaisCadastroItens] = useState([]);
  const [clienteLayouts, setClienteLayouts] = useState([]);

  useEffect(() => {
    if (primaryLoader || secondaryLoader) setLoader(true);
    else setLoader(false);
  }, [primaryLoader, secondaryLoader]);

  useEffect(() => {
    setEntities({
      tipoMateriais,
      tipoEntregas,
      campanhas,
      campanhaMateriais,
      estados,
      materiaisCadastroItens,
      unidades,
      produtos,
      produtoCategorias,
      clienteLayouts,
      motivos,
      extensoes,
      formaPagamentos,
      quantidadesImpressos,
      tempos,
      enderecoFilial,
    });
  }, [
    tipoMateriais,
    tipoEntregas,
    campanhas,
    campanhaMateriais,
    estados,
    materiaisCadastroItens,
    unidades,
    produtos,
    produtoCategorias,
    clienteLayouts,
    motivos,
    extensoes,
    formaPagamentos,
    quantidadesImpressos,
    tempos,
    enderecoFilial,
  ]);

  useEffect(() => {
    (async () => {
      setPrimaryLoader(true);

      Promise.all([
        getTipoEntregas(),
        getCampanhas(),
        getProdutosCategoriasUnidades(),
        getMotivos(),
        getExtensoes(),
        getFormaPagamentos(),
        getQuantidadeImpressos(),
        getTempos(),
      ]).then(() => {
        setPrimaryLoader(false);
      });

      getEstados()
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setSecondaryLoader(true);

      Promise.all([
        getTipoMateriais(),
        getEnderecoFilial(),
        getMateriaisCadastroItens(),
        getClienteLayouts(),
        getCampanhaMateriais(),
      ]).then(() => {
        setSecondaryLoader(false);
      });
    })();
  }, [pedido, solicitacao]);

  //tipoMateriais 'TABLOIDE'
  async function getTipoMateriais() {
    if (!pedido.loja?.lojaId) return;

    if (lojaId === 0 || lojaId !== pedido.loja.lojaId) {
      const result = await GET_LIST_TIPO_MATERIAL({
      //const result = await GET_LIST_TIPO_TABLOIDE({
          lojaId: pedido.loja.lojaId,
      });

      if (result?.value) {
        setLojaId(pedido.loja.lojaId);

        let tipoMateriais = result.value;
        setTipoMateriais(tipoMateriais);
      }
    }
  }

  //tipoEntregas
  async function getTipoEntregas() {
    if (!tipoEntregas.length) {
      const result = await GET_LIST_TIPO_ENTREGA();

      if (result?.value?.length) {
        let tipoEntregas = result.value;
        setTipoEntregas(tipoEntregas);
      }
    }
  }

  // Carregar os tipos de campanhas
  async function getCampanhas() {
    if (!campanhas.length) {
      const result = await GET_LIST_CAMPANHA();
      if (result?.value.length) {
        setCampanhas(result.value);
      }
    }
  }

  //produtos e categorias e unidades
  async function getProdutosCategoriasUnidades() {
    if (!produtos.length || !produtoCategorias.length || !unidades.length) {
      const result = await GET_TAB_PRODUTOS();

      if (result.value) {
        let produtos = result.value.produtos || [];
        let produtoCategorias = result.value.produtoCategorias || [];
        let unidades = result.value.unidades || [];

        setProdutos(produtos);
        setProdutoCategorias(produtoCategorias);
        setUnidades(unidades);
      }
    }
  }

  //motivos
  async function getMotivos() {
    if (!motivos.length) {
      const result = await GET_LIST_MOTIVO_ACAO();

      if (result?.value?.length) {
        let motivos = result.value;
        setMotivos(motivos);
      }
    }
  }

  //extensoes
  async function getExtensoes() {
    if (!extensoes.length) {
      const result = await GET_LIST_EXTENSAO_ARQUIVO();

      if (result?.value?.length) {
        let extensoes = result.value;
        setExtensoes(extensoes);
      }
    }
  }

  //formaPagamentos
  async function getFormaPagamentos() {
    if (!formaPagamentos.length) {
      const result = await GET_LIST_FORMA_PAGAMENTO();

      if (result?.value?.length) {
        let formaPagamentos = result.value;
        setFormaPagamentos(formaPagamentos);
      }
    }
  }

  //estados
  async function getEstados() {
    if (!estados.length) {
      const result = await GET_LIST_ESTADO();

      if (result?.value?.length) {
        let estados = result.value;
        setEstados(estados);
      }
    }
  }

  //quantidadesImpressos
  async function getQuantidadeImpressos() {
    if (!quantidadesImpressos.length) {
      const result = await GET_LIST_QUANTIDADE_IMPRESSOS();

      if (result?.value?.length) {
        let quantidadesImpressos = result.value;
        setQuantidadesImpressos(quantidadesImpressos);
      }
    }
  }

  //tempos
  async function getTempos() {
    if (!tempos.length) {
      const result = await GET_LIST_TEMPO_VIDEO();

      if (result?.value?.length) {
        let tempos = result.value;
        setTempos(tempos);
      }
    }
  }

  //enderecoFilial
  async function getEnderecoFilial() {
    if (!pedido.loja?.lojaId) return;

    if (!pedido.loja.enderecoFilial?.endereco) {
      const result = await GET_ENDERECO_CENTRO_DISTRIBUICAO(pedido.loja.lojaId);
      if (result.value) {
        pedido.loja.enderecoFilial = result.value;
        setEnderecoFilial(pedido.loja.enderecoFilial);
      }
    }
  }

  //materiaisCadastroItens (sempre que houver mudança no pedido ocorre a verificação)
  //todo: talvez ja buscar todos os cadastroItens dos materiais
  async function getMateriaisCadastroItens() {
    let materialComunicacaoIds = [];

    if (solicitacao?.materialComunicacaoId) {
      materialComunicacaoIds = [solicitacao.materialComunicacaoId];
    } else {
      materialComunicacaoIds = (pedido?.solicitacoes || []).map(
        (solicitacao) => solicitacao.materialComunicacaoId
      );
    }

    if (materialComunicacaoIds.length === 0) return;

    if (
      materialComunicacaoIds.filter(
        (materialComunicacaoId) =>
          materiaisCadastroItens.findIndex(
            (x) => x.materialComunicacaoId === materialComunicacaoId
          ) === -1
      ).length > 0
    ) {
      let params = new URLSearchParams();
      materialComunicacaoIds.forEach((materialComunicacaoId) => {
        params.append('MaterialComunicacaoIds', materialComunicacaoId);
      });

      const result = await GET_LIST_MATERIAL_CADASTRO_ITENS_MODEL(params);
      if (result?.value) {
        setMateriaisCadastroItens(result.value);
      }
    }
  }

  //clienteLayouts (sempre que ocorre um setModel verifica se o cliente mudou para buscar novamente os layouts)
  async function getClienteLayouts() {
    if (
      pedido.loja?.lojaId > 0 &&
      pedido.loja.lojaId !== clienteLayouts.lojaId
    ) {
      const result = await GET_LOJA_LAYOUT_ARTES(pedido.loja.lojaId);

      if (result?.value) {
        let clienteLayouts = result.value;
        setClienteLayouts(clienteLayouts);
      }
    }
  }

  //campanhaMateriais (sempre que ocorre um setModel verifica se a campanha mudou)
  async function getCampanhaMateriais() {
    if (
      pedido.campanhaId > 0 &&
      (!campanhaMateriais.length ||
        campanhaMateriais.findIndex(
          (campanhaMaterial) =>
            campanhaMaterial.campanhaId === pedido.campanhaId
        ) === -1)
    ) {
      const result = await GET_LIST_MATERIAL_CAMPANHA({
        lojaId: pedido.loja.lojaId,
        campanhaId: pedido.campanhaId,
      });
      // const result = {
      //   "code": 200,
      //   "message": null,
      //   "jwtToken": null,
      //   "value": [
      //     { "campanhaId": 1, "layoutArteId": 97, "layoutArteNome": "Teste", "layoutArteUrl": "/teste.png", "materialComunicacaoTipoId": 4, "materialComunicacaoTipoNome": "MATERIAIS DIGITAIS", "materialComunicacaoId": 16, "materialComunicacaoNome": "BANNER ESTÁTICO PARA SITE", "diasInicio": 0, "diasInicioDigital": 2, "diasInicioCapital": 0, "diasInicioInterior": 0, "duracaoVigencia": 0, "diasFim": 0, "alturaMinima": 0, "larguraMinima": 0, "possuiProducao": false, "minimoProdutos": 0, "maximoProdutos": 3, "apenasProdutosEspecificos": false, "precoObrigatorio": false, "unidadeMedida": "PX" },
      //     { "campanhaId": 2, "layoutArteId": 97, "layoutArteNome": "Teste", "layoutArteUrl": "/teste.png", "materialComunicacaoTipoId": 4, "materialComunicacaoTipoNome": "MATERIAIS DIGITAIS", "materialComunicacaoId": 16, "materialComunicacaoNome": "BANNER ESTÁTICO PARA SITE", "diasInicio": 0, "diasInicioDigital": 2, "diasInicioCapital": 0, "diasInicioInterior": 0, "duracaoVigencia": 0, "diasFim": 0, "alturaMinima": 0, "larguraMinima": 0, "possuiProducao": false, "minimoProdutos": 0, "maximoProdutos": 3, "apenasProdutosEspecificos": false, "precoObrigatorio": false, "unidadeMedida": "PX" },
      //     { "campanhaId": 3, "layoutArteId": 97, "layoutArteNome": "Teste", "layoutArteUrl": "/teste.png", "materialComunicacaoTipoId": 4, "materialComunicacaoTipoNome": "MATERIAIS DIGITAIS", "materialComunicacaoId": 16, "materialComunicacaoNome": "BANNER ESTÁTICO PARA SITE", "diasInicio": 0, "diasInicioDigital": 2, "diasInicioCapital": 0, "diasInicioInterior": 0, "duracaoVigencia": 0, "diasFim": 0, "alturaMinima": 0, "larguraMinima": 0, "possuiProducao": false, "minimoProdutos": 0, "maximoProdutos": 3, "apenasProdutosEspecificos": false, "precoObrigatorio": false, "unidadeMedida": "PX" },
      //     { "campanhaId": 4, "layoutArteId": 97, "layoutArteNome": "Teste", "layoutArteUrl": "/teste.png", "materialComunicacaoTipoId": 4, "materialComunicacaoTipoNome": "MATERIAIS DIGITAIS", "materialComunicacaoId": 16, "materialComunicacaoNome": "BANNER ESTÁTICO PARA SITE", "diasInicio": 0, "diasInicioDigital": 2, "diasInicioCapital": 0, "diasInicioInterior": 0, "duracaoVigencia": 0, "diasFim": 0, "alturaMinima": 0, "larguraMinima": 0, "possuiProducao": false, "minimoProdutos": 0, "maximoProdutos": 3, "apenasProdutosEspecificos": false, "precoObrigatorio": false, "unidadeMedida": "PX" },
      //     { "campanhaId": 5, "layoutArteId": 97, "layoutArteNome": "Teste", "layoutArteUrl": "/teste.png", "materialComunicacaoTipoId": 4, "materialComunicacaoTipoNome": "MATERIAIS DIGITAIS", "materialComunicacaoId": 16, "materialComunicacaoNome": "BANNER ESTÁTICO PARA SITE", "diasInicio": 0, "diasInicioDigital": 2, "diasInicioCapital": 0, "diasInicioInterior": 0, "duracaoVigencia": 0, "diasFim": 0, "alturaMinima": 0, "larguraMinima": 0, "possuiProducao": false, "minimoProdutos": 0, "maximoProdutos": 3, "apenasProdutosEspecificos": false, "precoObrigatorio": false, "unidadeMedida": "PX" },
      //   ],
      //   "errors": null,
      //   "exception": null
      // }

      if (result?.value) {
        let campanhaMateriais = result.value;
        setCampanhaMateriais(campanhaMateriais);

      }
    }
  }

  return null;
};

export default Entities;
