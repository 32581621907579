import React, { useContext, useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { Tabs, Tab, Button } from 'react-bootstrap';
import { GET_RELATORIO_PRODUTIVIDADE } from 'services/RelatorioGrafico';
import { Context } from 'context/AuthContext';
import {
  TabCusto,
  TabFechamento,
  TabParticipacao,
  TabProdutividade,
} from './ContentProdutividade/Tabs';
import './styles.scss';
import logo from '../../../../assets/imgs/logo_cor.png';
import Filtro from './ContentProdutividade/Filtro';
import Cards from './ContentProdutividade/Cards';
// import { filterOptions, sampleData } from './ContentProdutividade/data/sampleData';

const Produtividade = () => {
  const initialState = {
    ano: null,
    mes: null,
    regiao: null,
    canal: null,
    marca: null,
    categoria: null,
    material: null,
  };
  const { showLoader } = useContext(Context);
  const [pageLoaded, setPageLoaded] = useState(false);
  /**
   * filtro = todas as opcoes de filtro, setada apenas na primeira request
   * filtroDisp = opcoes de filtro disponiveis de acordo com a selecao
   * filtroSelecionado = opcoes de filtro selecionadas dentre as disponiveis
   */
  const [filtro, setFiltro] = useState(
    JSON.parse(JSON.stringify(initialState)) || null
  );
  const [filtroDisp, setFiltroDisp] = useState(
    JSON.parse(JSON.stringify(initialState)) || null
  );
  const [filtroSelecionado, setFiltroSelecionado] = useState(
    JSON.parse(JSON.stringify(initialState)) || null
  );

  const [data, setData] = useState(null);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(true);

  const produtividadeRef = useRef(null);
  const custoRef = useRef(null);
  const participacaoRef = useRef(null);
  const fechamentoRef = useRef(null);

  const fetchData = async () => {
    // const tmpFiltroSelecionado = { ...filtroSelecionado };
    if (pageLoaded) {
      // mudando valor do filtroSelecionado para [] quando todas opcoes de filtro estao selecionadas
      const filterProperties = [
        'ano',
        'mes',
        'regiao',
        'canal',
        'marca',
        'categoria',
        'material',
      ];
      // para cada propriedade do filtro, verifica se o filtroSelecionado/tmpFiltroSelecionado é igual ao filtroDisponivel
      filterProperties.forEach((property) => {
        if (
          JSON.stringify(filtroSelecionado?.[property]?.sort()) ===
          JSON.stringify(filtroDisp?.[property]?.sort())
        ) {
          filtroSelecionado[property] = [];
        }
      });
    }
    const response = await GET_RELATORIO_PRODUTIVIDADE(filtroSelecionado);
    return response;
  };
  const handleExportClick = async (tabRef, titulo) => {
    showLoader(true);
    if (tabRef.current) {
      const div = tabRef.current;
      if (div) {
        div.classList.add('export-estilo');
      }
      await html2canvas(tabRef.current).then((canvas) => {
        // Convert canvas to PNG image data URL
        const imageDataURL = canvas.toDataURL('image/png');

        // Create a link element and trigger a download
        const downloadLink = document.createElement('a');
        downloadLink.href = imageDataURL;
        const today = new Date();
        downloadLink.download = `relatorio_${titulo}-${today.getDate()}-${
          today.getMonth() + 1
        }-${today.getFullYear()}.png`;
        downloadLink.click();
      });
      div.classList.remove('export-estilo');
    }
    showLoader(false);
  };

  // useEffect inicial, roda apenas quando a pagina nao for carregada
  useEffect(async () => {
    if (!pageLoaded) {
      showLoader(true);
      await fetchData().then((res) => {
        setFiltro(res.value.filtro.filtroInicial);
        setFiltroDisp(res.value.filtro.filtroDisponivel);
        setFiltroSelecionado(res.value.filtro.filtroSelecionado);
        setData(res.value.data);
      });
      showLoader(false);
      setPageLoaded(true);
    }
  }, [pageLoaded]);
  // useEffect para atualizar os dados de acordo com o filtro selecionado
  useEffect(async () => {
    if (pageLoaded) {
      showLoader(true);
      await fetchData().then((res) => {
        setFiltroDisp(res.value.filtro.filtroDisponivel);
        setFiltroSelecionado(res.value.filtro.filtroSelecionado);
        setData(res.value.data);
      });
      showLoader(false);
    }
  }, [fetchDataTrigger]);

  return pageLoaded ? (
    <div className="wrapper-produtividade">
      <Tabs className="tabs">
        <Tab eventKey="produtividade" title="Produtividade">
          <div className="tab-header">
            <img src={logo} alt="logo" className="img-logo" />
            <Button
              onClick={() =>
                handleExportClick(produtividadeRef, 'produtividade')
              }
            >
              Exportar página
            </Button>
          </div>
          <Filtro
            setFetchDataTrigger={setFetchDataTrigger}
            filtro={filtro}
            filtroDisp={filtroDisp}
            filtroSelecionado={filtroSelecionado}
            setFiltroSelecionado={setFiltroSelecionado}
          />
          <div ref={produtividadeRef}>
            <Cards data={data?.total} />
            <TabProdutividade data={data?.produtividade} />
          </div>
        </Tab>
        <Tab eventKey="custo" title="Custo/Verba">
          <div className="tab-header">
            <img src={logo} alt="logo" className="img-logo" />
            <Button onClick={() => handleExportClick(custoRef, 'custo')}>
              Exportar página
            </Button>
          </div>
          <Filtro
            setFetchDataTrigger={setFetchDataTrigger}
            filtro={filtro}
            filtroDisp={filtroDisp}
            filtroSelecionado={filtroSelecionado}
            setFiltroSelecionado={setFiltroSelecionado}
          />
          <div ref={custoRef}>
            <Cards data={data?.total} />
            <TabCusto data={data?.custo} />
          </div>
        </Tab>
        <Tab eventKey="participacao" title="Participação">
          <div className="tab-header">
            <img src={logo} alt="logo" className="img-logo" />
            <Button
              onClick={() => handleExportClick(participacaoRef, 'participacao')}
            >
              Exportar página
            </Button>
          </div>
          <Filtro
            setFetchDataTrigger={setFetchDataTrigger}
            filtro={filtro}
            filtroDisp={filtroDisp}
            filtroSelecionado={filtroSelecionado}
            setFiltroSelecionado={setFiltroSelecionado}
            material
          />
          <div ref={participacaoRef}>
            <Cards data={data?.total} />
            <TabParticipacao data={data?.participacao} />
          </div>
        </Tab>
        <Tab eventKey="fechamento" title="Fechamento">
          <div className="tab-header">
            <img src={logo} alt="logo" className="img-logo" />
            <Button
              onClick={() => handleExportClick(fechamentoRef, 'fechamento')}
            >
              Exportar página
            </Button>
          </div>
          <Filtro
            setFetchDataTrigger={setFetchDataTrigger}
            filtro={filtro}
            filtroDisp={filtroDisp}
            filtroSelecionado={filtroSelecionado}
            setFiltroSelecionado={setFiltroSelecionado}
          />
          <div ref={fechamentoRef}>
            <TabFechamento data={data?.fechamento} />
          </div>
        </Tab>
      </Tabs>
    </div>
  ) : null;
};

export default Produtividade;
