import React from 'react';

import TitlePages from 'components/_base/TitlePages';
import Contact from 'components/Contact';
import AbrirChamado from 'components/AbrirChamado';
import Doubts from 'components/Doubts';

import './styles.scss';

const ContentAtendimento = () => {
  return (
    <div className="wrapper-atendimento">
      <TitlePages title="ATENDIMENTO" />
      <div className="content">
        <div className="contato col-lg-10">
          <Contact />
        </div>
        <div className=" abrirChamado col-lg-10">
          <AbrirChamado />
        </div>
        <div className=" duvidas col-lg-10">
          <Doubts />
        </div>
      </div>
    </div>
  );
};

export default ContentAtendimento;
