import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import { relatoriosColors } from 'constants/appConstants';
import SemDados from '../SemDados';
import { months } from '../../data/sampleData';

const EvolucaoSolicitacoesVarejo = (props) => {
  const { data } = props;

  const titulo = 'Evolução de total de solicitações - FOOD VAREJO';

  const canais = ['food & VAREJO'];

  if (
    !data ||
    Object.keys(data).length === 0 ||
    !Object.keys(data).includes(canais[0])
  )
    return <SemDados titulo={titulo} />;

  const anos = Object.keys(data[canais[0]].data);
  const meses = [];
  const values = {};

  let maxValue = 1;

  const calculateSeries = () => {
    const tmpSeries = [];
    if (anos.length === 0) {
      return [];
    }
    months.forEach((mes, i) => {
      let check = false;
      anos.forEach((ano) => {
        if (data[canais[0]].data[ano][i] !== null) {
          maxValue = Math.max(maxValue, data[canais[0]].data[ano][i]);
          check = true;
        }
      });
      if (check) {
        anos.forEach((ano) => {
          values[ano] = [...(values[ano] || []), data[canais[0]].data[ano][i]];
        });
        meses.push(`${mes.slice(0, 3)}.`);
      }
    });
    anos.forEach((ano) => {
      tmpSeries.push({
        name: ano,
        data: values[ano],
      });
    });
    return tmpSeries;
  };

  const series = calculateSeries();

  const options = {
    legend: {
      show: true,
    },
    colors: relatoriosColors.colors,
    plotOptions: {
      bar: {
        hideZeroBarsWhenGrouped: false,
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
        colors: {},
      },
    },
    stroke: {
      colors: ['transparent'],
      width: 4,
    },
    xaxis: {
      categories: [...meses],
      show: true,
      position: 'top',
      labels: {
        show: true,
        offsetY: 10,
        style: {
          fontWeight: 600,
        },
      },
      axisBorder: {
        color: '#d7d7d7',
      },
      axisTicks: {
        show: true,
        height: 20,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      max: maxValue * 1.2,
      show: false,
    },
    grid: {
      padding: {
        bottom: -20,
      },
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      id: 'can-solicitacoes',
      parentHeightOffset: 8,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: -19,
      formatter: (value) => {
        return value === null ? 0 : `${value}`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Mês de Data Criação: <span id="data">${meses[dataPointIndex]}</span>
            </span>
            <span>
              Ano de Data Criação: <span id="data">${anos[seriesIndex]}</span>
            </span>
            <span>
              Contagem Distinta de Solicitação: <span id="data">${series[seriesIndex][dataPointIndex]}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="bar" height="250" />
    </div>
  );
};

export default EvolucaoSolicitacoesVarejo;
