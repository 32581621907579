import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import lineBreaker from 'helpers/LineBreaker';
import SemDados from '../SemDados';

const somaCanal = (canal) => {
  const years = Object.keys(canal);
  let soma = 0;
  years.forEach((year) => {
    canal[year].forEach((num) => {
      if (num !== null) {
        soma += num;
      }
    });
  });
  return soma;
};

const AcumuladoCanal = (props) => {
  const { data } = props;

  const titulo = 'Acumulado por canal';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const canais = Object.keys(data);
  const series = [
    {
      data: canais.map((canal) => {
        return somaCanal(data[canal].data);
      }),
    },
  ];

  const options = {
    legend: {
      show: false,
    },
    colors: ['#faa306', '#59aaf1', '#d3d3d3'],
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
        colors: {},
      },
    },
    xaxis: {
      show: true,
      labels: {
        formatter: (value) => {
          const lines = lineBreaker(value.toUpperCase(), 16);
          return lines;
        },
        show: true,
        rotate: 0,
        style: {
          fontWeight: 600,
        },
      },
      categories: canais,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    chart: {
      id: 'acu-canal',
      parentHeightOffset: 0,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: -19,
      formatter: (value, { dataPointIndex }) => {
        return `${value}(${data[canais[dataPointIndex]].percentual}%)`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Canal: <span id="data">${canais[dataPointIndex]}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${
                series[seriesIndex][dataPointIndex]
              }</span>
            </span>
            <span>
              %: <span id="data">${
                data[canais[dataPointIndex]].percentual
              }</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="bar" height="250" />
    </div>
  );
};

export default AcumuladoCanal;
