import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const AnoSolicitacoes = (props) => {
  const { data } = props;

  const titulo = 'EVOLUÇÃO SOLICITAÇÕES POR ANO';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const anos = Object.keys(data);

  const series = [{ data: anos.map((ano) => data[ano]) }];

  const options = {
    annotations: {
      xaxis: [
        {
          x: anos[4],
          borderColor: '#999',
          offsetX: -30,
          label: {
            offsetX: -30,
            borderColor: '#999',
            style: {
              color: '#fff',
              background: '#999',
              fontSize: '12px',
            },
            text: 'Início Plataforma',
          },
        },
      ],
    },
    colors: ['#021f59'],
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: true,
          orientation: 'horizontal',
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: false,
        trim: false,
        rotateAlways: false, // mudar para true se quiser que todas as labels sejam rotacionadas
      },
      categories: anos,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      max: Math.max(...anos.map((ano) => data[ano])) * 1.1,
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      height: 'auto',
      id: 'reg-solicitacoes',
      parentHeightOffset: 0,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: -20,
      formatter: (value) => {
        return `${parseInt(value, 10).toLocaleString('pt-BR')}`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 550,
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Ano: <span id="data">${anos[dataPointIndex].toUpperCase()}</span>
            </span>
            <span>
              Qtde de Solicitações: <span id="data">${parseInt(
                series[seriesIndex][dataPointIndex],
                10
              ).toLocaleString('pt-BR')}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="bar" height="300" />
    </div>
  );
};

export default AnoSolicitacoes;
