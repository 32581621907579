import api from 'api';

const qs = require('qs');

const PATH = '/RelatorioGrafico';

const GET_RELATORIO_PRODUTIVIDADE = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-relatorio-produtividade`, {
    params: filtros,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { GET_RELATORIO_PRODUTIVIDADE };
