/* eslint-disable */

import React from 'react';

export const PedidoContext = React.createContext('');

export const PedidoProvider = (props) => {
  const search = new URLSearchParams(window.location.search);
  const materialComunicacaoTipo = search.get('materialComunicacaoTipo') ?? '';

  return (
    <PedidoContext.Provider value={materialComunicacaoTipo}>
      {props.children}
    </PedidoContext.Provider>
  );
};
