/* eslint-disable */
import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import {
  cadastroItemKeys,
  entregaTipoNomes,
  checkUrlImage,
  getStringFromDate,
  getStringFromPrice,
} from '../../_constants';

const ResumoMaterial = (props) => {
  let solicitacao = props.solicitacao;
  let loja = props.loja || {};
  let entregaDigital = props.entregaDigital || {};
  let entregaCentroDistribuicao = props.entregaCentroDistribuicao || {};
  let entregaLojasParticipantes = props.entregaLojasParticipantes || [];
  let entregaOutros = props.entregaOutros || [];
  let pedidoCriado = props.pedidoCriado;
  let entities = props.entities;
  let accordionKey = '' + props.index;

  solicitacao.solicitacaoId = solicitacao.solicitacaoId
    ? solicitacao.solicitacaoId
    : (pedidoCriado.solicitacoes || []).find(
      (x) => x.materialComunicacaoId === solicitacao.materialComunicacaoId
    )?.solicitacaoId || 0;

  let cliente = loja;

  let solicitacaoDados = solicitacao.solicitacaoDados || {};

  let material = []
    .concat(
      ...(entities.tipoMateriais || []).map(
        (tipoMaterial) => tipoMaterial.materiaisComunicacao
      )
    )
    .find((m) => m.materialComunicacaoId === solicitacao.materialComunicacaoId);

  let cadastroItens =
    (entities.materiaisCadastroItens || []).find(
      (x) => x.materialComunicacaoId === solicitacao.materialComunicacaoId
    )?.cadastroItens || [];

  cadastroItens = (cadastroItens || []).filter(
    (cadastroItem) =>
      (solicitacao.imprimir && cadastroItem.entregaFisica) ||
      (!solicitacao.imprimir && cadastroItem.entregaDigital)
  );

  let nomeFantasia =
    (solicitacaoDados.nomeFantasia || '').length > 0
      ? solicitacaoDados.nomeFantasia
      : cliente.nomeFantasia;

  let motivo =
    (entities.motivos || []).find(
      (motivo) => motivo.motivoAcaoId === solicitacaoDados.motivoAcaoId
    ) || {};

  let outroMotivo = motivo.habilitaDescricao
    ? ' - ' + (solicitacaoDados.outroMotivo || '')
    : '';

  let cidades = [].concat(
    ...(entities.estados || []).map((estado) => estado.cidades)
  );

  let tipoEntrega =
    (entities.tipoEntregas || []).find((t) => {
      return t.entregaTipoId === solicitacao.entregaTipoId;
    }) || {};

  let solicitacaoTamanhos = solicitacao.solicitacaoTamanhos || [];

  let unidadeMedida = material?.unidadeMedida?.toLowerCase();

  let extensaoArquivo =
    (entities.extensoes || []).find(
      (item) => item.nome === solicitacaoDados.extensaoArquivo
    ) || {};

  let tempoVideo =
    (entities.tempos || []).find(
      (item) => item.tempoVideoId === solicitacaoDados.tempoVideoId
    ) || {};

  let extensaoOutro = extensaoArquivo.habilitaDescricao
    ? ' - ' + (solicitacaoDados.extensaoOutro || '')
    : '';

  let possuiLogo = solicitacaoDados.possuiLogo ? 'Sim' : 'Não';

  let possuiRodape = solicitacaoDados.possuiRodape ? 'Sim' : 'Não';

  let layoutArte =
    ((entities.clienteLayouts || {}).layoutArtes || []).find(
      (layout) => layout.layoutArteId === solicitacaoDados.layoutArteId
    ) || {};

  let solicitacaoRodapes = (solicitacao.solicitacaoRodapes || []).map(
    (solicitacaoRodape) => {
      return {
        ...solicitacaoRodape,
        cidade: cidades.find(
          (cidade) => cidade.cidadeId === solicitacaoRodape.cidadeId
        ),
      };
    }
  );

  let solicitacaoProdutos = solicitacao.solicitacaoProdutos || [];

  entregaOutros = (entregaOutros || []).map(
    (entregaOutro) => {
      return {
        ...entregaOutro,
        cidade: cidades.find(
          (cidade) => cidade.cidadeId === entregaOutro.cidadeId
        ),
      };
    }
  );

  const renderInformacoes = () => {
    return (
      <div className="resumo-informacoes">
        <h4>Informações</h4>
        <div className="resumo-informacoes-dados">
          <div className="row row-striped">
            <div className="col-lg-3 header-dados">Rede</div>
            <div className="col-lg-9 info-dados">{cliente.razaoSocial}</div>
          </div>

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_Veiculacao
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Veiculação</div>
                <div className="col-lg-9 info-dados">
                  {getStringFromDate(solicitacaoDados.dataVeiculacaoInicio)} -{' '}
                  {getStringFromDate(solicitacaoDados.dataVeiculacaoFim)}
                </div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_DataEnvioArte
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Data para receber o Layout</div>
                <div className="col-lg-9 info-dados">{getStringFromDate(solicitacaoDados.dataEnvioArte)}</div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_Motivo
          ) !== -1 && motivo.motivoAcaoId > 0 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Motivo da ação</div>
                <div className="col-lg-9 info-dados">
                  {motivo.nome || ''} {outroMotivo}
                </div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key ===
              cadastroItemKeys.Dados_QuantidadeLojasParticipantes
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Quantidade de Lojas Participantes</div>
                <div className="col-lg-9 info-dados">{solicitacaoDados.quantidadeLojasParticipantes}</div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key ===
              cadastroItemKeys.Dados_QuantidadeImpressosLoja
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Quantidade de Impressos por Loja</div>
                <div className="col-lg-9 info-dados">{solicitacaoDados.quantidadeImpressosLoja}</div>
              </div>
            )}

          {(cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_Tamanho_Unico
          ) !== -1 ||
            cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_Tamanhos_Multi
            ) !== -1) && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Medidas (Largura x Altura)</div>
                <div className="col-lg-9 info-dados">
                  {solicitacaoTamanhos.map((solicitacaoTamanho, index) => (
                    <span key={index} className="d-block">
                      {solicitacaoTamanho.largura} {unidadeMedida}{' '}
                      <span className="m-2">X</span> {solicitacaoTamanho.altura}{' '}
                      {unidadeMedida}
                    </span>
                  ))}
                </div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_ExtensaoArquivo
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Tipo de Arquivo</div>
                <div className="col-lg-9 info-dados">
                  {extensaoArquivo.nome || ''} {extensaoOutro}
                </div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_Tempo
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Tempo (segundos)</div>
                <div className="col-lg-9 info-dados">{tempoVideo.descricao || ''}</div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Site
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Site</div>
                <div className="col-lg-9 info-dados">{solicitacaoDados.site}</div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_Facebook
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Facebook</div>
                <div className="col-lg-9 info-dados">{solicitacaoDados.facebook}</div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_Instagram
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Instagram</div>
                <div className="col-lg-9 info-dados">{solicitacaoDados.instagram}</div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_TelefoneContato
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Telefone Contato</div>
                <div className="col-lg-9 info-dados">{solicitacaoDados.telefoneContato}</div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_TelefoneDelivery
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Telefone Delivery</div>
                <div className="col-lg-9 info-dados">{solicitacaoDados.telefoneDelivery}</div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Logo
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Incluir logo na arte?</div>
                <div className="col-lg-9 info-dados">{possuiLogo}</div>
              </div>
            )}

          {cadastroItens.findIndex(
            (cadastroItem) =>
              cadastroItem.key === cadastroItemKeys.Dados_Rodape
          ) !== -1 && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">O cliente possui rodapé?</div>
                <div className="col-lg-9 info-dados">{possuiRodape}</div>
              </div>
            )}

          {solicitacaoDados.observacoesGerais && solicitacaoDados.observacoesGerais.length && (
            <div className="row row-striped">
              <div className="col-lg-3 header-dados">Observação</div>
              <div className="col-lg-9 info-dados">{solicitacaoDados.observacoesGerais}</div>
            </div>
          )}
        </div>
        <hr />
      </div>
    );
  };

  const renderLayout = () => {
    if (!layoutArte.layoutArteId) return null;

    return (
      <div className="resumo-informacoes">
        <h4>Layout</h4>
        <img src={layoutArte.urlImagem} className="img-fluid" />
        <hr />
      </div>
    );
  };

  const renderLogo = () => {
    if (
      cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Logo
      ) === -1
    )
      return null;

    if (!cliente.urlLogo || !cliente.urlLogo.length) return null;

    return (
      <div className="resumo-informacoes">
        <h4>Logo</h4>
        <img src={cliente.urlLogo} className="img-fluid" />
        <hr />
      </div>
    );
  };

  const renderRodape = () => {
    if (
      cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Rodape
      ) === -1
    )
      return null;

    return (
      <div className="resumo-informacoes">
        <h4>Rodapé</h4>
        {renderRodapeArquivo()}
        {renderRodapeEnderecos()}
        <hr />
      </div>
    );
  };

  const renderRodapeArquivo = () => {
    if (!possuiRodape) return null;

    if (!cliente.urlRodape || !cliente.urlRodape.length) {
      return null;
    }

    if (checkUrlImage(cliente.urlRodape)) {
      return <img src={cliente.urlRodape} className="img-fluid" />;
    } else {
      return (
        <a href={cliente.urlRodape} target="_blank" className="btn btn-primary">
          Baixar Rodapé
        </a>
      );
    }
  };

  const renderRodapeEnderecos = () => {
    // if (possuiRodape)
    //   return null;

    return (
      <>
        <div className="resumo-informacoes-dados">
          <div className="row row-striped">
            <div className="col-lg-3 header-dados">Forma de Pagamento</div>
            <div className="col-lg-9 info-dados">{solicitacaoDados.formaPagamento}</div>
          </div>
          {solicitacaoDados.outraFormaPagamento &&
            solicitacaoDados.outraFormaPagamento.length && (
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Forma de Pagamento</div>
                <div className="col-lg-9 info-dados">{solicitacaoDados.formaPagamento}</div>
              </div>
            )}
          <div className="row row-striped">
            <div className="col-lg-3 header-dados">Endereços</div>
            <div className="col-lg-9 info-dados">
              {solicitacaoRodapes.map((solicitacaoRodape, index) => {
                return (
                  <div key={index}>
                    <div className="resumo-informacoes-dados">
                      <div className="row row-striped">
                        <div className="col-lg-3 header-dados">CEP</div>
                        <div className="col-lg-9 info-dados">{solicitacaoRodape.cep}</div>
                      </div>
                      <div className="row row-striped">
                        <div className="col-lg-3 header-dados">Endereço</div>
                        <div className="col-lg-9 info-dados">{solicitacaoRodape.endereco}</div>
                      </div>
                      <div className="row row-striped">
                        <div className="col-lg-3 header-dados">Número</div>
                        <div className="col-lg-9 info-dados">{solicitacaoRodape.numero}</div>
                      </div>
                      <div className="row row-striped">
                        <div className="col-lg-3 header-dados">Complemento</div>
                        <div className="col-lg-9 info-dados">{solicitacaoRodape.complemento}</div>
                      </div>
                      <div className="row row-striped">
                        <div className="col-lg-3 header-dados">Bairro</div>
                        <div className="col-lg-9 info-dados">{solicitacaoRodape.bairro}</div>
                      </div>
                      <div className="row row-striped">
                        <div className="col-lg-3 header-dados">Cidade</div>
                        <div className="col-lg-9 info-dados">{(solicitacaoRodape.cidade || {}).nome}</div>
                      </div>
                      <div className="row row-striped">
                        <div className="col-lg-3 header-dados">Estado</div>
                        <div className="col-lg-9 info-dados">{(solicitacaoRodape.cidade || {}).uf}</div>
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderEntregaDigital = () => {
    if (!tipoEntrega.entregaDigital) return null;

    return (
      <div className="resumo-informacoes">
        <h4>Entrega Digital</h4>
        <div className="resumo-informacoes-dados">
          <div className="row row-striped">
            <div className="col-lg-3 header-dados">Nome </div>
            <div className="col-lg-9 info-dados">{entregaDigital.nome}</div>
          </div>
          <div className="row row-striped">
            <div className="col-lg-3 header-dados">Email(s) </div>
            <div className="col-lg-9 info-dados">{entregaDigital.email}</div>
          </div>
          <div className="row row-striped">
            <div className="col-lg-3 header-dados">Telefone </div>
            <div className="col-lg-9 info-dados">{entregaDigital.telefone}</div>
          </div>
        </div>

        <hr />
      </div>
    );
  };

  const renderEntregaFisica = () => {
    if (!tipoEntrega.entregaFisica) return null;

    return (
      <div className="resumo-informacoes">
        <h4>Entrega(s)</h4>
        {renderEntregaCentroDistribuicao()}
        {renderEntregaLojaLoja()}
        {renderEntregaOutro()}
        <hr />
      </div>
    );
  };

  const renderEntregaCentroDistribuicao = () => {
    if (tipoEntrega.nome !== entregaTipoNomes.centroDistribuicao) return null;

    return (
      <div className="resumo-informacoes-dados">
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">CEP</div>
          <div className="col-lg-9 info-dados">{cliente.cep}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Endereço</div>
          <div className="col-lg-9 info-dados">{cliente.logradouro}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Número</div>
          <div className="col-lg-9 info-dados">{cliente.numero}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Complemento</div>
          <div className="col-lg-9 info-dados">{cliente.complemento}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Bairro</div>
          <div className="col-lg-9 info-dados">{cliente.bairro}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Cidade</div>
          <div className="col-lg-9 info-dados">{cliente.cidade?.nome}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Estado</div>
          <div className="col-lg-9 info-dados">{cliente.cidade?.uf}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Razão Social</div>
          <div className="col-lg-9 info-dados">{cliente.razaoSocial}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">CNPJ</div>
          <div className="col-lg-9 info-dados">{cliente.cnpj}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Nome</div>
          <div className="col-lg-9 info-dados">{entregaCentroDistribuicao.responsavelNome}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Telefone</div>
          <div className="col-lg-9 info-dados">{entregaCentroDistribuicao.responsavelTelefone}</div>
        </div>
        <div className="row row-striped">
          <div className="col-lg-3 header-dados">Email</div>
          <div className="col-lg-9 info-dados">{entregaCentroDistribuicao.responsavelEmail}</div>
        </div>
      </div>
    );
  };

  const renderEntregaLojaLoja = () => {
    if (tipoEntrega.nome !== entregaTipoNomes.lojaLoja) return null;

    return (
      <div>
        {entregaLojasParticipantes.map((loja, index) => {
          return (
            <div key={index}>
              <div className="resumo-informacoes-dados">
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">CEP</div>
                  <div className="col-lg-9 info-dados">{loja.cep}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Endereço</div>
                  <div className="col-lg-9 info-dados">{loja.logradouro}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Número</div>
                  <div className="col-lg-9 info-dados">{loja.numero}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Complemento</div>
                  <div className="col-lg-9 info-dados">{loja.complemento}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Bairro</div>
                  <div className="col-lg-9 info-dados">{loja.bairro}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Cidade</div>
                  <div className="col-lg-9 info-dados">{loja.cidade?.nome}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Estado</div>
                  <div className="col-lg-9 info-dados">{loja.cidade?.uf}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Razão Social</div>
                  <div className="col-lg-9 info-dados">{loja.razaoSocial}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">CNPJ</div>
                  <div className="col-lg-9 info-dados">{loja.cnpj}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Nome</div>
                  <div className="col-lg-9 info-dados">{loja.responsavelNome}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Telefone</div>
                  <div className="col-lg-9 info-dados">{loja.responsavelTelefone}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Email</div>
                  <div className="col-lg-9 info-dados">{loja.responsavelEmail}</div>
                </div>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    );
  };

  const renderEntregaOutro = () => {
    if (tipoEntrega.nome !== entregaTipoNomes.outro) return null;

    if (entregaOutros.length === 0)
      return null;

    return (
      <div className="row">
        {entregaOutros.map(entregaOutro => (
          <div className="col-lg-6">
            <div className="resumo-informacoes-dados">
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">CEP</div>
                <div className="col-lg-9 info-dados">{entregaOutro.cep}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Endereço</div>
                <div className="col-lg-9 info-dados">{entregaOutro.logradouro}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Número</div>
                <div className="col-lg-9 info-dados">{entregaOutro.numero}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Complemento</div>
                <div className="col-lg-9 info-dados">{entregaOutro.complemento}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Bairro</div>
                <div className="col-lg-9 info-dados">{entregaOutro.bairro}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Cidade</div>
                <div className="col-lg-9 info-dados">{entregaOutro.cidade?.nome}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Estado</div>
                <div className="col-lg-9 info-dados">{entregaOutro.cidade?.uf}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Razão Social</div>
                <div className="col-lg-9 info-dados">{entregaOutro.razaoSocial}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">CNPJ</div>
                <div className="col-lg-9 info-dados">{entregaOutro.cnpj}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Nome</div>
                <div className="col-lg-9 info-dados">{entregaOutro.responsavelNome}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Telefone</div>
                <div className="col-lg-9 info-dados">{entregaOutro.responsavelTelefone}</div>
              </div>
              <div className="row row-striped">
                <div className="col-lg-3 header-dados">Email</div>
                <div className="col-lg-9 info-dados">{entregaOutro.responsavelEmail}</div>
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
    );
  };

  const renderProdutos = () => {
    if (
      cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Logo
      ) === -1
    )
      return null;

    if (solicitacaoProdutos.length === 0)
      return (
        <div className="resumo-informacoes">
          <h4>Produtos</h4>
          <i>Sem produtos</i>
          <hr />
        </div>
      );

    return (
      <div className="resumo-informacoes resumo-grid-produtos">
        <h4>Produtos</h4>
        {solicitacaoProdutos.map((solicitacaoProduto, index) => {
          return (
            <div className="row row-striped" key={index}>
              <div className="col-lg-2">
                <div className="row">
                  <div className="col-lg-12">
                    {solicitacaoProduto.urlImagem &&
                      solicitacaoProduto.urlImagem.length && (
                        <img
                          className="img-fluid"
                          src={solicitacaoProduto.urlImagem}
                        />
                      )}
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Código</label>
                    {solicitacaoProduto.produto.codigo}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label>Produto</label>
                    {solicitacaoProduto.produto.nome}
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Descrição no Encarte</label>
                    {solicitacaoProduto.descricao}
                  </div>
                </div>

                {(solicitacaoProduto.solicitacaoProdutoDetalhamentos || [])
                  .length > 0 && (
                    <div className="row">
                      <div className="col-lg-12">
                        <label>Detalhes</label>
                        {(
                          (
                            solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                            []
                          ).map((x) => {
                            return solicitacaoProduto.produto?.produtoDetalhamentos?.find(
                              (detalhamento) =>
                                detalhamento.produtoDetalhamentoId ===
                                x.produtoDetalhamentoId
                            );
                          }) || []
                        ).map((detalhamento, index) => {
                          return (
                            <span key={index} className="d-block">
                              {detalhamento?.descricao}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>

              <div className="col-lg-2">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Unidade</label>
                    {solicitacaoProduto.unidade}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label>Posição</label>
                    {solicitacaoProduto.posicao === 0
                      ? 'Frente'
                      : 'Verso'}
                  </div>
                </div>
              </div>

              <div className="col-lg-2">
                <div className="row">
                  {!solicitacaoProduto.oferta && (
                    <div className="col-lg-12">
                      <label>Preço</label>
                      <span className="produto-preco">
                        R$ {getStringFromPrice(solicitacaoProduto.preco)}
                      </span>
                    </div>
                  )}

                  {solicitacaoProduto.oferta && (
                    <div className="col-lg-12">
                      <label>Preço OFERTA</label>
                      <span className="produto-preco">
                        de R$ {getStringFromPrice(solicitacaoProduto.precoDe)}
                      </span>{' '}
                      <span className="produto-preco">
                        por R$ {getStringFromPrice(solicitacaoProduto.preco)}
                      </span>
                    </div>
                  )}
                </div>

                {solicitacaoProduto.destaque && (
                  <div className="row">
                    <div className="col-lg-12">
                      <label className="bg-primary text-white d-block text-center">
                        <i>Destacar</i>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <hr />
      </div>
    );
  };

  const [ico, setIcon] = useState(false);

  const mudarIcon = () => {
    if (ico === false) {
      setIcon(true);
    } else {
      setIcon(false);
    }
  };

  return (
    <div className="col-lg-12 resumo mb-4">
      <div className="resumo-header">
        <Accordion.Toggle
          onClick={mudarIcon}
          as={Card.Header}
          eventKey={accordionKey}
        >
          {solicitacao.solicitacaoId > 0 && (
            <span>Solicitação {solicitacao.solicitacaoId} - </span>
          )}{' '}
          {material?.nome}
          <div className="float-right">
            <FontAwesomeIcon
              id="icone"
              icon={ico !== true ? faAngleDown : faAngleUp}
            />
          </div>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={accordionKey}>
        <div className="resumo-body">
          {renderInformacoes()}

          {renderLayout()}

          {renderLogo()}

          {renderRodape()}

          {renderEntregaDigital()}

          {renderEntregaFisica()}

          {renderProdutos()}
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default ResumoMaterial;
