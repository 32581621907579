import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const ImpressosGenerico = (props) => {
  const { data, titulo } = props;

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const itens = Object.keys(data);
  const series = [{ data: itens.map((item) => data[item]) }];

  const options = {
    colors: ['#021f59'],
    plotOptions: {
      bar: {
        barHeight: '80%',
        horizontal: true,
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: itens.map((item) => item.toUpperCase()),
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max: Math.max(...itens.map((item) => data[item])) * 1.6,
      labels: {
        align: 'right',
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    grid: {
      row: {
        colors: ['#dcdcdc', 'transparent'],
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      width: '100%',
      parentHeightOffset: 0,
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'start',
      offsetX: 5,
      offsetY: 6,
      formatter: (value, { dataPointIndex }) => {
        return `${parseInt(data[itens[dataPointIndex]], 10).toLocaleString(
          'pt-BR'
        )}`;
      },
      style: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              ${titulo}: <span id="data">${itens[
          dataPointIndex
        ].toUpperCase()}</span>
            </span>
            <span>
              Qtd Impressos: <span id="data">${parseInt(
                data[itens[dataPointIndex]],
                10
              ).toLocaleString('pt-BR')}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="scrollable">
        <Chart
          options={options}
          series={series}
          height={itens.length * 17 + 100}
          type="bar"
        />
      </div>
    </div>
  );
};

export default ImpressosGenerico;
