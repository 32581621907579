/* eslint-disable */
import React, { useState, useContext } from 'react';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';

import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import { getStringFromDate, tabs } from '../_constants';
import './styles.scss';

function Resumo(props) {
  const { model, enviarSolicitacao, setActiveTab } = props;
  const [ico, setIcon] = useState(false);

  const mudarIcon = () => {
    if (ico === false) {
      setIcon(true);
    } else {
      setIcon(false);
    }
  };

  const resumoItem = (item, model) => {
    return (
      <div className="col-lg-12 resumo mb-4" key={item.materialComunicacaoId}>
        <div className="resumo-header">
          <Accordion.Toggle
            onClick={mudarIcon}
            as={Card.Header}
            eventKey={item.materialComunicacaoId}
          >
            {item.materialComunicacao.nome}
            <div className="float-right">
              <FontAwesomeIcon
                id="icone"
                icon={ico !== true ? faAngleDown : faAngleUp}
              ></FontAwesomeIcon>
            </div>
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey={item.materialComunicacaoId}>
          <div className="resumo-body">
            <div className="resumo-informacoes">
              <h4>Informações</h4>
              <div className="resumo-informacoes-dados">
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Solicitante</div>
                  <div className="col-lg-9 info-dados">{model?.solicitante?.nome}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Supervisor</div>
                  <div className="col-lg-9 info-dados">{model?.usuarioSuperior?.nome}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Regional</div>
                  <div className="col-lg-9 info-dados">{model.loja?.regional?.nome}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Rede</div>
                  <div className="col-lg-9 info-dados">{model.loja?.razaoSocial}</div>
                </div>
              </div>
            </div>
            <div className="resumo-informacoes">
              <h4>Layout</h4>
              <img
                src={model?.layoutArteFacil?.urlImagem}
                className="img-fluid"
              />
              <hr />
            </div>
            <div className="resumo-informacoes">
              <h4>Ação Promocional</h4>
              <div className="resumo-informacoes-dados">
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Início da Vigência</div>
                  <div className="col-lg-9 info-dados">
                    {getStringFromDate(
                      item.solicitacaoDados?.dataVeiculacaoInicio
                    )}
                  </div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Fim da Vigência</div>
                  <div className="col-lg-9 info-dados">
                    {getStringFromDate(
                      item?.solicitacaoDados?.dataVeiculacaoFim
                    )}
                  </div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Incluir logo?</div>
                  <div className="col-lg-9 info-dados">{item.solicitacaoDados?.possuiLogo ? 'Sim' : 'Não'}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Tamanhos</div>
                  <div className="col-lg-9 info-dados">
                    {item.solicitacaoTamanhos.map(
                      (tamanho) => `${tamanho.largura}x${tamanho.altura} `
                    )}
                  </div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Observação</div>
                  <div className="col-lg-9 info-dados">{item.solicitacaoDados?.observacoesGerais}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Incluir Ação Promocional?</div>
                  <div className="col-lg-9 info-dados">
                    {item.solicitacaoDados.possuiAcaoPromocional
                      ? 'Sim'
                      : 'Não'}
                  </div>
                </div>

                {item.solicitacaoAcaoPromocionais !== null &&
                  item.solicitacaoAcaoPromocionais.length > 0 &&
                  item.solicitacaoDados.possuiAcaoPromocional &&
                  item.solicitacaoAcaoPromocionais.map(
                    (acao, key) =>
                      acao.acaoPromocional?.texto?.length > 0 && (
                        <div key={key} className="row row-striped">
                          <div className="col-lg-3 header-dados"></div>
                          <div className="col-lg-9 info-dados">
                            {acao.acaoPromocional?.texto}
                            {'  '}
                            {acao.solicitacaoAcaoPromocionalValores &&
                              acao.solicitacaoAcaoPromocionalValores.length > 0 &&
                              acao.solicitacaoAcaoPromocionalValores.map(
                                (valor) =>
                                  `${valor.acaoPromocionalCampo.texto}: ${valor.valor && valor.valor
                                  } `
                              )}
                          </div>
                        </div>
                      )
                  )}
              </div>
            </div>
            <div className="resumo-informacoes">
              <h4>Dados de Envio</h4>
              <div className="resumo-informacoes-dados">
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Nome</div>
                  <div className="col-lg-9 info-dados">{item.solicitacaoEntregaDigital?.nomeResponsavel}</div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Telefone</div>
                  <div className="col-lg-9 info-dados">
                    {item.solicitacaoEntregaDigital?.telefoneResponsavel}
                  </div>
                </div>
                <div className="row row-striped">
                  <div className="col-lg-3 header-dados">Email</div>
                  <div className="col-lg-9 info-dados">{item.solicitacaoEntregaDigital?.emailResponsavel}</div>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    );
  };

  return (
    <div className="container content-pedido-resumo pb-5">
      <TitlePages title="Resumo" />
      <div className="row">
        <div className="col-lg-12">
          <Accordion>
            {model.solicitacoes &&
              model.solicitacoes.map((item) => resumoItem(item, model))}
          </Accordion>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 text-left">
          <button
            className="btn btn-primary btn-navigation"
            onClick={() => {
              // updateRascunho();
              setActiveTab(tabs.tabDadosAcao);
            }}
          >
            <span>
              <FaAngleLeft size={26} />
            </span>
            <span>VOLTAR</span>
          </button>
        </div>

        <div className="col-lg-6 d-flex flex-row-reverse">
          <button
            className={`btn btn-primary btn-navigation btn-navigation-right`}
            onClick={() => {
              enviarSolicitacao();
            }}
          >
            <span>FINALIZAR PEDIDO</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>

      <div className="d-flex align-items-end flex-column mt-5">

      </div>
    </div>
  );
}

export default Resumo;
