/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import SummarySolicitacao from './SummarySolicitacao';
import { GET_RESUMO_MODEL } from 'services/Consulta';
import actionLoader from 'store/modules/loader/actions';
import { appConst } from 'constants/appConstants';
import './SummarySolicitacao';

const Summary = (props) => {
  const userAuth = JSON.parse(localStorage.getItem(appConst.STORAGE_NAME));

  let solicitacaoId = props.solicitacaoId;
  let updateIsOwner = props.updateIsOwner;

  const dispatch = useDispatch();

  const [model, setModel] = useState({});

  useEffect(() => {
    (async () => {
      if (solicitacaoId && solicitacaoId > 0) {
        dispatch(actionLoader.showLoader(true));

        const data = await GET_RESUMO_MODEL(props.solicitacaoId);
        setModel(data?.value);

        if (updateIsOwner)
          updateIsOwner(data.value.pedido?.usuarioId === userAuth.userId);

        dispatch(actionLoader.showLoader(false));
      } else {
        setModel({});
      }
    })();
  }, [solicitacaoId]);

  if (!model.pedido) return null;

  return (
    <SummarySolicitacao pedido={model.pedido} solicitacao={model.solicitacao} />
  );
};

export default Summary;
