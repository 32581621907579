import React from 'react';

import './styles.scss';
import BrandWhite from 'assets/imgs/logo_branco.png';

const Brand = (props) => {
  const { white } = props;
  return <img className="brand" src={white ? BrandWhite : ''} alt="Marfrig" />;
};

export default Brand;
