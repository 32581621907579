import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentChamadoFinalizado from './ContentChamadoFinalizado';

const CalledSent = () => {
  return (
    <>
      <Header />
      <ContentChamadoFinalizado />
      <Bottom />
    </>
  );
};

export default CalledSent;
