import React from 'react';
import '../../styles.scss';

import {
  EvolucaoImpressos,
  EvolucaoCanal,
  ImpressosGenerico,
  MapChart,
  RegiaoImpresso,
} from '../Graficos/participacao';

const TabParticipacao = React.memo(
  (props) => {
    const { data } = props;

    return (
      <div className="container-tab">
        <div className="row-graficos">
          <EvolucaoImpressos data={data?.evolucao} />
        </div>
        <div className="row-graficos">
          <EvolucaoCanal data={data?.evolucaoCanal} />
        </div>
        <div className="row-graficos">
          <MapChart data={data?.uf} />
          <RegiaoImpresso data={data?.impressos.regional} />
        </div>
        <div className="row-graficos">
          <ImpressosGenerico
            titulo="Quantidade de Impressos por Soliciante"
            data={data?.impressos.solicitante}
          />
          <ImpressosGenerico
            titulo="Quantidade de Impressos por Rede"
            data={data?.impressos.rede}
          />
          {/* <ImpressosGenerico
          titulo="Quantidade de Impressos por Campanha"
          data={data?.impressos.campanha}
        /> */}
        </div>
        <div className="row-graficos">
          <ImpressosGenerico
            titulo="Quantidade de Impressos por Marca"
            data={data?.impressos.marca}
          />
          <ImpressosGenerico
            titulo="Quantidade de Impressos por Categoria"
            data={data?.impressos.categoria}
          />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default TabParticipacao;
