/* eslint-disable */
import React, { useEffect } from 'react';
import './styles.scss';
import DropDownFiltro from './DropDownFiltro';

const Filtro = (props) => {
  const {
    setFetchDataTrigger,
    filtro,
    filtroDisp,
    filtroSelecionado,
    setFiltroSelecionado,
    material,
  } = props;

  return (
    <div className="wrapper-filtro">
      <div className="filtro">
        <p>Ano</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="ano"
          filtro={filtro?.ano}
          filtroDisp={filtroDisp?.ano}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        ></DropDownFiltro>
      </div>
      <div className="filtro">
        <p>Mês</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="mes"
          filtro={filtro?.mes}
          filtroDisp={filtroDisp?.mes}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        ></DropDownFiltro>
      </div>
      <div className="filtro">
        <p>Regional</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="regiao"
          filtro={filtro?.regiao}
          filtroDisp={filtroDisp?.regiao}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        ></DropDownFiltro>
      </div>
      <div className="filtro">
        <p>Canal</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="canal"
          filtro={filtro?.canal}
          filtroDisp={filtroDisp?.canal}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        ></DropDownFiltro>
      </div>
      <div className="filtro">
        <p>Marca</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="marca"
          filtro={filtro?.marca}
          filtroDisp={filtroDisp?.marca}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        ></DropDownFiltro>
      </div>
      <div className="filtro">
        <p>Categoria</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="categoria"
          filtro={filtro?.categoria}
          filtroDisp={filtroDisp?.categoria}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        ></DropDownFiltro>
      </div>
      {material ? (
        <div className="filtro">
          <p>Material Comunicacao</p>
          <DropDownFiltro
            setFetchDataTrigger={setFetchDataTrigger}
            nome="material"
            filtro={filtro?.material}
            filtroDisp={filtroDisp?.material}
            selecao={filtroSelecionado}
            setSelecao={setFiltroSelecionado}
          ></DropDownFiltro>
        </div>
      ) : null}
    </div>
  );
};

export default Filtro;
