/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './styles.scss';
import TitlePages from 'components/_base/TitlePages';
import SubHeader from 'components/SubHeader';
import { getCampoObrigatorioClassName } from '../../_constants';

import InputTelefone from 'components/_base/InputTelefone';
import { GET_USUARIO } from 'services/Usuario';
import { Context } from 'context/AuthContext';
import { appConst } from 'constants/appConstants';

const EntregaDigital = (props) => {
  let { errors, renderErrors, avancar, voltar, entities, model } = props;
  const { showLoader } = useContext(Context);
  const userAuth = JSON.parse(localStorage.getItem(appConst.STORAGE_NAME));

  model.entregaDigital = model.entregaDigital || {};

  const [entregaDigital, setEntregaDigital] = useState(
    model.entregaDigital || {}
  );

  const updateEntregaDigital = (obj) => {
    model.entregaDigital = { ...obj };
    setEntregaDigital(model.entregaDigital);
  };

  useEffect(() => {
    setEntregaDigital(model.entregaDigital || {});
  }, [model]);

  const entregaTipoDigital = (entities.tipoEntregas || []).find(
    (tipoEntrega) => {
      return tipoEntrega.entregaDigital;
    }
  );

  const avancarSolicitacoes = () => {
    model.solicitacoes.forEach((solicitacao) => {
      if (!solicitacao.imprimir)
        solicitacao.entregaTipoId = entregaTipoDigital.entregaTipoId;
    });
    avancar();
  };

  const getUserData = async () => {
    showLoader(true);
    const data = await GET_USUARIO(userAuth.userId);
    entregaDigital.nome = data.value.nome;
    entregaDigital.telefone = data.value.telefone;
    entregaDigital.email = data.value.email;
    updateEntregaDigital(entregaDigital);
    showLoader(false);
  };

  const renderFields = () => {
    if (!model.entregaDigital) return null;

    return (
      <div className="row mb-5">
        <div className="col-lg-12 mb-3">
          <div>
            <label>Nome</label>
            <input
              type="text"
              className={`form-control ${getCampoObrigatorioClassName(
                entregaDigital.nome,
                true
              )}`}
              value={entregaDigital.nome || ''}
              onChange={(event) => {
                entregaDigital.nome = event.target.value;
                updateEntregaDigital(entregaDigital);
              }}
            />
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <div>
            <label>Telefone</label>
            <InputTelefone
              className={`${getCampoObrigatorioClassName(
                entregaDigital.telefone,
                true
              )}`}
              value={entregaDigital.telefone || ''}
              onChange={(value) => {
                entregaDigital.telefone = value;
                updateEntregaDigital(entregaDigital);
              }}
            />
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <div>
            <label>E-mail </label>
            <input
              type="text"
              placeholder=""
              className={`form-control ${getCampoObrigatorioClassName(
                entregaDigital.email,
                true
              )}`}
              value={entregaDigital.email || ''}
              onChange={(event) => {
                entregaDigital.email = event.target.value;
                updateEntregaDigital(entregaDigital);
              }}
            />
            <label style={{ fontSize: '.8rem' }}>
              {' '}
              Para mais de um e-mail, separe por ;{' '}
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="wrapper-content-pedido-entrega-digital">
      <div className="content-pedido-entrega-digital">
        <SubHeader title={model.loja?.razaoSocial} />

        <TitlePages title="Entrega de Materiais Digitais" />

        {renderErrors(errors)}

        <div className="row m-5">
          <div className="col-lg-12 text-center">
            <h4>
              Você selecionou materiais digitais e/ou sem impressão.
              <br />
              Preencha os dados para envio da Arte Final.
            </h4>
          </div>
        </div>

        <div className="row m-5">
          <div className="col-lg-12 text-center">
            <h4>
              SE FOR ENVIAR A ARTE FINAL PARA O CLIENTE , PREENCHA COM OS DADOS{' '}
              <br />
              DO CLIENTE, SE FOR ENVIAR PARA O SOLICITANTE{' '}
              <button
                class="btn btn-link"
                style={{ fontSize: '1.5rem' }}
                onClick={() => getUserData()}
              >
                CLIQUE AQUI
              </button>
            </h4>
          </div>
        </div>

        {renderFields()}

        <div className="row buttons">
          <div className="col ">
            <button
              className="btn btn-primary btn-navigation"
              onClick={() => voltar()}
            >
              <span>
                <FaAngleLeft size={26} />
              </span>
              <span>VOLTAR</span>
            </button>
          </div>

          <div className="col flex-row-reverse">
            <button
              className="btn btn-primary btn-navigation btn-navigation-right"
              onClick={() => avancarSolicitacoes()}
            >
              <span>AVANÇAR</span>
              <span>
                <FaAngleRight size={26} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntregaDigital;
