import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import PageTitle from 'components/_base/PageTitle';

import ContentDraft from './ContentDraft';

const Draft = () => {
  return (
    <>
      <HeaderApp />
      <div style={{ marginTop: '60px' }} />
      <PageTitle title="Rascunhos" />
      <hr />
      <ContentDraft />
      <Bottom />
    </>
  );
};

export default Draft;
