import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
// import lineBreaker from 'helpers/LineBreaker';
import SemDados from '../SemDados';

const marcasFixasArray = ['BASSI', 'MONTANA STEAKHOUSE', 'MONTANA'];

const EvolucaoMarca = (props) => {
  const { data } = props;
  const titulo = 'EVOLUÇÃO POR MARCA';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const marcas = Object.keys(data);
  const dataMarcasFixas = {};

  // filtrando marcas fixas
  marcas.forEach((marca) => {
    if (marcasFixasArray.includes(marca.toUpperCase())) {
      dataMarcasFixas[marca] = data[marca];
    }
  });

  const marcasFixas = Object.keys(dataMarcasFixas);
  const anos = Object.keys(dataMarcasFixas[marcasFixas[0]]);

  let maxValue = 1;

  const calculateAnnotation = (series) => {
    const tmpAnnotationPoints = [];

    marcasFixas.forEach((marca) => {
      if (anos.length < 2) return;
      const dataUltimoAno =
        series[anos.length - 1].data[marcasFixas.indexOf(marca)];
      const dataPenultimoAno =
        series[anos.length - 2].data[marcasFixas.indexOf(marca)];
      const variacaoMarca =
        ((dataUltimoAno - dataPenultimoAno) / dataPenultimoAno) * 100;
      const labelColor = variacaoMarca > 0 ? '#008000' : '#FF0000';

      tmpAnnotationPoints.push({
        x: marca,
        y: series[anos.length - 1].data[marcasFixas.indexOf(marca)],
        marker: {
          size: 0,
        },
        label: {
          borderColor: undefined,
          text: Number.isFinite(variacaoMarca)
            ? `${variacaoMarca > 0 ? '+' : ''}${variacaoMarca.toFixed(0)}% ${
                variacaoMarca > 0 ? '↑' : '↓'
              }`
            : 'N/A',
          textAnchor: 'start',
          offsetX: 0,
          offsetY: -25,
          style: {
            background: 'transparent',
            color: labelColor,
            fontSize: '0.9rem',
            fontWeight: 700,
          },
        },
      });
    });
    return tmpAnnotationPoints;
  };

  const calculateSeries = () => {
    if (anos.length === 0) {
      return [];
    }

    const tmpSeries = [];

    anos.forEach((ano) => {
      tmpSeries.push({
        name: ano,
        data: marcasFixas.map((marca) => {
          const qtdeAno = dataMarcasFixas[marca][ano];
          maxValue = Math.max(maxValue, qtdeAno);
          return qtdeAno;
        }),
      });
    });

    return tmpSeries;
  };

  const series = calculateSeries();

  const options = {
    annotations: {
      points: calculateAnnotation(series),
    },
    colors: ['#faa306', '#1374fb'],
    legend: {
      showForSingleSeries: true,
      formatter: (seriesName) => {
        return [seriesName.toUpperCase()];
      },
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      offsetX: 0,
      offsetY: 6,
    },
    plotOptions: {
      bar: {
        hideZeroBarsWhenGrouped: false,
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
        colors: {},
      },
    },
    stroke: {
      colors: ['transparent'],
      width: 4,
    },
    xaxis: {
      categories: [...marcasFixas],
      show: true,
      labels: {
        show: true,
        offsetY: 0,
        style: {
          fontWeight: 600,
        },
        formatter: (value) => {
          const lines = value.toUpperCase();
          return lines;
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      max: maxValue * 1.2,
      show: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      id: 'can-solicitacoes',
      parentHeightOffset: 5,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: -19,
      formatter: (value) => {
        return value === null ? '0' : `${value}`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Ano: <span id="data">${anos[seriesIndex]}</span>
            </span>
            <span>
              Marca: <span id="data">${marcasFixas[
                dataPointIndex
              ].toUpperCase()}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${
                series[seriesIndex][dataPointIndex]
              }</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="bar" height="300" />
    </div>
  );
};

export default EvolucaoMarca;
