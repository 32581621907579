const filterOptions = {
  ano: ['2022', '2023'],
  mes: [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ],
  regiao: [
    'Nulo',
    'Centro-Oeste',
    'Nordeste',
    'Sao Paulo Capital',
    'São Paulo Interior',
    'Sudeste',
    'Sul',
  ],
  canal: ['N/A', 'Atacado', 'Food & Varejo'],
  marca: [
    'Nulo',
    'BASSI',
    'BASSI ANGUS',
    'BASSI STARS',
    'MONTANA',
    'MONTANA STEAKHOUSE',
    'PAMPEANO (ENLATADOS)',
    'PLANT PLUS',
    'TACUARIMBO (IMPORTADOS)',
  ],
  categoria: ['IN NATURA', 'INDUSTRIALIZADOS', 'PALATARE IN NATURA'],
  material: [
    'Anuncio 1 pagina',
    'BOX 1/2 pag',
    'BOX Pag Inteira',
    'EXCLUSIVO',
    'POST FEED ANIMADO',
    'POST FEED ESTATICO',
    'POST STORIES ANIMADO',
    'POST STORIES ESTATICO',
    'WHATSAPP ANIMADO',
    'WHATSAPP ESTATICO',
  ],
};

const sampleData = {
  filtro: {
    filtroSelecionado: {
      ano: ['2023', '2022'],
      mes: [
        'agosto 2023',
        'maio 2023',
        'abril 2023',
        'março 2023',
        'fevereiro 2023',
        'janeiro 2023',
        'dezembro 2022',
        'novembro 2022',
        'outubro 2022',
        'setembro 2022',
        'agosto 2022',
        'julho 2022',
        'junho 2022',
        'maio 2022',
        'abril 2022',
        'março 2022',
      ],
      regiao: [
        null,
        'CENTRO OESTE',
        'SÃO PAULO CAPITAL',
        'SÃO PAULO INTERIOR',
        'SUDESTE',
        'SUL',
      ],
      canal: [null, 'ATACADO', 'FOOD & VAREJO'],
      marca: [
        null,
        'BASSI',
        'BASSI ANGUS',
        'MONTANA',
        'MONTANA STEAKHOUSE',
        'PLANT PLUS',
      ],
      categoria: [null, 'IN NATURA', 'INDUSTRIALIZADOS'],
      material: [
        'BOX 1/2 Pág.',
        'BOX Pág. Inteira',
        'EXCLUSIVO',
        'POST',
        'POST FEED ANIMADO',
        'POST FEED ESTÁTICO',
        'POST STORIES ANIMADO',
        'POST STORIES ESTÁTICO',
        'WHATSAPP ANIMADO',
        'WHATSAPP ESTÁTICO',
      ],
    },
    filtroInicial: null,
    filtroDisponivel: {
      ano: ['2023', '2022'],
      mes: [
        'agosto 2023',
        'maio 2023',
        'abril 2023',
        'março 2023',
        'fevereiro 2023',
        'janeiro 2023',
        'dezembro 2022',
        'novembro 2022',
        'outubro 2022',
        'setembro 2022',
        'agosto 2022',
        'julho 2022',
        'junho 2022',
        'maio 2022',
        'abril 2022',
        'março 2022',
      ],
      regiao: [
        null,
        'CENTRO OESTE',
        'SÃO PAULO CAPITAL',
        'SÃO PAULO INTERIOR',
        'SUDESTE',
        'SUL',
      ],
      canal: [null, 'ATACADO', 'FOOD & VAREJO'],
      marca: [
        null,
        'BASSI',
        'BASSI ANGUS',
        'MONTANA',
        'MONTANA STEAKHOUSE',
        'PLANT PLUS',
      ],
      categoria: [null, 'IN NATURA', 'INDUSTRIALIZADOS'],
      material: [
        'BOX 1/2 Pág.',
        'BOX Pág. Inteira',
        'EXCLUSIVO',
        'POST',
        'POST FEED ANIMADO',
        'POST FEED ESTÁTICO',
        'POST STORIES ANIMADO',
        'POST STORIES ESTÁTICO',
        'WHATSAPP ANIMADO',
        'WHATSAPP ESTÁTICO',
      ],
    },
  },
  data: {
    total: {
      solicitacoes: 486,
      solicitacoesAprovadas: 480,
      artesAprovadas: 472,
      qtdeImpressoes: 397001,
    },
    produtividade: {
      evolucao: {
        2022: [null, null, 4, 2, 7, 30, 32, 66, 41, 27, 34, 22],
        2023: [30, 97, 62, 28, 1, null, null, 3, null, null, null, null],
      },
      uf: {
        rj: '6,58',
        sp: '21,81',
        mt: '0,41',
        mg: '0,82',
        nulo: '69,14',
        rs: '1,03',
        ms: '0,21',
      },
      regional: {
        nulo: {
          quantidade: 336,
          percentual: 100,
        },
        'são PAULO CAPITAL': {
          quantidade: 60,
          percentual: 100,
        },
        'são PAULO INTERIOR': {
          quantidade: 46,
          percentual: 100,
        },
        sudeste: {
          quantidade: 36,
          percentual: 100,
        },
        sul: {
          quantidade: 5,
          percentual: 100,
        },
        'centro OESTE': {
          quantidade: 3,
          percentual: 100,
        },
      },
      rede: {
        'tenda ATACADO': {
          quantidade: 67,
          percentual: 100,
        },
        'marfrig GLOBAL FOODS': {
          quantidade: 28,
          percentual: 100,
        },
        nulo: {
          quantidade: 21,
          percentual: 100,
        },
        'grupo SPANI': {
          quantidade: 17,
          percentual: 100,
        },
        'tennessee CUBATAO LTDA': {
          quantidade: 15,
          percentual: 100,
        },
        'sup PRECO IDEAL': {
          quantidade: 14,
          percentual: 100,
        },
        'rede VILLA ZAIRA': {
          quantidade: 13,
          percentual: 100,
        },
        'dinamica REAL DISTRIB PROD ALIM LTDA': {
          quantidade: 12,
          percentual: 100,
        },
        'turquette COM DE ALIM LTDA ME': {
          quantidade: 8,
          percentual: 100,
        },
        cooper: {
          quantidade: 8,
          percentual: 100,
        },
        'irm BOA LTDA': {
          quantidade: 7,
          percentual: 100,
        },
        'sup PINHEIRAO': {
          quantidade: 7,
          percentual: 100,
        },
        'rede MAKRO': {
          quantidade: 7,
          percentual: 100,
        },
        'rede SUPERMARKET': {
          quantidade: 7,
          percentual: 100,
        },
        'maravista COM ALIM LTDA': {
          quantidade: 6,
          percentual: 100,
        },
        'nicolau MAX SUP LTDA': {
          quantidade: 6,
          percentual: 100,
        },
        'sup BARRACAO': {
          quantidade: 6,
          percentual: 100,
        },
        'maria FATIMA A OLIVEIRA': {
          quantidade: 6,
          percentual: 100,
        },
        'r P STO COM GENEROS': {
          quantidade: 6,
          percentual: 100,
        },
        'rede - SUP PASTORINHO': {
          quantidade: 6,
          percentual: 100,
        },
        'furlan & Piola LTDA': {
          quantidade: 5,
          percentual: 100,
        },
        'beef SMART LTDA': {
          quantidade: 5,
          percentual: 100,
        },
        'bjp COM DIST ALIMENTOS LTDA': {
          quantidade: 5,
          percentual: 100,
        },
        'grupo - SUP JIRE': {
          quantidade: 5,
          percentual: 100,
        },
        'maby SUP LTDA': {
          quantidade: 5,
          percentual: 100,
        },
        'joao DANIEL RODRIGUES TURQUETTE': {
          quantidade: 5,
          percentual: 100,
        },
        'la MACELLARIA': {
          quantidade: 4,
          percentual: 100,
        },
        'sacolao SAO JORGE': {
          quantidade: 4,
          percentual: 100,
        },
        'jmw FOODS': {
          quantidade: 4,
          percentual: 100,
        },
        'comercial ESPERANCA': {
          quantidade: 4,
          percentual: 100,
        },
        'rede PERUZZO': {
          quantidade: 4,
          percentual: 100,
        },
        'sto ATACADISTA DE ALIMENTOS': {
          quantidade: 4,
          percentual: 100,
        },
        'grupo - COLAO O JORGE': {
          quantidade: 4,
          percentual: 100,
        },
        'rede - W M TANNOUS': {
          quantidade: 4,
          percentual: 100,
        },
        'bar ALCIONE EIRELI': {
          quantidade: 4,
          percentual: 100,
        },
        'recobase COMERCIAL LTDA': {
          quantidade: 4,
          percentual: 100,
        },
        'vendramini & FILHO LTDA': {
          quantidade: 4,
          percentual: 100,
        },
        'com DE ALIM CAETANO GUARAREMA LTDA': {
          quantidade: 3,
          percentual: 100,
        },
        'center SHOP': {
          quantidade: 3,
          percentual: 100,
        },
        'iotti GRIFFE DA CARNE': {
          quantidade: 3,
          percentual: 100,
        },
        'supermercado PAGUE MENOS LTDA': {
          quantidade: 3,
          percentual: 100,
        },
        'sup TAQUARAL LTDA': {
          quantidade: 3,
          percentual: 100,
        },
        'pague MENOS': {
          quantidade: 3,
          percentual: 100,
        },
        'rede - SUPERBOM': {
          quantidade: 3,
          percentual: 100,
        },
        'grupo - TENNESSEE': {
          quantidade: 3,
          percentual: 100,
        },
        'rede - MOHAMED': {
          quantidade: 3,
          percentual: 100,
        },
        'rede - FURLAN': {
          quantidade: 3,
          percentual: 100,
        },
        'floriano PAULISTA COMERCIO ALIM ': {
          quantidade: 3,
          percentual: 100,
        },
        'rede - SUP ECONOMICO JJ': {
          quantidade: 3,
          percentual: 100,
        },
        'rede - SUP PORTO SEGURO': {
          quantidade: 3,
          percentual: 100,
        },
        'hortifruti DO BAIRRO LTDA': {
          quantidade: 3,
          percentual: 100,
        },
        'dias Pastorinho S A Comercio e Industria': {
          quantidade: 2,
          percentual: 100,
        },
        'com DE GEN ALIM PARANA LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        'sup CODEBAL LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        hirota: {
          quantidade: 2,
          percentual: 100,
        },
        'irm FLORENTINOS CEREAIS LTDA ME': {
          quantidade: 2,
          percentual: 100,
        },
        'sup JAU SERVE LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        'jmi BAR E RESTAURANTE EIRELI': {
          quantidade: 2,
          percentual: 100,
        },
        'rede PINHEIRAO': {
          quantidade: 2,
          percentual: 100,
        },
        'sacolao SAUDE': {
          quantidade: 2,
          percentual: 100,
        },
        'mercad LESSA LTDA ME': {
          quantidade: 2,
          percentual: 100,
        },
        'peg E PESE COM DE HORTIFRUTIG LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        'sup BATISTA 2012 LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        'lima SILVA COM ALIMENTOS LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        'du frade Du Frade Mercadinho LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        'fnh JK RESTAURANTE ': {
          quantidade: 2,
          percentual: 100,
        },
        'rede - SUP ELIAS MOREIRA': {
          quantidade: 2,
          percentual: 100,
        },
        'rede - DISTR BEBIDAS MAITAN': {
          quantidade: 2,
          percentual: 100,
        },
        'rede - COML ZAFFARI': {
          quantidade: 2,
          percentual: 100,
        },
        'grupo - HORTIFRUTIGRANJEIRO': {
          quantidade: 2,
          percentual: 100,
        },
        'rede - SUP MEDEIROS': {
          quantidade: 2,
          percentual: 100,
        },
        'rede - MERCEARIA R A MARAVILHA': {
          quantidade: 2,
          percentual: 100,
        },
        angeloni: {
          quantidade: 2,
          percentual: 100,
        },
        'altair MEIRELES SILVA CIA LTDA ME': {
          quantidade: 2,
          percentual: 100,
        },
        'w C A COM DE ALIM LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        'fhe FERREIRA C P ALIM SERVICOS LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        'mercearia FANTASTIC LTDA': {
          quantidade: 2,
          percentual: 100,
        },
        'mini MERC BOA SORTE JACONE LTDA ME': {
          quantidade: 2,
          percentual: 100,
        },
        'ofelia SARRI MORETE': {
          quantidade: 2,
          percentual: 100,
        },
        'supermercado SUPERPOUPE EIRELI': {
          quantidade: 1,
          percentual: 100,
        },
        'flor DA POSSE': {
          quantidade: 1,
          percentual: 100,
        },
        'jp COML PROD ALIM LTDA ME': {
          quantidade: 1,
          percentual: 100,
        },
        'rede MARINGA': {
          quantidade: 1,
          percentual: 100,
        },
        'rede HORT MAIS HORTIFRUTI LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'w P MERC PAD LTDA ME': {
          quantidade: 1,
          percentual: 100,
        },
        'xancarnes COM DE ALIM': {
          quantidade: 1,
          percentual: 100,
        },
        'natural DA TERRA': {
          quantidade: 1,
          percentual: 100,
        },
        'grupo NAGUMO': {
          quantidade: 1,
          percentual: 100,
        },
        'distr DE BEBIDAS MAITAN LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'gigantao SUPERMERCADOS LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'sup GALASSI LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'e P FILHO C DISTR CARNES P A LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'rede - SUP COMPRE CERTO': {
          quantidade: 1,
          percentual: 100,
        },
        'rede - HIROTA': {
          quantidade: 1,
          percentual: 100,
        },
        'rede - BARRACAO SUP': {
          quantidade: 1,
          percentual: 100,
        },
        'panificadora CONFEITARIA NOVA EUR C ': {
          quantidade: 1,
          percentual: 100,
        },
        'rede - REDE LOCAL': {
          quantidade: 1,
          percentual: 100,
        },
        'frosinone PARTICIPACOES ': {
          quantidade: 1,
          percentual: 100,
        },
        'formello COM ALIM FAST FOOD  EPP': {
          quantidade: 1,
          percentual: 100,
        },
        'rede - EMPORIUM LEAO': {
          quantidade: 1,
          percentual: 100,
        },
        'rede - INFANGER & CIA': {
          quantidade: 1,
          percentual: 100,
        },
        'fnh PH RESTAURANTE ': {
          quantidade: 1,
          percentual: 100,
        },
        'bar E RESTAURANTE MONTE GORDO ': {
          quantidade: 1,
          percentual: 100,
        },
        'rede - CASA BARROS ALIM': {
          quantidade: 1,
          percentual: 100,
        },
        'grupo - COM MARABEL': {
          quantidade: 1,
          percentual: 100,
        },
        'grupo - KOSMOS': {
          quantidade: 1,
          percentual: 100,
        },
        'rede - COML 3D                          ': {
          quantidade: 1,
          percentual: 100,
        },
        'internacional REST BRASIL ': {
          quantidade: 1,
          percentual: 100,
        },
        'rede - 3 IRMAOS COM ALIM': {
          quantidade: 1,
          percentual: 100,
        },
        'coml ZAFFARI LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'rede ZAFFARI': {
          quantidade: 1,
          percentual: 100,
        },
        'sup GRICKI': {
          quantidade: 1,
          percentual: 100,
        },
        'dario NEVES & CIA LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'com DE PRODUTOS ALIMENT FREGNI LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'oxan ATACADISTA LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'emporio FARM COMERCIO DE ALIMENTOS LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'nilton J DE ANDRADE ME': {
          quantidade: 1,
          percentual: 100,
        },
        'irm QUEVEDO LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'carlos REIS DIAS ME': {
          quantidade: 1,
          percentual: 100,
        },
        'vf BOUTIQUE CARNES ESP BEBIDAS LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'entreposto D CARNES DANIELLA LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'j M CARVALHO MERCEARIA LTDA EPP': {
          quantidade: 1,
          percentual: 100,
        },
        'nl FONTES DIST PROD ALIMENT LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'mercado RIO SUL DE CAMPO GRANDE EIRELI': {
          quantidade: 1,
          percentual: 100,
        },
        'merc SUPER COMPRAS GOUVEIA LTDA': {
          quantidade: 1,
          percentual: 100,
        },
        'supermercado CATIARA LTDA': {
          quantidade: 1,
          percentual: 100,
        },
      },
      marca: {
        'montana STEAKHOUSE': {
          quantidade: 152,
          percentual: 31.28,
        },
        bassi: {
          quantidade: 139,
          percentual: 28.6,
        },
        montana: {
          quantidade: 98,
          percentual: 20.16,
        },
        nulo: {
          quantidade: 91,
          percentual: 18.72,
        },
        'bassi ANGUS': {
          quantidade: 3,
          percentual: 0.62,
        },
        'plant PLUS': {
          quantidade: 3,
          percentual: 0.62,
        },
      },
      canal: {
        atacado: {
          percentual: 1.65,
          data: {
            2022: [
              null,
              null,
              null,
              null,
              null,
              2,
              null,
              null,
              null,
              1,
              null,
              null,
            ],
            2023: [
              null,
              2,
              3,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
          },
        },
        'food & VAREJO': {
          percentual: 29.22,
          data: {
            2022: [null, null, null, null, 2, 2, 10, 16, 4, 4, null, 3],
            2023: [null, 47, 33, 17, 1, null, null, 3, null, null, null, null],
          },
        },
        nulo: {
          percentual: 69.14,
          data: {
            2022: [null, null, 4, 2, 5, 26, 22, 50, 37, 22, 34, 19],
            2023: [
              30,
              48,
              26,
              11,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
          },
        },
      },
      ranking: {
        rede: {
          'tenda ATACADO': {
            quantidade: 67,
            percentual: 100,
          },
          'marfrig GLOBAL FOODS': {
            quantidade: 28,
            percentual: 100,
          },
          nulo: {
            quantidade: 21,
            percentual: 100,
          },
          'grupo SPANI': {
            quantidade: 17,
            percentual: 100,
          },
          'tennessee CUBATAO LTDA': {
            quantidade: 15,
            percentual: 100,
          },
          'sup PRECO IDEAL': {
            quantidade: 14,
            percentual: 100,
          },
          'rede VILLA ZAIRA': {
            quantidade: 13,
            percentual: 100,
          },
          'dinamica REAL DISTRIB PROD ALIM LTDA': {
            quantidade: 12,
            percentual: 100,
          },
          'turquette COM DE ALIM LTDA ME': {
            quantidade: 8,
            percentual: 100,
          },
          cooper: {
            quantidade: 8,
            percentual: 100,
          },
        },
        solicitante: {
          'jefferson THOMAZ DE LIMA': {
            quantidade: 133,
            percentual: 100,
          },
          'elaine FERREIRA GAEFK': {
            quantidade: 35,
            percentual: 100,
          },
          'anderson GIOVANI FERREIRA BOLTER': {
            quantidade: 31,
            percentual: 100,
          },
          'gabriel DOS SANTOS GARCIA': {
            quantidade: 29,
            percentual: 100,
          },
          'nilson BATISTA BRITO DE SOUZA': {
            quantidade: 25,
            percentual: 100,
          },
          'karem CRISTINA PEREIRA FERRO': {
            quantidade: 24,
            percentual: 100,
          },
          'diego DE OLIVEIRA FARIA': {
            quantidade: 18,
            percentual: 100,
          },
          'elias FERREIRA DE MENDONCA': {
            quantidade: 17,
            percentual: 100,
          },
          'valter ROCHA COELHO JUNIOR': {
            quantidade: 16,
            percentual: 100,
          },
          'jose HUGO FIQUENE RAMOS': {
            quantidade: 16,
            percentual: 100,
          },
        },
        produto: {
          nulo: {
            quantidade: 91,
            percentual: 100,
          },
          'picanha RF VC MI MONTANA STEAKHOUSE': {
            quantidade: 48,
            percentual: 100,
          },
          'file DE COSTELA (BIFE ANCHO) S/CP S/A RF BT MI BASSI': {
            quantidade: 41,
            percentual: 100,
          },
          'fralda C/ GORDURA RF BT MI BASSI': {
            quantidade: 29,
            percentual: 100,
          },
          'coracao DA ALCATRA C/GORD S/RL AM (BABY BEEF) 1/1,6kg RF VC MI MONTANA STEAKHOUSE':
            {
              quantidade: 26,
              percentual: 100,
            },
          'contrafile (BIFE DE CHORIZO) S/C S/A RF BT MI BASSI': {
            quantidade: 20,
            percentual: 100,
          },
          'file DE COSTELA S/CP C/A 0,8/1,4kg RF VC MI MONTANA STEAKHOUSE ': {
            quantidade: 20,
            percentual: 100,
          },
          'maminha DA ALCATRA C/ GORD RF BT MI BASSI': {
            quantidade: 14,
            percentual: 100,
          },
          'peito C/GORD 1/1,3kg RF VC MI MONTANA': {
            quantidade: 14,
            percentual: 100,
          },
          'alcatra COM MAMINHA C/RL RF VC MI MONTANA': {
            quantidade: 13,
            percentual: 100,
          },
        },
        categoria: {
          'in NATURA': {
            quantidade: 331,
            percentual: 100,
          },
          nulo: {
            quantidade: 91,
            percentual: 100,
          },
          industrializados: {
            quantidade: 64,
            percentual: 100,
          },
        },
        material: {
          exclusivo: {
            quantidade: 98,
            percentual: 100,
          },
          'post FEED ESTÁTICO': {
            quantidade: 83,
            percentual: 100,
          },
          'post STORIES ESTÁTICO': {
            quantidade: 82,
            percentual: 100,
          },
          'post FEED ANIMADO': {
            quantidade: 56,
            percentual: 100,
          },
          post: {
            quantidade: 51,
            percentual: 100,
          },
          'post STORIES ANIMADO': {
            quantidade: 31,
            percentual: 100,
          },
          'box Pág. Inteira': {
            quantidade: 28,
            percentual: 100,
          },
          'whatsapp ESTÁTICO': {
            quantidade: 23,
            percentual: 100,
          },
          'whatsapp ANIMADO': {
            quantidade: 22,
            percentual: 100,
          },
          'box 1/2 Pág.': {
            quantidade: 12,
            percentual: 100,
          },
        },
        solicitanteVarejo: {
          'karem CRISTINA PEREIRA FERRO': {
            quantidade: 112,
            percentual: 100,
          },
          'jefferson THOMAZ DE LIMA': {
            quantidade: 76,
            percentual: 100,
          },
          'joao PAULO DA SILVA SANTOS': {
            quantidade: 58,
            percentual: 100,
          },
          'diego DE OLIVEIRA FARIA': {
            quantidade: 19,
            percentual: 100,
          },
          'joao VITOR FERREIRA DOS SANTOS': {
            quantidade: 18,
            percentual: 100,
          },
          'valter ROCHA COELHO JUNIOR': {
            quantidade: 17,
            percentual: 100,
          },
          'eduardo ALESSANDRO MARTINS SILVA': {
            quantidade: 11,
            percentual: 100,
          },
          'carlos DANIEL SANTOS COSTA': {
            quantidade: 11,
            percentual: 100,
          },
          'elias FERREIRA DE MENDONCA': {
            quantidade: 9,
            percentual: 100,
          },
          'gabriel DOS SANTOS GARCIA': {
            quantidade: 9,
            percentual: 100,
          },
        },
        solicitanteAtacado: {
          'lucio LEMOS': {
            quantidade: 41,
            percentual: 100,
          },
          'joao VITOR FERREIRA DOS SANTOS': {
            quantidade: 3,
            percentual: 100,
          },
          'elaine FERREIRA GAEFK': {
            quantidade: 1,
            percentual: 100,
          },
        },
      },
    },
    custo: {
      evolucao: {
        2022: [
          null,
          null,
          720,
          360,
          1963,
          17142,
          31821,
          65638,
          32581,
          33994,
          45240,
          6460,
        ],
        2023: [
          6401,
          52045,
          23358,
          9217,
          123,
          null,
          null,
          820,
          null,
          null,
          null,
          null,
        ],
      },
      uf: {
        rj: '1,99',
        sp: '12,12',
        mt: '0,10',
        mg: '0,15',
        nulo: '85,43',
        rs: '0,16',
        ms: '0,05',
      },
      regional: {
        nulo: '280115',
        'são PAULO CAPITAL': '31030',
        'são PAULO INTERIOR': '8707',
        sudeste: '6998',
        sul: '540',
        'centro OESTE': '500',
      },
      solicitacoes: {
        rede: {
          'grupo SPANI': 79386,
          hirota: 36551,
          'tenda ATACADO': 24509,
          'comercial ESPERANCA': 24405,
          'rede MAKRO': 20992,
          'rede - HIROTA': 19495,
          'fhe FERREIRA C P ALIM SERVICOS LTDA': 14044,
          'natural DA TERRA': 12375,
          'rede PERUZZO': 7604,
          'lima SILVA COM ALIMENTOS LTDA': 6989,
          'rede SUPERMARKET': 5723,
          'marfrig GLOBAL FOODS': 5135,
          'center SHOP': 4650,
          nulo: 4540,
          'sup PRECO IDEAL': 3060,
          'rede VILLA ZAIRA': 2820,
          'tennessee CUBATAO LTDA': 2700,
          'sacolao SAO JORGE': 2377,
          'sup CODEBAL LTDA': 2126,
          'dinamica REAL DISTRIB PROD ALIM LTDA': 1799,
          'grupo NAGUMO': 1510,
          'r P STO COM GENEROS': 1440,
          'maria FATIMA A OLIVEIRA': 1380,
          'bar ALCIONE EIRELI': 1303,
          'irm BOA LTDA': 1260,
          'rede - SUP PASTORINHO': 1260,
          'maravista COM ALIM LTDA': 1023,
          'bjp COM DIST ALIMENTOS LTDA': 1020,
          'sup BARRACAO': 1000,
          'sup PINHEIRAO': 979,
          'beef SMART LTDA': 960,
          'nicolau MAX SUP LTDA': 940,
          'turquette COM DE ALIM LTDA ME': 899,
          'joao DANIEL RODRIGUES TURQUETTE': 839,
          'jmw FOODS': 780,
          'furlan & Piola LTDA': 779,
          'grupo - SUP JIRE': 779,
          'sto ATACADISTA DE ALIMENTOS': 720,
          'rede - W M TANNOUS': 720,
          'grupo - TENNESSEE': 720,
          'recobase COMERCIAL LTDA': 719,
          'iotti GRIFFE DA CARNE': 660,
          'sup TAQUARAL LTDA': 660,
          'rede - SUPERBOM': 660,
          'rede - SUP ECONOMICO JJ': 660,
          'grupo - COLAO O JORGE': 599,
          'rede - FURLAN': 540,
          'rede - SUP PORTO SEGURO': 540,
          'maby SUP LTDA': 539,
          'com DE ALIM CAETANO GUARAREMA LTDA': 520,
          'supermercado PAGUE MENOS LTDA': 520,
          cooper: 479,
          'la MACELLARIA': 479,
          'floriano PAULISTA COMERCIO ALIM ': 467,
          'hortifruti DO BAIRRO LTDA': 455,
          'pague MENOS': 435,
          'jmi BAR E RESTAURANTE EIRELI': 420,
          'mercad LESSA LTDA ME': 420,
          'altair MEIRELES SILVA CIA LTDA ME': 420,
          'mini MERC BOA SORTE JACONE LTDA ME': 420,
          'du frade Du Frade Mercadinho LTDA': 400,
          'rede - MERCEARIA R A MARAVILHA': 395,
          'sup BATISTA 2012 LTDA': 380,
          'dias Pastorinho S A Comercio e Industria': 360,
          'com DE GEN ALIM PARANA LTDA': 360,
          'sacolao SAUDE': 360,
          'peg E PESE COM DE HORTIFRUTIG LTDA': 360,
          'rede - SUP ELIAS MOREIRA': 360,
          'rede - COML ZAFFARI': 360,
          'grupo - HORTIFRUTIGRANJEIRO': 360,
          'rede - SUP MEDEIROS': 360,
          angeloni: 360,
          'vendramini & FILHO LTDA': 359,
          'rede PINHEIRAO': 320,
          'irm FLORENTINOS CEREAIS LTDA ME': 311,
          'sup JAU SERVE LTDA': 311,
          'fnh JK RESTAURANTE ': 311,
          'rede - DISTR BEBIDAS MAITAN': 299,
          'mercearia FANTASTIC LTDA': 299,
          'ofelia SARRI MORETE': 299,
          'sup GALASSI LTDA': 240,
          'e P FILHO C DISTR CARNES P A LTDA': 240,
          'rede - SUP COMPRE CERTO': 240,
          'panificadora CONFEITARIA NOVA EUR C ': 240,
          'rede - REDE LOCAL': 240,
          'rede - EMPORIUM LEAO': 240,
          'rede - INFANGER & CIA': 240,
          'rede - CASA BARROS ALIM': 240,
          'oxan ATACADISTA LTDA': 240,
          'nilton J DE ANDRADE ME': 240,
          'irm QUEVEDO LTDA': 240,
          'carlos REIS DIAS ME': 240,
          'vf BOUTIQUE CARNES ESP BEBIDAS LTDA': 240,
          'entreposto D CARNES DANIELLA LTDA': 240,
          'nl FONTES DIST PROD ALIMENT LTDA': 240,
          'supermercado CATIARA LTDA': 240,
          'w C A COM DE ALIM LTDA': 239,
          'supermercado SUPERPOUPE EIRELI': 180,
          'rede HORT MAIS HORTIFRUTI LTDA': 180,
          'w P MERC PAD LTDA ME': 180,
          'distr DE BEBIDAS MAITAN LTDA': 180,
          'gigantao SUPERMERCADOS LTDA': 180,
          'bar E RESTAURANTE MONTE GORDO ': 180,
          'grupo - COM MARABEL': 180,
          'rede - COML 3D                          ': 180,
          'internacional REST BRASIL ': 180,
          'coml ZAFFARI LTDA': 180,
          'rede ZAFFARI': 180,
          'sup GRICKI': 180,
          'emporio FARM COMERCIO DE ALIMENTOS LTDA': 180,
          'j M CARVALHO MERCEARIA LTDA EPP': 180,
          'rede - MOHAMED': 179,
          'flor DA POSSE': 155,
          'jp COML PROD ALIM LTDA ME': 155,
          'rede MARINGA': 155,
          'xancarnes COM DE ALIM': 155,
          'frosinone PARTICIPACOES ': 155,
          'formello COM ALIM FAST FOOD  EPP': 155,
          'fnh PH RESTAURANTE ': 155,
          'grupo - KOSMOS': 155,
          'mercado RIO SUL DE CAMPO GRANDE EIRELI': 155,
          'merc SUPER COMPRAS GOUVEIA LTDA': 155,
          'rede - BARRACAO SUP': 140,
          'rede - 3 IRMAOS COM ALIM': 140,
          'dario NEVES & CIA LTDA': 59,
          'com DE PRODUTOS ALIMENT FREGNI LTDA': 59,
        },
        marca: {
          'montana STEAKHOUSE': 136978,
          bassi: 128411,
          montana: 46931,
          nulo: 12518,
          'plant PLUS': 2493,
          'bassi ANGUS': 555,
        },
        canal: {
          nulo: 280115,
          'food & VAREJO': 46271,
          atacado: 1503,
        },
        solicitante: {
          'jefferson THOMAZ DE LIMA': 172033,
          'valter ROCHA COELHO JUNIOR': 58687,
          'mayara THAISE DA SILVA SERAFIM': 12375,
          'anderson GIOVANI FERREIRA BOLTER': 9860,
          'camila DUARTE DE OLIVEIRA LINO': 9236,
          'elaine FERREIRA GAEFK': 7740,
          'marco ANTONIO DOS SANTOS': 6989,
          'gabriel DOS SANTOS GARCIA': 4699,
          'nilson BATISTA BRITO DE SOUZA': 4560,
          'karem CRISTINA PEREIRA FERRO': 4395,
          'elias FERREIRA DE MENDONCA': 4393,
          'jose HUGO FIQUENE RAMOS': 3440,
          'diego DE OLIVEIRA FARIA': 3119,
          'eduardo ALESSANDRO MARTINS SILVA': 2939,
          'julino FERREIRA OLIVEIRA NETO': 2820,
          'joao VITOR FERREIRA DOS SANTOS': 2460,
          'carlos DANIEL SANTOS COSTA': 2220,
          'bruna SIQUEIRA CARDOSO ': 2159,
          'joao PAULO DA SILVA SANTOS': 2123,
          'lucio LEMOS': 1923,
          'gabriel DO CARMO OLIVEIRA': 1499,
          'vend TESTE': 1443,
          'carlos EDUARDO ZELAUHI ALVES ANJOS': 935,
          'roberto PACHECO MOERBECK FILHO': 827,
          'ieda SABRINA DA COSTA GOMES': 820,
          'rodrigo AVELAR DE OLIVEIRA': 720,
          'rosemeire ALMEIDA SILVA': 540,
          'claudia APARECIDA FARIAS': 520,
          'adriana CRIVELARO DA FONSECA': 479,
          'leonardo DIAS DOS SANTOS': 471,
          'rodolfo LUIZ BATISTA DE SOUSA': 420,
          'claudio ROBERTO DE LIMA MELO': 395,
          'ademir DA ROCHA MEDEIROS': 320,
          'andreia DE FARIA': 320,
        },
        categoria: {
          'in NATURA': 262927,
          industrializados: 52443,
          nulo: 12518,
        },
        material: {
          exclusivo: 257031,
          'post FEED ESTÁTICO': 15260,
          'post STORIES ESTÁTICO': 14760,
          'post FEED ANIMADO': 13700,
          'post STORIES ANIMADO': 7440,
          'whatsapp ANIMADO': 5280,
          'box Pág. Inteira': 4480,
          'whatsapp ESTÁTICO': 4140,
          post: 4135,
          'box 1/2 Pág.': 1663,
        },
      },
    },
    participacao: {
      evolucao: {
        2022: [
          null,
          null,
          null,
          null,
          1000,
          21000,
          42000,
          109000,
          37000,
          48000,
          53000,
          5000,
        ],
        2023: [
          5000,
          49000,
          17000,
          10000,
          1,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
      },
      evolucaoCanal: {
        atacado: {
          2022: [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ],
          2023: [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ],
        },
        'food & VAREJO': {
          2022: [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ],
          2023: [
            null,
            27000,
            null,
            null,
            1,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ],
        },
        nulo: {
          2022: [
            null,
            null,
            null,
            null,
            1000,
            21000,
            42000,
            109000,
            37000,
            48000,
            53000,
            5000,
          ],
          2023: [
            5000,
            22000,
            17000,
            10000,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ],
        },
      },
      uf: {
        rj: '1',
        sp: '27000',
        mt: '0',
        mg: '0',
        nulo: '370000',
        rs: '0',
        ms: '0',
      },
      impressos: {
        regional: {
          nulo: 370000,
          'são PAULO CAPITAL': 27000,
          sudeste: 1,
        },
        rede: {
          'grupo SPANI': 140000,
          hirota: 54000,
          'comercial ESPERANCA': 42000,
          'rede MAKRO': 37000,
          'rede - HIROTA': 27000,
          'fhe FERREIRA C P ALIM SERVICOS LTDA': 25000,
          'tenda ATACADO': 18000,
          'natural DA TERRA': 17000,
          'center SHOP': 10000,
          'lima SILVA COM ALIMENTOS LTDA': 8000,
          'rede PERUZZO': 7000,
          'rede SUPERMARKET': 5000,
          'sacolao SAO JORGE': 3000,
          'sup CODEBAL LTDA': 2000,
          'grupo NAGUMO': 2000,
          'bar ALCIONE EIRELI': 1,
        },
        marca: {
          'montana STEAKHOUSE': 204000,
          bassi: 151000,
          montana: 39000,
          'plant PLUS': 3000,
          nulo: 1,
        },
        solicitante: {
          'jefferson THOMAZ DE LIMA': 266000,
          'valter ROCHA COELHO JUNIOR': 81000,
          'mayara THAISE DA SILVA SERAFIM': 17000,
          'anderson GIOVANI FERREIRA BOLTER': 12000,
          'camila DUARTE DE OLIVEIRA LINO': 10000,
          'marco ANTONIO DOS SANTOS': 8000,
          'elias FERREIRA DE MENDONCA': 3000,
          'vend TESTE': 1,
        },
        categoria: {
          'in NATURA': 338000,
          industrializados: 59000,
          nulo: 1,
        },
      },
    },
    fechamento: {
      evolucaoSolicitacoes: {
        2022: [null, null, 4, 2, 17, 112, 186, 278, 179, 120, 158, 65],
        2023: [57, 257, 164, 113, 1, null, null, 5, null, null, null, null],
      },
      solicitacoesMarca: {
        2023: {
          'montana STEAKHOUSE': {
            quantidade: 198,
            percentual: 11.53,
          },
          bassi: {
            quantidade: 197,
            percentual: 11.47,
          },
          montana: {
            quantidade: 132,
            percentual: 7.68,
          },
          nulo: {
            quantidade: 58,
            percentual: 3.38,
          },
          'plant PLUS': {
            quantidade: 11,
            percentual: 0.64,
          },
          'bassi ANGUS': {
            quantidade: 1,
            percentual: 0.06,
          },
        },
      },
      solicitacoesCanal: {
        atacado: {
          percentual: 2.62,
          data: {
            2022: [
              null,
              null,
              null,
              null,
              null,
              16,
              null,
              null,
              null,
              8,
              null,
              null,
            ],
            2023: [
              null,
              11,
              10,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
          },
        },
        'food & VAREJO': {
          percentual: 23.92,
          data: {
            2022: [null, null, null, null, 6, 4, 36, 85, 5, 6, null, 5],
            2023: [null, 118, 68, 72, 1, null, null, 5, null, null, null, null],
          },
        },
        nulo: {
          percentual: 73.46,
          data: {
            2022: [null, null, 4, 2, 11, 92, 150, 193, 174, 106, 158, 60],
            2023: [
              57,
              128,
              86,
              41,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
          },
        },
      },
      solicitacoesRegional: {
        2023: {
          nulo: {
            quantidade: 1262,
            percentual: 100,
          },
          'são PAULO CAPITAL': {
            quantidade: 185,
            percentual: 100,
          },
          sudeste: {
            quantidade: 135,
            percentual: 100,
          },
          'são PAULO INTERIOR': {
            quantidade: 117,
            percentual: 100,
          },
          'centro OESTE': {
            quantidade: 10,
            percentual: 100,
          },
          sul: {
            quantidade: 9,
            percentual: 100,
          },
        },
      },
      solicitacoesCliente: {
        2023: {
          'tenda ATACADO': {
            quantidade: 286,
            percentual: 100,
          },
          'grupo SPANI': {
            quantidade: 215,
            percentual: 100,
          },
          'marfrig GLOBAL FOODS': {
            quantidade: 125,
            percentual: 100,
          },
          'rede MAKRO': {
            quantidade: 72,
            percentual: 100,
          },
          'rede SUPERMARKET': {
            quantidade: 62,
            percentual: 100,
          },
          'maravista COM ALIM LTDA': {
            quantidade: 41,
            percentual: 100,
          },
          'grupo - SUP JIRE': {
            quantidade: 40,
            percentual: 100,
          },
          nulo: {
            quantidade: 38,
            percentual: 100,
          },
          'comercial ESPERANCA': {
            quantidade: 35,
            percentual: 100,
          },
          'rede PERUZZO': {
            quantidade: 32,
            percentual: 100,
          },
          'sup PRECO IDEAL': {
            quantidade: 30,
            percentual: 100,
          },
          'floriano PAULISTA COMERCIO ALIM ': {
            quantidade: 27,
            percentual: 100,
          },
          'fhe FERREIRA C P ALIM SERVICOS LTDA': {
            quantidade: 26,
            percentual: 100,
          },
          'tennessee CUBATAO LTDA': {
            quantidade: 24,
            percentual: 100,
          },
          'pague MENOS': {
            quantidade: 21,
            percentual: 100,
          },
          'dinamica REAL DISTRIB PROD ALIM LTDA': {
            quantidade: 19,
            percentual: 100,
          },
          'center SHOP': {
            quantidade: 19,
            percentual: 100,
          },
          'sup BARRACAO': {
            quantidade: 18,
            percentual: 100,
          },
          'irm FLORENTINOS CEREAIS LTDA ME': {
            quantidade: 18,
            percentual: 100,
          },
          'nicolau MAX SUP LTDA': {
            quantidade: 17,
            percentual: 100,
          },
          'fnh JK RESTAURANTE ': {
            quantidade: 17,
            percentual: 100,
          },
          hirota: {
            quantidade: 16,
            percentual: 100,
          },
          'grupo NAGUMO': {
            quantidade: 16,
            percentual: 100,
          },
          'sup CODEBAL LTDA': {
            quantidade: 15,
            percentual: 100,
          },
          'sacolao SAO JORGE': {
            quantidade: 15,
            percentual: 100,
          },
          'sup JAU SERVE LTDA': {
            quantidade: 15,
            percentual: 100,
          },
          'lima SILVA COM ALIMENTOS LTDA': {
            quantidade: 15,
            percentual: 100,
          },
          'rede VILLA ZAIRA': {
            quantidade: 14,
            percentual: 100,
          },
          'supermercado PAGUE MENOS LTDA': {
            quantidade: 14,
            percentual: 100,
          },
          'r P STO COM GENEROS': {
            quantidade: 13,
            percentual: 100,
          },
          'hortifruti DO BAIRRO LTDA': {
            quantidade: 12,
            percentual: 100,
          },
          'sup PINHEIRAO': {
            quantidade: 11,
            percentual: 100,
          },
          'turquette COM DE ALIM LTDA ME': {
            quantidade: 10,
            percentual: 100,
          },
          'irm BOA LTDA': {
            quantidade: 9,
            percentual: 100,
          },
          'bjp COM DIST ALIMENTOS LTDA': {
            quantidade: 9,
            percentual: 100,
          },
          'rede - HIROTA': {
            quantidade: 9,
            percentual: 100,
          },
          'frosinone PARTICIPACOES ': {
            quantidade: 9,
            percentual: 100,
          },
          'fnh PH RESTAURANTE ': {
            quantidade: 9,
            percentual: 100,
          },
          'rede - MERCEARIA R A MARAVILHA': {
            quantidade: 9,
            percentual: 100,
          },
          'jp COML PROD ALIM LTDA ME': {
            quantidade: 8,
            percentual: 100,
          },
          'rede MARINGA': {
            quantidade: 8,
            percentual: 100,
          },
          'jmi BAR E RESTAURANTE EIRELI': {
            quantidade: 8,
            percentual: 100,
          },
          'sto ATACADISTA DE ALIMENTOS': {
            quantidade: 8,
            percentual: 100,
          },
          'grupo - COLAO O JORGE': {
            quantidade: 8,
            percentual: 100,
          },
          'formello COM ALIM FAST FOOD  EPP': {
            quantidade: 8,
            percentual: 100,
          },
          'grupo - KOSMOS': {
            quantidade: 8,
            percentual: 100,
          },
          cooper: {
            quantidade: 8,
            percentual: 100,
          },
          'mercado RIO SUL DE CAMPO GRANDE EIRELI': {
            quantidade: 8,
            percentual: 100,
          },
          'merc SUPER COMPRAS GOUVEIA LTDA': {
            quantidade: 8,
            percentual: 100,
          },
          'furlan & Piola LTDA': {
            quantidade: 7,
            percentual: 100,
          },
          'flor DA POSSE': {
            quantidade: 7,
            percentual: 100,
          },
          'xancarnes COM DE ALIM': {
            quantidade: 7,
            percentual: 100,
          },
          'natural DA TERRA': {
            quantidade: 7,
            percentual: 100,
          },
          'joao DANIEL RODRIGUES TURQUETTE': {
            quantidade: 7,
            percentual: 100,
          },
          'la MACELLARIA': {
            quantidade: 6,
            percentual: 100,
          },
          'maria FATIMA A OLIVEIRA': {
            quantidade: 6,
            percentual: 100,
          },
          'rede PINHEIRAO': {
            quantidade: 6,
            percentual: 100,
          },
          'mercad LESSA LTDA ME': {
            quantidade: 6,
            percentual: 100,
          },
          'sup BATISTA 2012 LTDA': {
            quantidade: 6,
            percentual: 100,
          },
          'rede - COML ZAFFARI': {
            quantidade: 6,
            percentual: 100,
          },
          'grupo - TENNESSEE': {
            quantidade: 6,
            percentual: 100,
          },
          'rede - SUP PASTORINHO': {
            quantidade: 6,
            percentual: 100,
          },
          'maby SUP LTDA': {
            quantidade: 6,
            percentual: 100,
          },
          'dias Pastorinho S A Comercio e Industria': {
            quantidade: 5,
            percentual: 100,
          },
          'com DE ALIM CAETANO GUARAREMA LTDA': {
            quantidade: 5,
            percentual: 100,
          },
          'beef SMART LTDA': {
            quantidade: 5,
            percentual: 100,
          },
          'sup TAQUARAL LTDA': {
            quantidade: 5,
            percentual: 100,
          },
          'rede - SUP ECONOMICO JJ': {
            quantidade: 5,
            percentual: 100,
          },
          'recobase COMERCIAL LTDA': {
            quantidade: 5,
            percentual: 100,
          },
          'jmw FOODS': {
            quantidade: 4,
            percentual: 100,
          },
          'du frade Du Frade Mercadinho LTDA': {
            quantidade: 4,
            percentual: 100,
          },
          'rede - BARRACAO SUP': {
            quantidade: 4,
            percentual: 100,
          },
          'rede - W M TANNOUS': {
            quantidade: 4,
            percentual: 100,
          },
          'bar ALCIONE EIRELI': {
            quantidade: 4,
            percentual: 100,
          },
          angeloni: {
            quantidade: 4,
            percentual: 100,
          },
          'vendramini & FILHO LTDA': {
            quantidade: 4,
            percentual: 100,
          },
          'ofelia SARRI MORETE': {
            quantidade: 4,
            percentual: 100,
          },
          'iotti GRIFFE DA CARNE': {
            quantidade: 3,
            percentual: 100,
          },
          'rede - SUPERBOM': {
            quantidade: 3,
            percentual: 100,
          },
          'grupo - HORTIFRUTIGRANJEIRO': {
            quantidade: 3,
            percentual: 100,
          },
          'rede - MOHAMED': {
            quantidade: 3,
            percentual: 100,
          },
          'rede - FURLAN': {
            quantidade: 3,
            percentual: 100,
          },
          'rede - SUP PORTO SEGURO': {
            quantidade: 3,
            percentual: 100,
          },
          'internacional REST BRASIL ': {
            quantidade: 3,
            percentual: 100,
          },
          'rede - 3 IRMAOS COM ALIM': {
            quantidade: 3,
            percentual: 100,
          },
          'coml ZAFFARI LTDA': {
            quantidade: 3,
            percentual: 100,
          },
          'rede ZAFFARI': {
            quantidade: 3,
            percentual: 100,
          },
          'carlos REIS DIAS ME': {
            quantidade: 3,
            percentual: 100,
          },
          'com DE GEN ALIM PARANA LTDA': {
            quantidade: 2,
            percentual: 100,
          },
          'sacolao SAUDE': {
            quantidade: 2,
            percentual: 100,
          },
          'peg E PESE COM DE HORTIFRUTIG LTDA': {
            quantidade: 2,
            percentual: 100,
          },
          'rede - SUP ELIAS MOREIRA': {
            quantidade: 2,
            percentual: 100,
          },
          'rede - DISTR BEBIDAS MAITAN': {
            quantidade: 2,
            percentual: 100,
          },
          'rede - REDE LOCAL': {
            quantidade: 2,
            percentual: 100,
          },
          'rede - SUP MEDEIROS': {
            quantidade: 2,
            percentual: 100,
          },
          'altair MEIRELES SILVA CIA LTDA ME': {
            quantidade: 2,
            percentual: 100,
          },
          'w C A COM DE ALIM LTDA': {
            quantidade: 2,
            percentual: 100,
          },
          'emporio FARM COMERCIO DE ALIMENTOS LTDA': {
            quantidade: 2,
            percentual: 100,
          },
          'irm QUEVEDO LTDA': {
            quantidade: 2,
            percentual: 100,
          },
          'vf BOUTIQUE CARNES ESP BEBIDAS LTDA': {
            quantidade: 2,
            percentual: 100,
          },
          'mercearia FANTASTIC LTDA': {
            quantidade: 2,
            percentual: 100,
          },
          'mini MERC BOA SORTE JACONE LTDA ME': {
            quantidade: 2,
            percentual: 100,
          },
          'supermercado SUPERPOUPE EIRELI': {
            quantidade: 1,
            percentual: 100,
          },
          'rede HORT MAIS HORTIFRUTI LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'w P MERC PAD LTDA ME': {
            quantidade: 1,
            percentual: 100,
          },
          'distr DE BEBIDAS MAITAN LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'gigantao SUPERMERCADOS LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'sup GALASSI LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'e P FILHO C DISTR CARNES P A LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'rede - SUP COMPRE CERTO': {
            quantidade: 1,
            percentual: 100,
          },
          'panificadora CONFEITARIA NOVA EUR C ': {
            quantidade: 1,
            percentual: 100,
          },
          'rede - EMPORIUM LEAO': {
            quantidade: 1,
            percentual: 100,
          },
          'rede - INFANGER & CIA': {
            quantidade: 1,
            percentual: 100,
          },
          'bar E RESTAURANTE MONTE GORDO ': {
            quantidade: 1,
            percentual: 100,
          },
          'rede - CASA BARROS ALIM': {
            quantidade: 1,
            percentual: 100,
          },
          'grupo - COM MARABEL': {
            quantidade: 1,
            percentual: 100,
          },
          'rede - COML 3D                          ': {
            quantidade: 1,
            percentual: 100,
          },
          'sup GRICKI': {
            quantidade: 1,
            percentual: 100,
          },
          'dario NEVES & CIA LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'com DE PRODUTOS ALIMENT FREGNI LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'oxan ATACADISTA LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'nilton J DE ANDRADE ME': {
            quantidade: 1,
            percentual: 100,
          },
          'entreposto D CARNES DANIELLA LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'j M CARVALHO MERCEARIA LTDA EPP': {
            quantidade: 1,
            percentual: 100,
          },
          'nl FONTES DIST PROD ALIMENT LTDA': {
            quantidade: 1,
            percentual: 100,
          },
          'supermercado CATIARA LTDA': {
            quantidade: 1,
            percentual: 100,
          },
        },
      },
      solicitacoesMaterial: {
        2023: {
          exclusivo: {
            quantidade: 965,
            percentual: 100,
          },
          'post STORIES ESTÁTICO': {
            quantidade: 160,
            percentual: 100,
          },
          'box Pág. Inteira': {
            quantidade: 149,
            percentual: 100,
          },
          'post FEED ESTÁTICO': {
            quantidade: 113,
            percentual: 100,
          },
          'post FEED ANIMADO': {
            quantidade: 96,
            percentual: 100,
          },
          post: {
            quantidade: 51,
            percentual: 100,
          },
          'whatsapp ESTÁTICO': {
            quantidade: 49,
            percentual: 100,
          },
          'box 1/2 Pág.': {
            quantidade: 48,
            percentual: 100,
          },
          'whatsapp ANIMADO': {
            quantidade: 44,
            percentual: 100,
          },
          'post STORIES ANIMADO': {
            quantidade: 43,
            percentual: 100,
          },
        },
      },
      evolucaoCusto: {
        2022: [
          null,
          null,
          720,
          360,
          7741,
          106017,
          322013,
          610254,
          268127,
          412090,
          383857,
          37331,
        ],
        2023: [
          26443,
          338712,
          207356,
          52351,
          123,
          null,
          null,
          1100,
          null,
          null,
          null,
          null,
        ],
      },
    },
  },
};

const months = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro',
];

export { filterOptions, sampleData, months };
