import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import lineBreaker from 'helpers/LineBreaker';
import SemDados from '../SemDados';

const MaterialRank = (props) => {
  const { data } = props;

  const titulo = 'RANK SOLICITAÇÕES POR MATERIAL';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const materiais = Object.keys(data);

  const series = [
    { data: materiais.map((material) => data[material].quantidade) },
  ];

  const options = {
    colors: ['#021f59'],
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: true,
          orientation: 'vertical',
        },
      },
    },
    xaxis: {
      categories: [...materiais],
      labels: {
        formatter: (value) => {
          const lines = lineBreaker(value.toUpperCase(), 14);
          return lines;
        },
        show: true,
        rotate: -90,
        trim: true,
        rotateAlways: false, // mudar para true se quiser que todas as labels sejam rotacionadas
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
      axisTicks: {
        show: true,
        height: 200,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      show: true,
      max:
        Math.max(...materiais.map((material) => data[material].quantidade)) *
        1.6,
      labels: {
        show: false,
      },
    },
    grid: {
      show: true,
      padding: {
        top: 0,
        bottom: -12,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      height: 'auto',
      id: 'reg-solicitacoes',
      parentHeightOffset: 10,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: 10,
      formatter: (value, { dataPointIndex }) => {
        return `${parseInt(value, 10).toLocaleString('pt-BR')} (${
          data[materiais[dataPointIndex]].percentual
        }%)`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 'light',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Material: <span id="data">${materiais[
                dataPointIndex
              ].toUpperCase()}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${parseInt(
                series[seriesIndex][dataPointIndex],
                10
              ).toLocaleString('pt-BR')}</span>
            </span>
            <span>
              % Aprovados: <span id="data">${
                data[materiais[dataPointIndex]].percentual
              }%</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="bar" height="300" />
    </div>
  );
};

export default MaterialRank;
