import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';
import { months } from '../../data/sampleData';

const CanalSolicitacoes = (props) => {
  const { data } = props;

  const titulo = 'Solicitações por canal';

  if (!data || Object.keys(data).length === 0)
    return <SemDados titulo={titulo} />;

  const canais = Object.keys(data);
  const anos = Object.keys(data[canais[0]].data);
  const meses = [];
  const values = {};

  let maxValue = 1;

  const calculateSeries = () => {
    const tmpSeries = [];
    if (anos.length === 0) {
      return [];
    }
    anos.forEach((ano) => {
      months.forEach((mes, i) => {
        let check = false;
        canais.forEach((canal) => {
          if (data[canal].data[ano][i] !== null) {
            maxValue = Math.max(maxValue, data[canal].data[ano][i]);
            check = true;
          }
        });
        if (check) {
          canais.forEach((canal) => {
            values[canal] = [
              ...(values[canal] || []),
              data[canal].data[ano][i] || 0,
            ];
          });
          meses.push(`${mes.slice(0, 3)}.-${ano.slice(2, 4)}`);
        }
      });
    });
    canais.forEach((canal) => {
      tmpSeries.push({
        name: canal,
        data: values[canal],
      });
    });
    return tmpSeries;
  };

  const series = calculateSeries();

  const options = {
    colors: ['#faa306', '#1374fb', '#d3d3d3'],
    legend: {
      formatter: (seriesName) => {
        return [seriesName.toUpperCase()];
      },
      show: true,
      showForSingleSeries: true,
    },
    xaxis: {
      tickPlacement: 'on',
      categories: [...meses],
    },
    yaxis: {
      show: false,
      showForNullSeries: true,
    },
    grid: {
      show: false,
    },
    chart: {
      id: 'can-solicitacoes',
      parentHeightOffset: 0,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: [3, 3],
      show: true,
      curve: 'straight',
      dashArray: 0,
    },
    markers: {
      size: 8,
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        fontSize: '0.8rem',
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        opacity: 0.8,
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Mês: <span id="data">${meses[dataPointIndex]}</span>
            </span>
            <span>
              Canal: <span id="data">${canais[seriesIndex].toUpperCase()}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${
                series[seriesIndex][dataPointIndex]
              }</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="line" height="250" />
    </div>
  );
};

export default CanalSolicitacoes;
