import React from 'react';
import '../../styles.scss';

import {
  EvolucaoTotal,
  MapChart,
  RegiaoTotal,
  SolicitacoesGenerico,
} from '../Graficos/custo';

const TabCusto = React.memo(
  (props) => {
    const { data } = props;
    return (
      <div className="container-tab">
        <div className="row-graficos">
          <EvolucaoTotal data={data?.evolucao} />
        </div>
        <div className="row-graficos">
          <MapChart data={data?.uf} />
          <RegiaoTotal data={data?.regional} />
        </div>
        <div className="row-graficos">
          <SolicitacoesGenerico
            titulo="Solicitações por Solicitante"
            data={data?.solicitacoes.solicitante}
          />
          <SolicitacoesGenerico
            titulo="Solicitações por Rede"
            data={data?.solicitacoes.rede}
          />
          <SolicitacoesGenerico
            titulo="Solicitações por Canal"
            data={data?.solicitacoes.canal}
          />
        </div>
        <div className="row-graficos">
          <SolicitacoesGenerico
            titulo="Solicitações por Marca"
            data={data?.solicitacoes.marca}
          />
          <SolicitacoesGenerico
            titulo="Solicitações por Categoria"
            data={data?.solicitacoes.categoria}
          />
          <SolicitacoesGenerico
            titulo="Solicitações por Material"
            data={data?.solicitacoes.material}
          />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default TabCusto;
